const compareCities = (city1, city2) => {
    if(!city1 || !city2) {
        return false
    }
    const a = city1
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\ /g, '')
        .toLowerCase()
        .trim();
        
    const b = city2
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\ /g, '')
        .toLowerCase()
        .trim();

    return a === b;
};

export default compareCities
import React, { useContext, useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";

import Loading from "../../components/Loading";

import { ATENDIMENTOS_API_URL } from "../../constants/api";
import { FirebaseContext, CurrentUserContext } from "../../Contexts";
import { useCallbackStatic } from "../../hooks/utils";

// const characterLimit = 240;

const Base = (props) => {
  const {
    title,
    description,
    imagePath,
    programName,
    nome,
    cidade,
    bairro,
    telefone,
    clearForm,
  } = props;

  const { firebaseAuth } = useContext(FirebaseContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  const back = useCallbackStatic(() => {
    setResult(false);
  });

  const send = useCallbackStatic(async (data) => {
    const mensagem = [
      { label: "Nome", value: data.nome },
      { label: "Cidade", value: data.cidade },
      { label: "Bairro", value: data.bairro },
      { label: "Telefone", value: data.telefone },
    ].reduce((accumulator, field) => {
      if (field.value && field.value.length > 0) {
        return `${accumulator}\n\n${field.label}:\n${field.value}`;
      }

      return accumulator;
    }, "");

    setLoading(true);

    const messageParams = {};

    if (currentUser.podeEnviarMensagens) {
      const idToken = await firebaseAuth.currentUser.getIdToken();
      const url = `${ATENDIMENTOS_API_URL}/external/mensagens?notSendFirebase=true`;
      const headers = { Authorization: `Bearer ${idToken}` };
      const body = {
        mensagem: `Mensagem para o ${programName}:\n\n${mensagem}`,
      };
      const result = await axios.post(url, body, { headers });
      const apiMensagem = result.data.rows[0];

      messageParams.created_at = moment(apiMensagem.data_criacao).toDate();
      messageParams.id = apiMensagem.id;
    } else {
      messageParams.created_at = moment().toDate();
      messageParams.id = `${currentUser.uid}-${moment().unix()}`;
    }

    clearForm();
    setResult(true);
    setLoading(false);
  });

  const sendMessage = useCallback(() => {
    if (!nome || nome.length <= 0) {
      alert("Escreva o nome do estabelecimento");
      return;
    }

    if (!cidade || cidade.length <= 0) {
      alert("Escreva a cidade");
      return;
    }

    send({
      nome,
      cidade,
      bairro,
      telefone,
    });
  }, [send, nome, cidade, bairro, telefone]);

  const renderContent = useCallback(() => {
    if (!result) {
      return (
        <div className="container">
          <div className="titenquete">{title}</div>
          <div className="descenquete">{description()}</div>

          <div className="btsalvar">
            <button onClick={sendMessage}>Enviar</button>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="titenquete">Indicação enviada com sucesso!</div>
        <br />
        <br />
        <div className="btsalvar">
          <button onClick={back}>Enviar nova indicação</button>
        </div>
      </div>
    );
  }, [back, description, result, sendMessage, title]);

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img alt="RPC Recicla" src={imagePath} />
        </div>

        {renderContent()}
      </div>

      {loading ? <Loading /> : null}
    </React.Fragment>
  );
};

export default Base;

import React from 'react'

import { useCallbackStatic } from '../../hooks/utils'

import Base from './Base'

const Natal = () => {
  return (
    <Base
      title=""
      description={() => <h4>Conte sua história de Natal para a RPC!</h4>}
      imagePath="/imgs/mensagem-natal.png"
      characterLimit={2048}
      sendType="natal"
    />
  )
}

export default Natal

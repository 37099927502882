import React, { useState, useRef } from 'react'

const Collapse = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false)
  const contentRef = useRef(null)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const getHeight = () => {
    return expanded ? `${contentRef.current.scrollHeight}px` : '0px'
  }

  const getOpacity = () => {
    return expanded ? 1 : 0
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <div className="btn-default agasalho-2024">
        <div className="collapse-button">
          <button onClick={toggleExpanded}>{title}</button>
        </div>
      </div>
      <div
        ref={contentRef}
        style={{
          overflow: 'hidden',
          height: getHeight(),
          opacity: getOpacity(),
          transition: 'height 0.3s ease, opacity 0.3s ease'
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Collapse

import React from 'react';

const TermoCorridaCaminhadaDiaInternacionalMulher2022 = () => (
    <>
      <p>Promoção válida de 26/02 a 20/04/2022 – Desconto na Inscrição da Corrida e Caminhada do Dia Internacional da Mulher.</p>

<p>Ao me cadastrar nesta promoção estou ciente que a quantidade de cupons é limitada, devido ao espaço do local, normas sanitárias e de segurança.</p>

<p>Os cupons de desconto serão disponibilizados em dois lotes promocionais. O primeiro lote, disponível de 26/02/2022 a 20/03/2022 (conforme disponibilidade das inscrições), gerará um cupom de desconto de 30% para ser aplicado no ato da inscrição no site oficial do evento. O segundo lote, disponível de 21/03/2022 a 20/04/2022, gerará um cupom de 20% de desconto para ser aplicado no ato da inscrição no site oficial do evento (conforme disponibilidade das inscrições.) As inscrições realizadas até o dia 09/04 darão direito ao recebimento do 'Kit Atleta'.</p>

<p>O cupom de desconto é pessoal e exclusivo para o usuário cadastrado no APP Você na RPC e dá direito exclusivamente ao desconto no ingresso para Corrida e Caminhada do Dia Internacional da Mulher, de propriedade do Instituto Ecoprojetar, nas categorias Individual Geral Feminino de 12 a 80 anos, Individual Geral Masculino de 12 a 80 anos, Dupla Geral Mista Masculino/Feminino de 12 a 80 anos, não incluindo utilização de brinquedos, restaurantes ou demais atrações do local. </p>

<p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoCorridaCaminhadaDiaInternacionalMulher2022

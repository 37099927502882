import React from 'react';

const TermosSambaSertaoBrasa2022 = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – Show SAMBA, SERTÃO &amp; BRASA</strong></p>
        <p>Ao me cadastrar nesta promoção estou ciente que o voucher é pessoal e exclusivo para o usuário cadastrado no APP.</p>
        <p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermosSambaSertaoBrasa2022;

import React from 'react'

const RadioButton = ({checked, setChecked, name='RadioButton', style = {}, options, title="", subTitle="", horizontal = false }) => {
    return ( <><p className="title">
                {title}
                {subTitle &&
                <span className="subTitle">
                    {`(${subTitle})`}
                </span>
                }
            </p>
      <div style={{ display: 'flex', flexDirection: horizontal ? 'row' : 'column' }}>
         {options.map((option, idx) => <div key={idx} className="toggle-radio" style={style}>
            <input
                type="radio"
                name={name}
                id={name}
                checked={checked === option.value}
                onChange={() => setChecked(option.value)}
            />
            <button type="button" className="btTransparente">
              <span>
                  <strong>{option.description}</strong>
              ​</span>
          </button>
        </div>)}
      </div>
      </>)
}

export default RadioButton

import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import ReactGA from 'react-ga4'

import videos from './models'

ReactGA.initialize('G-E68TZ3CBPD')

const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block'
      }}
      onClick={onClick}
    />
  )
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block'
      }}
      onClick={onClick}
    />
  )
}

const PlayListVideos = () => {
  const navigate = useHistory()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/aniversario-teatro-guaira',
      title: 'Aniversário Teatro Guaíra'
    })
  }, [])

  const handleVideoSelected = useCallback((idVideo) => {
    try {
      const video = videos.find((video) => video.id === idVideo)

      ReactGA.event({
        category: 'Video',
        action: `Click Video Aniversário Teatro Guaíra - ${video.title}`,
        label: `Video Aniversário Teatro Guaíra - ${video.title}`
      })

      navigate.push({
        pathname: '/video-aniversario-teatro-guaira/',
        state: { videoPlay: video }
      })
    } catch (error) {
      console.log('Error handleVideoSelected: ', error)
    }
  }, [])

  return (
    <div className="container-page aniversario-teatro-guaira-2024">
      <div className="imagemdestaque">
        <img src="/imgs/aniversario-teatro-guaira.png" alt="banner" />
      </div>
      <div className="content">
        <div className="title-enquete">
          <h1>Aniversário do Teatro Guaíra</h1>
        </div>
        <div className="description">
          <p>
            Acompanhe trechos de apresentações dos grupos artísticos do Centro
            Cultural Teatro Guaíra (CCTG). Balé, G2, OSP e Escola de Dança de um
            jeito que você nunca viu. As gravações foram feitas com uma câmera
            360 para você subir ao palco com os artistas e apreciar!
          </p>
        </div>
        <div className="list-videos">
          <Slider {...settings}>
            {videos.map((video) => (
              <div className="container-video" key={video.id}>
                <h2>{video.title}</h2>
                <div className="content-video">
                  <div
                    onClick={() => handleVideoSelected(video.id)}
                    className={`each-video video-${video.id}`}
                  >
                    <div className="play-button"></div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="logo-360">
          <img src="/imgs/logo-360.png" alt="Logo 360" />
        </div>

        <div className="content-instruction-video">
          <p>
            Para assistir a um vídeo 360°, comece clicando no botão de
            reprodução. Assim que o vídeo começar, você pode explorar o ambiente
            de forma interativa. Você pode tocar e arrastar na tela ou
            movimentar o dispositivo para explorar diferentes partes da cena.
            <br />
            <br />
            Se você possui um headset de realidade virtual (VR), insira seu
            dispositivo móvel no headset para uma experiência completa. Com os
            óculos VR, você pode usar os controles do headset para navegar e
            interagir com o vídeo, proporcionando uma sensação ainda mais
            realista e envolvente.
            <br />
            <br />
            Se você encontrar problemas, como o vídeo não carregar ou travar,
            verifique sua conexão com a internet e tente atualizar a página para
            resolver a situação.
            <br />
            <br />
            Com essas orientações, você estará pronto para aproveitar ao máximo
            os vídeos 360°, explorando cada detalhe e vivenciando a imersão que
            essa tecnologia oferece. Divirta-se e aproveite a experiência única
            de assistir a vídeos em 360 graus!
          </p>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  )
}

export default PlayListVideos

import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Result } from 'antd';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import InputWithLabel from '../../../../components/InputWithLabel';
import Modal from '../../../../components/Modal';
import TermoShowCabareSorteio2022 from '../../../../termosAceite/termoShowCabareSorteio2022';
import RadioButton from '../../../../components/RadioButton'

import { FirebaseContext, CurrentUserContext } from '../../../../Contexts';

import getClientIPv4 from '../../../../utils/getPublicIPv4'
import getPraca from '../../../../utils/getPraca'
import getDeviceInfos from '../../../../utils/getDeviceInfos'

import { checkUnfilledFields } from '../../../../utils/helpers';

import sendUserConsent from '../../../../utils/sendUserConsent';
import originConsents from '../../../../constants/originConsents'

const HeaderComponent = ({ children }) => (
  <div className="enqueterpc">
    <div className="imagemdestaque">
        <img src="/imgs/cupons/banner-cabare.png" alt="banner"/>
    </div>
    {children}
  </div>
);

const MessageComponent = ({ children, title, description }) => (
  <HeaderComponent>
    <div className="container">
        <div className="titenquete">
            <p className="title">{title}</p>
        </div>
        <div className="titenquete">
            <p style={{ fontWeight: 400 }}>{description}</p>
        </div>
        {children}
    </div>
  </HeaderComponent>
);

const termoShowCabareSorteio2022 = 'termoShowCabareSorteio2022'
const maxTickets = 40

const CabareSorteio2022 = () => {
  const { firebaseFirestore } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [error, setError] = useState(false)
  const [soldOutTicket, setSoldOutTicket] = useState(false)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false);

  const [formValues, setFormValues] = useState({
      nomeCompleto: '',
      cpf: '',
      rg: '',
      email: '',
      telefone: '',
      whatsApp: undefined,
      termoAceito: false,
      maiorDe18: false,
  });

  useEffect(() => {
    const firebaseTransaction = async () => {
    try {
      const availableTicketsAmmountRef = await firebaseFirestore
        .collection('sistema-reserva-tickets')
        .doc('show-cabare-sorteio-2022')

      await firebaseFirestore.runTransaction(async (transaction) => {
          const doc = await transaction.get(availableTicketsAmmountRef)

          const docData = doc.data()

          const availableTickets = docData.quantidadeTicketsDisponiveis
          const newAvailablity = availableTickets - 1

          console.log('TICKETS DISPONÍVEIS', availableTickets)
          console.log('NOVA DISPONIBILIDADE',newAvailablity)

          const currentTicketIndex = docData.ticketCorrente
          const newTicketIndex = currentTicketIndex + 1

          const soldOff = newAvailablity < 0

          transaction.update(availableTicketsAmmountRef, {
            quantidadeTicketsDisponiveis: soldOff ? availableTickets : newAvailablity,
            ticketCorrente: newTicketIndex
          })

          const ticketDocRef = availableTicketsAmmountRef.collection('tickets').doc()
          await transaction.set(ticketDocRef, {
            'ticket': newTicketIndex,
            'id': currentUser.uid,
            'nome': formValues.nomeCompleto,
            'email': formValues.email,
            'cpf': formValues.cpf,
            'rg': formValues.rg,
            'telefone': formValues.telefone,
            'whatsApp': formValues.whatsApp,
            'maiorDe18': formValues.maiorDe18,
            'created_at': new Date()
          })

           if (soldOff) {
            setSoldOutTicket(true)
           }

          console.log(doc)
      })

      console.log('Transaction success!')
    } catch (e) {
        console.log('Transaction failure:', e)
        setLoading(false)
        setError(true)
      }
    }

    const firestoreUpdateUserData = async () => {
      try {
        const user = await firebaseFirestore
          .collection('flerken-users')
          .doc(currentUser.uid)

        await user.update({
          nome: formValues.nomeCompleto,
          cpf: formValues.cpf,
          email: formValues.email,
          telefone: formValues.telefone
        })
      } catch (e) {
        console.error('Erro ao atualizar dados do usuário:', e)
        setLoading(false)
        setError(true)
      }
    }

     const firestoreStartUploadTerm = async () => {
        const firebaseMensagem = {
            created_at: moment().toDate(),
            id: `${currentUser.uid}-${moment().unix()}`,
            praca: await getPraca(currentUser),
            usr_email: currentUser.email,
            usr_id: currentUser.uid,
            usr_name: currentUser.nome,
            termo: termoShowCabareSorteio2022,
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }

        const showCabareSorteioDoc = await firebaseFirestore
          .collection('flerken-interatividade')
          .doc('TERMOS_ACEITE')
          .collection('termos')

        try {
            await showCabareSorteioDoc.doc(firebaseMensagem.id).set(firebaseMensagem)
            console.log('Ddos ado termo enviados com sucesso para o firestore')

            await sendUserConsent({
              name: formValues.nomeCompleto,
              email: formValues.email,
              origin: originConsents[termoShowCabareSorteio2022]
            })

            setFinish(true)
        } catch (err) {
            console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
            setError(true)
        }

        setLoading(false)
    }

    if(loading) {
      firebaseTransaction()
      firestoreUpdateUserData()
      firestoreStartUploadTerm()
    }
  }, [loading])

  useEffect(() => {
    const userExistsInDB = async () => {
       const registerInDB = await firebaseFirestore
        .collection('sistema-reserva-tickets')
        .doc('show-cabare-sorteio-2022')
        .collection('tickets')
        .where('id', '==', currentUser.uid)
        .get()

      if(registerInDB.docs.length > 0) {
         const userTicketData = registerInDB.docs[0].data()
         const { ticket } = userTicketData

         if(ticket > maxTickets) {
           setSoldOutTicket(true)
         }

         setFinish(true)
      }
    }

    if(currentUser) {
     userExistsInDB()

     setFormValues(prev => ({
       ...prev,
        nomeCompleto: currentUser.nome || '',
        cpf: currentUser.cpf || '',
        email: currentUser.email || '',
        telefone: currentUser.telefone || ''
     }))
    }
  }, [currentUser])

  if(!currentUser) {
    return <MessageComponent
      title="Usuário não identificado"
      description="É necessário se autenticar no app para efetuar a inscrição."
    />
  }

  if(error) {
    return <MessageComponent
      title="Ocorreu um erro ao enviar a sua inscrição"
      description="Por favor, tente novamente mais tarde."
    />
  }

  if (loading) {
    return <MessageComponent
      title="Enviando inscrição"
      description="Por favor, não feche esta tela até o processo finalizar."
    >
      <img src="/imgs/giphy.gif" className="img-loading"/>
    </MessageComponent>
  }

  if (finish) {
    return <HeaderComponent>
      <Result
        status="success"
        title={!soldOutTicket ? 'Inscrição feita 😊' : 'Agradecemos a sua participação 🙂'}
        extra={<>
          <p style={{ fontWeight: '700' }}>{!soldOutTicket ? 'Uhul! Você foi contemplado(a)! Em breve encaminharemos os ingressos por e-mail e/ou Whatsapp.' : 'Promoção encerrada.'}</p>
        </>}
      />
    </HeaderComponent>
  }

  const disabledButon = () => {
      const {
        nomeCompleto,
        cpf,
        rg,
        email,
        telefone,
        termoAceito,
        whatsApp,
        maiorDe18,
      } = formValues

      const requiredFields = checkUnfilledFields({
        nomeCompleto: {
          value: nomeCompleto,
          minLength: 5
        },
        rg: {
          value: rg,
          minLength: 6
        },
        cpf: {
          value: cpf,
          minLength: 11
        },
        email: {
          value: email,
          minLength: 5
        },
        telefone: {
          value: telefone,
          minLength: 10
        },
      })

      return !(
        termoAceito
        && maiorDe18
        && whatsApp !== undefined
        && requiredFields.length === 0)
  };

  const changeFormValue = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }))
  }

  console.log({formValues})

  return <HeaderComponent>
    <div className="container">
      <InputWithLabel
          title="Nome completo"
          inputValue={formValues.nomeCompleto}
          onChange={(e) => changeFormValue('nomeCompleto', e.target.value)}
          minInputLength="6"
          required={true}
      />
      <InputWithLabel
          title="RG"
          inputValue={formValues.rg}
          onChange={(e) => changeFormValue('rg', e.target.value)}
          mask="rg"
          parser="rg"
          minInputLength='6'
          required={true}
      />
      <InputWithLabel
          title="CPF"
          inputValue={formValues.cpf}
          onChange={(e) => changeFormValue('cpf', e.target.value)}
          mask="cpf"
          parser="cpf"
          minInputLength='11'
          required={true}
      />
      <InputWithLabel
          title="E-mail"
          inputValue={formValues.email}
          onChange={(e) => changeFormValue('email', e.target.value)}
          type="email"
          required={true}
      />
      <InputWithLabel
          title="Telefone"
          inputValue={formValues.telefone}
          onChange={(e) => changeFormValue('telefone', e.target.value)}
          maxInputLength={16}
          mask='telefone'
          parser='onlyNumbers'
          required={true}
      />
      <RadioButton
        name="radio-whatsapp"
        checked={formValues.whatsApp}
        style={{ width: '100%' }}
        setChecked={(value) => changeFormValue('whatsApp', value)}
        options={[
          {
            description: "Sim",
            value: true
          },
          {
            description: "Não",
            value: false
          }
        ]}
        title="O número de telefone informado tem WhatsApp?"
      />
      <div style={{ paddingTop: '15px' }} />
      <p>
        <strong>Atenção: Os ingressos são eletrônicos e serão enviados para o e-mail e WhatsApp cadastrados</strong> neste formulário de promoção. Dessa forma, é de sua responsabilidade conferir sua caixa de entrada, spam e WhatsApp para obter seus ingressos.
      </p>
      <div className="opcenquete align">
          <ToggleSwitch name="toggle-maior-de-18" checked={formValues.maiorDe18} style={{ width: '50px' }}
              setChecked={() => changeFormValue('maiorDe18', !formValues.maiorDe18)}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}>
              <span>
                  <strong>Declaro que sou maior de 18 anos</strong>
              ​</span>
          </button>
      </div>
      <div className="opcenquete align">
          <ToggleSwitch name="toggle-aceito" checked={formValues.termoAceito} style={{ width: '50px' }}
              setChecked={() => changeFormValue('termoAceito', !formValues.termoAceito)}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}
              onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  document.body.style.overflowY = 'hidden';
                  setMostrarTermoModal(_ => true)
              }}
          >
              <span>
                  <strong>Declaro que li e aceito os termos de uso</strong>
              ​</span>
          </button>
      </div>

      {
          mostrarTermoModal &&
          <Modal
              setTermoAceito={() => changeFormValue('termoAceito', true)}
              setShowModal={setMostrarTermoModal}
          >
              <TermoShowCabareSorteio2022 />
          </Modal>
      }
      <div className="btsalvar" style={{ paddingTop: '20px' }}>
          <button onClick={() => setLoading(true)} className="btsalvar" disabled={disabledButon()}>
              Participar
          </button>
      </div>
      <p style={{ textAlign: 'center', fontSize: '12px', padding: '15px 0px' }}>
        <strong>CERTIFICADO DE AUTORIZAÇÃO SEAE/ME N. 05.021509/2022</strong>
      </p>
    </div>
  </HeaderComponent>;
};

export default CabareSorteio2022

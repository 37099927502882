import dayjs from 'dayjs';

const urlApi = `https://brasilapi.com.br/api/feriados/v1/${dayjs().format("YYYY")}`

const getNationalHoliday = async () => {
  const holiday = await fetch(urlApi)
                          .then(response => response.json())
                          .then(data => data.map(record => record.date).filter(record => dayjs(record).day() === 5)) //Feriados na sexta
                          .catch(error =>{
                            console.log(error)
                            return []
                          })

  return holiday;
}

export default getNationalHoliday

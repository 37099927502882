import React, { useCallback, useMemo, useState } from 'react'
import { Divider, Radio, Space } from 'antd';

import InputWithLabel from '../../../components/InputWithLabel'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'

import TermoBemVerao2021 from '../../../termosAceite/termoBemVerao2021'
import FormularioInscricao from './FormularioInscricao.js'

import Base from './Base'

import { checkUnfilledFields } from '../../../utils/helpers';

import parsers from '../../../utils/parsers/index'

const characterLimit = 255

const ConcursoCaminhosDoCampo = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [mostrarFormInscriModal, setMostrarFormInscriModal] = useState(false)

    const [formValues, setFormValues] = useState({
        //Dados Pessoais
        nomeCompleto: '',
        sexo: '',
        dataNascimento: '',
        email: '',
        whatsapp: '',
        peso: '',
        altura: '',
        comorbidade: '',
        descricaoComorbidade: '',
        tomouVacinaCovid19: '',
        descricaoParticipacao: '',
        cidade: 'Foz do Iguaçu',
        //Aceites
        termoAceito: false,
        formularioInscricaoAceito: false,
    })

    const onChangeDropdownEventHandler = useCallback((attribute) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text
        setFormValues(prev => ({...prev, [`${attribute}`]: value}))
    }, [])

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
            const parseType = event.target.dataset.parser
            const parsedValue = parseType ? parsers[parseType](value) : value
            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
            }
        }, []
    );

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    }, [])

    const handleCheckedFormInscri = useCallback(() => {
        setFormValues(prev => ({...prev, formularioInscricaoAceito: !prev.formularioInscricaoAceito}))
    }, [])

    const userUnder18 = useMemo(() => {
        if(formValues.dataNascimento.length < 10) {
            return true;
        }
        try {
            const maxAllowedDate = new Date();
            maxAllowedDate.setFullYear( maxAllowedDate.getFullYear() - 18 );
            
            const data = formValues.dataNascimento.split('/')

            const birthday = new Date(`${data[1]}/${data[0]}/${data[2]}`);
            
            if(birthday > maxAllowedDate) return true;
            
            return false;
        }
        catch (e) {
            return true;
        }

    }, [formValues.dataNascimento])

    const salvarOk = useMemo(() => {
        const { 
            nomeCompleto,
            sexo,
            dataNascimento,
            email,
            whatsapp,
            peso,
            altura,
            comorbidade,
            descricaoComorbidade,
            tomouVacinaCovid19,
            descricaoParticipacao,
            cidade,
            termoAceito,
            formularioInscricaoAceito
        } = formValues;

        const requiredFields = checkUnfilledFields({
            nomeCompleto: { 
                value: nomeCompleto,
                minLength: 0
            },
            sexo: {
                value: sexo,
                minLength: 0
            },
            dataNascimento: {
                value: dataNascimento,
                minLength: 10
            },
            cidade: {
                value: cidade,
                minLength: 0
            },
            peso: {
                value: peso,
                minLength: 2
            },
            whatsapp: {
                value: whatsapp,
                minLength: 10
            },
            email: {
                value: email,
                minLength: 0
            },
            altura: {
                value: altura,
                minLength: 1,
            },
            comorbidade: {
                value: comorbidade,
                minLength: 0
            },
            descricaoParticipacao: {
                value: descricaoParticipacao,
                minLength: 0
            },
            tomouVacinaCovid19: {
                value: tomouVacinaCovid19,
                minLength: 0
            }
        });

        return !(
            termoAceito
            && formularioInscricaoAceito
            && requiredFields.length === 0
            && !userUnder18
        )
    }, [formValues, userUnder18])

    return (
        <Base
            title="Bem Verão 2021"
            description={() => (
                <div className="container">
                    <Divider>Dados Pessoais</Divider>
                    <InputWithLabel
                        title={"Nome completo"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompleto}
                        onChange={onChangeInputEventHandler('nomeCompleto', characterLimit)}
                    />

                    <div>
                        <p className="title">
                            Sexo:
                            <span className="subTitle">
                                (Campo obrigatório)
                            </span>
                        </p>
                        
                        <Radio.Group onChange={e => 
                                setFormValues(prev => ({...prev, sexo: e.target.value}))
                            } 
                            value={formValues.sexo}
                            style={{marginLeft: '24px'}}
                            className='align-left'
                        >
                            <Space direction="vertical">
                            <Radio value={'Masculino'}>Masculino</Radio>
                            <Radio value={'Feminino'}>Feminino</Radio>
                            </Space>
                        </Radio.Group>
                    </div>

                    <div>
                        <InputWithLabel
                            title={"Data de Nascimento"}
                            subTitle="Campo obrigatório"
                            inputValue={formValues.dataNascimento}
                            onChange={onChangeInputEventHandler('dataNascimento', 10)}
                            mask='dataNascimento'
                            parser='dataNascimento'
                        />
                        {userUnder18 && <span>
                            É necessário possuir mais de 18 anos para se inscrever no <strong>Bem Verão</strong>
                            </span>}
                    </div>

                    <InputWithLabel
                        title={"Email"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.email}
                        onChange={onChangeInputEventHandler('email', characterLimit)}
                    />

                    <InputWithLabel
                        title={"WhatsApp"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.whatsapp}
                        onChange={onChangeInputEventHandler('whatsapp', characterLimit)}
                        maxInputLength={16}
                        mask='telefone'
                        parser='onlyNumbers'
                    />

                    <InputWithLabel
                        className="textInput"
                        title={"Peso (Kg)"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.peso}
                        onChange={onChangeInputEventHandler('peso', 5)}
                        parser='numberWithOneDot'
                    />

                    <InputWithLabel
                        title={"Altura (metros)"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.altura}
                        onChange={onChangeInputEventHandler('altura', 4)}
                        mask='altura'
                        parser='numberWithOneDot'
                    />

                    <div>
                        <p className="title">Possui alguma comorbidade?
                            <span className="subTitle">
                                (Campo obrigatório)
                            </span>
                        </p>
                        <Radio.Group onChange={e => 
                                setFormValues(prev => ({...prev, comorbidade: e.target.value}))
                            } 
                            value={formValues.comorbidade}
                            style={{marginLeft: '24px'}}
                            className='align-left'
                        >
                            <Space direction="vertical">
                            <Radio value={'Não'}>Não</Radio>
                            <Radio value={'Sim'}>Sim</Radio>
                            </Space>
                        </Radio.Group>
                    </div>

                    {
                        formValues.comorbidade === 'Sim' &&
                        <InputWithLabel
                        title={"Qual a sua comorbidade?"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.descricaoComorbidade}
                        maxInputLength={50000}
                        onChange={onChangeInputEventHandler('descricaoComorbidade', 50000)}className="textInputArea"
                        inputPlaceholder={'Qual a sua comorbidade?'}
                        />
                    }

                    <div>
                        <p className="title">
                            Já tomou a vacina contra a Covid-19?
                            <span className="subTitle">
                                (Campo obrigatório)
                            </span>
                        </p>
                        <Radio.Group onChange={e => 
                                setFormValues(prev => ({...prev, tomouVacinaCovid19: e.target.value}))
                            } 
                            value={formValues.tomouVacinaCovid19}
                            style={{marginLeft: '24px'}}
                            className='align-left'
                        >
                            <Space direction="vertical">
                            <Radio value={'Não'}>Não</Radio>
                            <Radio value={'Sim'}>Sim</Radio>
                            </Space>
                        </Radio.Group>
                    </div>

                    <InputWithLabel
                        title={"Por que você merece participar do Bem Verão 2021?"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.descricaoParticipacao}
                        maxInputLength={50000}
                        onChange={onChangeInputEventHandler('descricaoParticipacao', 50000)}className="textInputArea"
                        inputPlaceholder={'Por que você merece participar do Bem Verão 2021?'}
                    />

                    <div style={{margin: '14px 0'}}>
                        <p className="title">Cidade de participação: {formValues.cidade}</p>
                    </div>
    

                    <Divider />

                    <div className="opcenquete align-left">
                        <ToggleSwitch checked={formValues.formularioInscricaoAceito}
                            setChecked={handleCheckedFormInscri}
                            name='formularioInscricaoAceito'
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarFormInscriModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos do formulário de inscrição para o <strong>BEM VERÃO 2021</strong>.
                            ​</span>                        
                        </button>
                        
                    </div>

                    <div className="opcenquete align-left">
                        <ToggleSwitch checked={formValues.termoAceito}
                            setChecked={handleCheckedTermo}
                            name='termoAceito'
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos de privacidade do <strong>BEM VERÃO 2021</strong>.
                            ​</span>
                            
                        </button>
                        
                    </div>
                    <div style={{marginTop: '14px'}}>
                    {(!formValues.termoAceito || !formValues.formularioInscricaoAceito) && 
                    <span>
                        <strong>Atenção</strong>: Neste caso (<strong>NÃO ACEITE</strong>) não será possível sua participação no <strong>BEM VERÃO 2021</strong>. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.
                    </span>}
                    </div>
                    
                    {
                        mostrarTermoModal &&    
                        <Modal
                            setTermoAceito={handleCheckedTermo}
                            setShowModal={setMostrarTermoModal}
                        >
                            <TermoBemVerao2021 />
                        </Modal>
                    } 
                    {
                        mostrarFormInscriModal &&    
                        <Modal 
                            setTermoAceito={handleCheckedFormInscri}
                            setShowModal={setMostrarFormInscriModal}>
                            <FormularioInscricao />
                        </Modal>
                    }                           
                </div>
            )}
            imagePath="/imgs/bem-verao-2021.png"
            programName="Bem Verão 2021"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="bemVerao2021"
        />
    )
}

export default ConcursoCaminhosDoCampo

import React from 'react'

import 'antd/dist/antd.css'

import ParamsProvider from './providers/ParamsProvider'
import FirebaseProvider from './providers/FirebaseProvider'
import RouterProvider from './providers/RouterProvider'
import UploadProvider from './providers/UploadProvider'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <ParamsProvider>
      <FirebaseProvider>
        <UploadProvider>
          <RouterProvider />
        </UploadProvider>
      </FirebaseProvider>
      <ToastContainer />
    </ParamsProvider>
  )
}

export default App

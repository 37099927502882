import React from "react";
import InputWithLabel from '../../../../../components/InputWithLabel';

export const FormCadastro = ({ formValues,  changeFormValue, responsavel="" }) => {
  const labelResponsavel = responsavel ?
  `( ${responsavel === "PrimeiroResponsavel" ? "Mãe" : "Pai"} ou responsável legal )`
  : ""

  return <>
    <InputWithLabel
      title={`Nome Completo ${labelResponsavel}`}
      onChange={(e) => changeFormValue('nomeCompleto'+responsavel, e.target.value)}
      minInputLength="6"
      required={true}
      inputValue={formValues['nomeCompleto'+responsavel]}
    />
   <InputWithLabel
      title={`RG ${labelResponsavel}`}
      onChange={(e) => changeFormValue('rg'+responsavel, e.target.value)}
      mask="rg"
      parser="rg"
      minInputLength='9'
      maxInputLength='9'
      required={true}
      inputValue={formValues['rg'+responsavel]}
    />
    <InputWithLabel
      title={`CPF ${labelResponsavel}`}
      onChange={(e) => { changeFormValue('cpf'+responsavel, e.target.value) }}
      mask="cpf"
      parser="cpf"
      minInputLength='11'
      maxInputLength='14'
      required={true}
      inputValue={formValues['cpf'+responsavel]}
    />
    <InputWithLabel
      title={`E-mail ${labelResponsavel}`}
      onChange={(e) => changeFormValue('email'+responsavel, e.target.value)}
      type="email"
      required={true}
      inputValue={formValues['email'+responsavel]}
    />

    <InputWithLabel
      title={`Telefone ${labelResponsavel}`}
      onChange={(e) => changeFormValue('telefone'+responsavel, e.target.value)}
      maxInputLength={16}
      mask='telefone'
      parser='onlyNumbers'
      required={true}
      inputValue={formValues['telefone'+responsavel]}
    />
    {/* <InputWithLabel
      title={`Endereço ${labelResponsavel}`}
      onChange={(e) => changeFormValue('endereco'+responsavel, e.target.value)}
      required={true}
      inputValue={formValues['endereco'+responsavel]}
    />
    <InputWithLabel
      title={`Cidade ${labelResponsavel}`}
      onChange={(e) => changeFormValue('cidade'+responsavel, e.target.value)}
      required={true}
      inputValue={formValues['cidade'+responsavel]}
    /> */}


  </>
}

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Divider } from 'antd'

import parsers from '../../../../utils/parsers'
import InputWithLabel from '../../../../components/InputWithLabel'
import DropdownWithLabel from '../../../../components/DropdownWithLabel'

import { checkUnfilledFields } from '../../../../utils/helpers'

const characterLimit = 255

const InscricaoPessoal = (props) => {
  const [formValues, setFormValues] = props.form
  const { setCurrentStep } = props

  const [cidades, setCidades] = useState()
  const [pracas, setPracas] = useState()
  const [ibges, setIbges] = useState()

  useEffect(() => {
    const loadCidadesPracas = async () => {
        const cidades = await fetch('/cidades.json').then(res => res.json())
        const cidadesObj = {}, pracasObj = {}, ibgesObj = {}
        Object.entries(cidades).forEach(
            ([key, cidade]) => {
                cidadesObj[`${cidade.nome}`] = cidade.nome
                pracasObj[`${cidade.nome}`] = cidade.praca
                ibgesObj[`${cidade.nome}`] = cidade.id_ibge
            }
        )
        setCidades(cidadesObj)
        setPracas(pracasObj)
        setIbges(ibgesObj)
    }
    loadCidadesPracas()
    }, [])

  useEffect(() => {
    const { formularioCidade } = formValues
    const praca = pracas ? pracas[formularioCidade] : undefined
    const ibge = ibges ? ibges[formularioCidade] : undefined

    if(!!formularioCidade && !!praca && !!ibge) {
      setFormValues(prev => ({
        ...prev,
        formularioPraca: praca,
        formularioIbge: ibge
      }))
    }
  }, [formValues.formularioCidade])

  const isNextButtonDisabled = useMemo(() => {
    const {
        nomeCompleto,
        formularioCidade,
        cpf,
        telefone,
        email
    } = formValues;

    const requiredFields = checkUnfilledFields({
        nomeCompleto: {
            value: nomeCompleto,
            minLength: 0
        },
        formularioCidade: {
            value: formularioCidade,
            minLength: 0
        },
        cpf: {
            value: cpf,
            minLength: 11
        },
        telefone: {
            value: telefone,
            minLength: 10
        },
        email: {
            value: email,
            minLength: 0
        }
    });

    return !(requiredFields.length === 0)
  }, [formValues])

  const onChangeDropdownEventHandler = useCallback(
    (attribute) => (event) => {
      const {options, selectedIndex} = event.target
      const value = options[selectedIndex].text
      setFormValues(prev => ({...prev, [`${attribute}`]: value}))
  }, [])

  const onChangeInputEventHandler = useCallback(
    (attribute, maxLength) => (event) => {
        const value = event.target.value;
        const valueLength = value.length;
        const parseType = event.target.dataset.parser
        const parsedValue = parseType ? parsers[parseType](value) : value
        if (valueLength <= maxLength) {
            setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
        }
    }, []
  );

  return <>
    <p style={{ margin: 0 }}>Inscrição pessoal</p>
    <Divider style={{ margin: '0px 0px 10px' }} />
    <InputWithLabel
      title={"Nome completo"}
      subTitle="Campo obrigatório"
      inputValue={formValues.nomeCompleto}
      onChange={onChangeInputEventHandler('nomeCompleto', characterLimit)}
      required
    />
    <DropdownWithLabel
      title={'Cidade'}
      subTitle="Campo obrigatório"
      values={cidades}
      onChange={onChangeDropdownEventHandler('formularioCidade')}
      required={formValues.formularioCidade.length === 0}
    />
    <InputWithLabel
      title={"CPF"}
      subTitle="Campo obrigatório"
      inputValue={formValues.cpf}
      onChange={onChangeInputEventHandler('cpf', characterLimit)}
      maxInputLength={14}
      mask='cpf'
      parser='onlyNumbers'
      required
    />
     <InputWithLabel
      title={"Telefone"}
      subTitle="Campo obrigatório"
      inputValue={formValues.telefone}
      onChange={onChangeInputEventHandler('telefone', characterLimit)}
      maxInputLength={16}
      mask='telefone'
      parser='onlyNumbers'
      required
    />
     <InputWithLabel
      title={"Email"}
      subTitle="Campo obrigatório"
      inputValue={formValues.email}
      onChange={onChangeInputEventHandler('email', characterLimit)}
      required
    />

     <div className="btsalvar">
        <button onClick={() => setCurrentStep(prev => prev + 1)} className="btsalvar button-caminhos-do-campo" disabled={isNextButtonDisabled}>
            Próximo
        </button>
    </div>
  </>
}

export default InscricaoPessoal

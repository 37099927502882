import React, { useContext, useEffect, useMemo, useState } from 'react'
import Modal from '../../../../components/Modal'
import ToggleSwitch from '../../../../components/ToggleSwitch'

// import TermoAgasalhoCinema2024 from '../../../../termosAceite/termoAgasalhoCinema2024'

import Base from './Base'
import { CurrentUserContext, FirebaseContext } from '../../../../Contexts'

const AgasalhoCinema2024 = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { firebaseFirestore: db } = useContext(FirebaseContext)

  const [dataTickets, setDataTickets] = useState([])
  const [loadingTickets, setLoadingTickets] = useState(false)

  const availableTicketsCollections = [
    {
      city: 'Curitiba',
      movies: [
        {
          name: 'Deadpool & Wolverine',
          date: '16/06/2024',
          location: 'R. Konrad Adenauer, 370 - Tarumã, Curitiba',
          coupons: [
            {
              hour: '19:30',
              day: 16,
              collection: 'agasalho-cinema-2024-deadpool-cascavel-1930'
            },
            {
              hour: '20:30',
              day: 16,
              collection: 'agasalho-cinema-2024-deadpool-cascavel-2030'
            },
            {
              hour: '21:30',
              day: 16,
              collection: 'agasalho-cinema-2024-deadpool-cascavel-2130'
            }
          ]
        },
        {
          name: 'Alien Romulus',
          date: '11/08/2024',
          location: 'Av. Cândido de Abreu, 127 - Centro Cívico, Curitiba',
          coupons: [
            {
              hour: '14:00',
              day: 11,
              collection: 'agasalho-cinema-2024-alien-cascavel-1930'
            },
            {
              hour: '16:30',
              day: 11,
              collection: 'agasalho-cinema-2024-alien-cascavel-2030'
            },
            {
              hour: '20:00',
              day: 11,
              collection: 'agasalho-cinema-2024-alien-cascavel-2130'
            }
          ]
        }
      ]
    },
    {
      city: 'Londrina',
      movies: [
        {
          name: 'Nome do Filme',
          date: '20/05/2024',
          location: 'Rua Endereço Teste, 642',
          coupons: [
            {
              hour: '19:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-1930'
            },
            {
              hour: '20:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-2030'
            },
            {
              hour: '21:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-2130'
            }
          ]
        },
        {
          name: 'Nome do Filme 2',
          date: '20/05/2024',
          location: 'Rua Maria Lima, 312',
          coupons: [
            {
              hour: '19:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-1930'
            },
            {
              hour: '20:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-2030'
            },
            {
              hour: '21:30',
              day: 20,
              collection: 'agasalho-cinema-2024-cascavel-2130'
            }
          ]
        }
      ]
    }
  ]

  useEffect(() => {
    const getAvailableTicketsAmmount = async () => {
      try {
        setLoadingTickets(true)

        const newDataTickets = []

        for (const item of availableTicketsCollections) {
          const moviesArray = []

          for (const movies of item.movies) {
            const couponsArray = []

            for (const coupons of movies.coupons) {
              couponsArray.push({
                movie: movies.name,
                date: movies.date,
                location: movies.location,
                day: coupons.day,
                hour: coupons.hour,
                // availableTicketsAmmount: data.quantidadeTicketsDisponiveis,
                availableTicketsAmmount: 30
                // availableTicketsAmmountRef: docRef
              })
            }

            moviesArray.push({
              movie: movies.name,
              date: movies.date,
              location: movies.location,
              coupons: couponsArray
            })
          }

          const dataTicket = {
            city: item.city,
            movies: moviesArray
          }

          newDataTickets.push(dataTicket)
        }

        setDataTickets(newDataTickets)
      } catch (error) {
        console.error('Error fetching available tickets:', error)
      }
    }

    getAvailableTicketsAmmount()
  }, [])

  return (
    <Base
      description={() => (
        <div>
          <h2 className="subtitle">
            ESCOLHA O DIA E O HORÁRIO PARA RESGATAR SEU INGRESSO
          </h2>
        </div>
      )}
      imagePath="/imgs/agasalho-2024.png"
      programName="Agasalho Cinema 2024"
      tipoTermo="termoAgasalhoCinema2024"
      dataTickets={dataTickets}
      loadingTickets={loadingTickets}
      setLoadingTickets={setLoadingTickets}
      db={db}
    />
  )
}

export default AgasalhoCinema2024

import React, { useContext, useCallback, useState, useEffect } from 'react'

import Loading from '../../../components/Loading'

import { CONTEUDO_API_URL } from '../../../constants/api'
import {
  FirebaseContext,
  CurrentUserContext,
  UploadContext
} from '../../../Contexts'
import { useCallbackStatic } from '../../../hooks/utils'
import { useRequest } from '../../../hooks/request'
import moment from 'moment'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getCity from '../../../utils/getCity'
import getPraca from '../../../utils/getPraca'
import getDeviceInfos from '../../../utils/getDeviceInfos'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import Footer from '../../../components/Footer'
import sendUserConsent from '../../../utils/sendUserConsent'
import originConsents from '../../../constants/originConsents'
import * as Sentry from '@sentry/react'

const Base = ({
  title,
  description,
  mediaID,
  imagePath,
  programName,
  selectedMedia,
  setSelectedMedia,
  tituloFoto,
  setTituloFoto,
  descricaoFoto,
  setDescricaoFoto,
  setTermoAceito,
  tipoTermo,
  customFirebaseCollection = null,
  customFirebaseControlKey = null,
  salvarOk = false
}) => {
  const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { S3StartUpload } = useContext(UploadContext)

  console.log(currentUser)

  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)
  const [userIdToken, setUserIdToken] = useState('')

  const termoFamiliaETudo2024 = 'termoFamiliaETudo2024'

  useEffect(() => {
    const getFirebaseToken = async () => {
      if (firebaseAuth.currentUser) {
        const token = await firebaseAuth.currentUser.getIdToken()
        setUserIdToken((_) => token)
      }
    }
    getFirebaseToken()
  }, [firebaseAuth.currentUser])

  const back = useCallbackStatic(() => {
    cleanDataState()
    setResult(false)
  })

  const [, , fetchSave] = useRequest(
    null,
    {
      url: `${CONTEUDO_API_URL}/midias`,
      method: 'post',
      headers: { Authorization: `Bearer ${userIdToken}` },
      data: {
        id_categoria_envio: mediaID,
        titulo: tituloFoto,
        descricao: descricaoFoto,
        midias: selectedMedia.map((item) => ({
          path: item.path,
          type: item.type
        }))
      }
    },
    null,
    {
      onComplete: async (data) => onComplete(data)
    }
  )

  const cleanDataState = () => {
    setSelectedMedia([])
    setTituloFoto('')
    setDescricaoFoto('')
    setTermoAceito(false)
  }

  const FirestoreStartUpload = async () => {
    const firebaseMensagem = {
      cidade: getCity(currentUser),
      created_at: moment().toDate(),
      id: `${currentUser.uid}-${moment().unix()}`,
      praca: await getPraca(currentUser),
      usr_email: currentUser.email,
      usr_id: currentUser.uid,
      usr_name: currentUser.nome,
      termo: tipoTermo,
      ipV4: await getClientIPv4(),
      device_infos: getDeviceInfos()
    }

    console.log('firebaseMensagem: ', firebaseMensagem)

    const termosCollection = firebaseFirestore
      .collection('flerken-interatividade')
      .doc('TERMOS_ACEITE')
      .collection('termos')
    try {
      await termosCollection.doc(firebaseMensagem.id).set(firebaseMensagem)

      await sendUserConsent({
        name: currentUser.nome,
        email: currentUser.email,
        origin: originConsents[termoFamiliaETudo2024]
      })
      console.log('Dados do termo enviados com sucesso para o firestore')
    } catch (err) {
      console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
    }
  }

  const send = useCallbackStatic(async () => {
    setLoading(true)
    getDeviceInfos()
    await fetchSave()
  })

  const onComplete = async (data) => {
    Sentry.captureEvent(data)
    try {
      if (data.status === 200) {
        const parts = data.token.split('.')

        if (parts.length < 3) return

        const encodedPayload = parts[1]
        const payload = JSON.parse(atob(encodedPayload))

        const files = []
        payload.ids_arquivos.forEach((item, index) => {
          const filename = selectedMedia[index].name
          if (filename.length < 2) {
            console.log('Skipping non-image')
            return
          }

          const typeMatch = filename.match(/\.([^.]*)$/)
          console.log('typeMatch: ', typeMatch)
          if (!typeMatch) {
            console.log('Skipping non-image2')
            return
          }

          const imageType = typeMatch[1].toLowerCase()
          if (
            imageType !== 'jpg' &&
            imageType !== 'jpeg' &&
            imageType !== 'png' &&
            imageType !== 'mp4'
          ) {
            console.log('Skipping non-image3')
            return
          }

          const s3Filename = btoa(
            `{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`
          )
          const name = `${s3Filename}.${imageType}`
          const file = selectedMedia[index]
          files.push({ name, file })
        })
        await S3StartUpload(files)
        await FirestoreStartUpload()
        setLoading(false)
        setResult(true)
      }
    } catch (error) {
      Sentry.captureEvent(new Error(error))
      setLoading(false)
      console.log('Error: ', error)
    }
  }

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <div className="container-page">
          <Player
            autoplay
            loop
            src="https://lottie.host/06400ccd-5f52-4962-9b80-726706404d05/Z4GGWy8UPJ.json"
            style={{ height: '200px', width: '200px' }}
          >
            <Controls
              visible={false}
              buttons={['play', 'repeat', 'frame', 'debug']}
            />
          </Player>
          <div className="title-enquete">
            <h1>Enviando sua foto</h1>
          </div>
          <br />
          <div className="sub-title">
            <h6>Por favor, não feche esta tela até o processo finalizar.</h6>
          </div>
        </div>
      )
    }
    if (!currentUser) {
      return (
        <div className="container-page">
          <Player
            autoplay
            keepLastFrame
            src="https://lottie.host/26f7167d-21f8-4234-91b5-ca19aecfb7fb/Dyw0I3MuB4.json"
            style={{ height: '160px', width: '160px' }}
          >
            <Controls
              visible={false}
              buttons={['play', 'repeat', 'frame', 'debug']}
            />
          </Player>
          <div className="title-enquete">
            <h1>Usuário não autenticado</h1>
          </div>
          <br />
        </div>
      )
    }
    if (!result) {
      return (
        <div className="container-page familiaetudo">
          <div className="title-enquete">
            <h1>{title}</h1>
          </div>
          <div className="description-enquete">{description()}</div>
          <div className="btn-default">
            <button onClick={() => send()} disabled={salvarOk}>
              Enviar
            </button>
          </div>
        </div>
      )
    }
    return (
      <div className="container-page">
        <Player
          autoplay
          keepLastFrame
          src="https://lottie.host/9b0cc4f5-937f-4dcc-bd4c-0450d0fa6792/l1erLfIKCb.json"
          style={{ height: '200px', width: '200px' }}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>
        <div className="title-enquete">
          <h1>Foto enviada com sucesso!</h1>
        </div>
        <br />
        <br />
        <div className="btn-default">
          <button onClick={back}>Enviar nova foto</button>
        </div>

        <Footer />
      </div>
    )
  }, [result, back, title, description, salvarOk, send, loading])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img src={imagePath} alt="banner" />
        </div>
        {renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

import dayjs from 'dayjs'

export const handleFilterDate = (date, horario) =>{
  const dateFiltered = horario ? date.filter((date, index) => {
    if(horario === "manha"){
      return parseInt(dayjs(date).format("DD")) < 15
    }
    return parseInt(dayjs(date).format("DD")) > 15
  }) : date

  return dateFiltered.map((d, index) =>
  d.format("DD/MM/YYYY") + " - " + (parseInt(dayjs(d).format("DD")) < 15 ? "Manhã" : "Tarde"))
}

/* eslint-disable import/prefer-default-export */

import moment from 'moment'

import getDeviceInfos from '../../../../utils/getDeviceInfos'
import getClientIPv4 from '../../../../utils/getPublicIPv4'
import getTicket from '../../../../utils/getTicket'

export const uploadFirestore = async ({
  currentUser,
  tipoTermo,
  termoAceito,
  setLoading,
  setResult,
  setHasError,
  hasError,
  db,
  itemSelected,
  setUserTicket
}) => {
  const firebaseMensagem = {
    created_at: moment().toDate(),
    id: `${currentUser.uid}-${moment().unix()}`,
    usr_email: currentUser.email,
    usr_id: currentUser.uid,
    usr_name: currentUser.nome,
    cpf: currentUser.cpf,
    termo: tipoTermo,
    ipV4: await getClientIPv4(),
    device_infos: getDeviceInfos()
  }

  try {
    await db.runTransaction(async (transaction) => {
      const { day, hour, availableTicketsAmmountRef } = itemSelected

      const doc = await transaction.get(availableTicketsAmmountRef)

      const docData = doc.data()

      const availableTickets = docData.quantidadeTicketsDisponiveis
      const newAvailablity = availableTickets - 1

      console.log('TICKETS DISPONÍVEIS', availableTickets)
      console.log('NOVA DISPONIBILIDADE', newAvailablity)

      const currentTicketIndex = docData.ticketCorrente
      const newTicketIndex = currentTicketIndex + 1

      const ticket = getTicket(
        `PM${day}${hour.replace(':', '')}`,
        +newTicketIndex
      )

      if (newAvailablity < 0) {
        setHasError('Cupons esgotados.')
      } else {
        transaction.update(availableTicketsAmmountRef, {
          quantidadeTicketsDisponiveis: newAvailablity,
          ticketCorrente: newTicketIndex
        })
        setResult(true)
        setUserTicket(ticket)

        const ticketDocRef = availableTicketsAmmountRef
          .collection('tickets')
          .doc()
        await transaction.set(ticketDocRef, {
          ticket: ticket,
          id: currentUser.uid,
          nome: currentUser.nome,
          email: currentUser.email,
          created_at: new Date()
        })
      }

      console.log(doc)
    })

    console.log('Transaction success!')
  } catch (e) {
    console.log('Transaction failure:', e)
  }

  if (!hasError) {
    try {
      const collection = await db
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')
      collection.doc(firebaseMensagem.id).set(firebaseMensagem)

      console.log('firebaseMensagem: ', firebaseMensagem)
      console.log('Dados do formulario enviados com sucesso')
      setLoading(false)
      setResult(true)
    } catch (err) {
      console.log('Ocorreu um erro ao enviar os dados do formulario', err)
      setHasError('Falha ao gerar o ticket.')
    }
  }
}

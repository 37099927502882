/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from 'react'
import axios, { CancelToken } from 'axios'
import * as Sentry from '@sentry/react'

export const useRequest = (
  initialData,
  requestOptions,
  transform = null,
  events = {}
) => {
  const cancelRequestRef = useRef()

  const [state, setState] = useState({
    data: initialData,
    statusRequest: {
      done: false,
      isLoading: false,
      success: false,
      error: false
    }
  })

  const { authorizationToken } =
    requestOptions.authorizationToken === undefined ? '' : requestOptions

  const buildRequest = (options) => {
    if (typeof options === 'string') {
      return {
        url: options,
        method: 'get',
        headers: { Authorization: `Bearer ${authorizationToken}` },
        data: ''
      }
    }
    return {
      url: options.url,
      method: options.method,
      headers: options.headers,
      data: options.data
    }
  }

  const buildFetch = async (force = false) => {
    if (!state.statusRequest.isLoading || force) {
      setState({
        data: state.data,
        statusRequest: {
          done: false,
          isLoading: true,
          success: false,
          error: false
        }
      })
      const { method, url, headers, data } = buildRequest(requestOptions)
      try {
        const result = await axios({
          method,
          url,
          headers,
          data,
          cancelToken: new CancelToken((c) => {
            cancelRequestRef.current = c
          })
        })

        setState({
          data: transform ? transform(result.data) : result.data,
          statusRequest: {
            done: true,
            isLoading: false,
            success: true,
            error: false
          }
        })

        if (events.onComplete) {
          events.onComplete(
            transform ? transform(result.data) : result.data,
            result.data
          )
        }
      } catch (error) {
        Sentry.captureException(new Error(error))
        console.log(JSON.stringify(error))
        if (!axios.isCancel(error)) {
          setState({
            data: state.data,
            statusRequest: {
              done: true,
              isLoading: false,
              success: false,
              error: true
            }
          })
        }
      }
    }
  }

  const fetchRef = useRef()
  useEffect(() => {
    fetchRef.current = buildFetch
  }, [buildFetch])

  const fetch = () => {
    if (requestOptions.cancelRequest) {
      if (cancelRequestRef.current) {
        cancelRequestRef.current()
      }
      if (fetchRef.current) {
        fetchRef.current(true)
      }
    } else if (fetchRef.current) {
      fetchRef.current()
    }
  }

  const clearData = () => {
    if (requestOptions.cancelRequest && cancelRequestRef.current) {
      cancelRequestRef.current()
    }

    setState({
      data: initialData,
      statusRequest: {
        done: false,
        isLoading: false,
        success: false,
        error: false
      }
    })
  }

  return [state.data, state.statusRequest, fetch, clearData]
}

export default {
  useRequest
}

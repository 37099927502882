import React, { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'

import VideoPlayer from '../../components/VideoPlayer'
// import { GA_VIDEO_TRACKING_CODE } from '../../constants/googleAnalytics'

// ReactGA.initialize(GA_VIDEO_TRACKING_CODE, {
//     debug: true,
// })

const videoName = 'Amor por Cascavel comunicação interna'
const videoURL='https://comunicados-grpcom.s3.amazonaws.com/amor_por_cascavel_comunicacao_interna.mp4'

const Video = () => {
    const [percPlayed, setPercPlayed] = useState(0)

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const handleVideoProgress = useCallback(({played}) => {
        const decimalPercentage = parseInt((played * 10) % 10, 10)
        if(percPlayed < decimalPercentage) {
            setPercPlayed(decimalPercentage)
            ReactGA.event({
                category: 'Video View',
                action: `${videoName}`,
                label: `${decimalPercentage * 10}%`,
            })
        }
    }, [percPlayed])

    const handleVideoStarted = useCallback((state) => {
        ReactGA.event({
            category: 'Video View',
            action: `${videoName}`,
            label: 'started',
        })
    }, [])

    const handleVideoEnded = useCallback((state) => {
        ReactGA.event({
            category: 'Video View',
            action: `${videoName}`,
            label: 'ended',
        })
    }, []) 

    return (
        <div className="enqueterpc">
            <div className="imagemdestaque">
                <img src="/imgs/amordemae.png" alt="banner"/>
            </div>
            <VideoPlayer url={videoURL} 
                onProgress={handleVideoProgress}
                onStart={handleVideoStarted} 
                onEnded={handleVideoEnded}
            /> 
        </div>
    )
}

export default Video

import React, { useContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import Loading from '../../components/Loading'

import { AVALIACOES_ATENDIMENTOS_API_URL } from '../../constants/api'
import { FirebaseContext, CurrentUserContext } from '../../Contexts'
import { useCallbackStatic } from '../../hooks/utils'

// const characterLimit = 240;

const Base = (props) => {
  const {
    title,
    description,
    imagePath,
    programName,
    satisfacao,
    comentarios,
    nome,
    telefone,
    email,
    clearForm
  } = props

  const { id: atendimentoId } = useParams()

  const { firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [userIdToken, setUserIdToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(false)
  const [hasError, setHasError] = useState(undefined)

  useEffect(() => {
    const getFirebaseToken = async () => {
      if (firebaseAuth.currentUser) {
        const token = await firebaseAuth.currentUser.getIdToken()
        setUserIdToken((_) => token)

        const url = `${AVALIACOES_ATENDIMENTOS_API_URL}/avaliacao/${atendimentoId}`
        const headers = { Authorization: `Bearer ${token}` }
        const result = await axios.get(url, { headers })
        const response = result.data

        if (response.valid) {
          setLoading(false)
        } else {
          setHasError('Avaliação já realizada')
        }

      } else {
        setHasError('Usuário não autenticado')
      }
    }
    getFirebaseToken()
  }, [firebaseAuth.currentUser])

  const back = useCallbackStatic(() => {
    setResult(false)
  })

  const send = useCallbackStatic(async (data) => {
    setLoading(true)

    const messageParams = {}

    if (currentUser.podeEnviarMensagens) {
      const idToken = await firebaseAuth.currentUser.getIdToken()
      const url = `${AVALIACOES_ATENDIMENTOS_API_URL}/avaliacao/${atendimentoId}`
      const headers = { Authorization: `Bearer ${idToken}` }

      const body = {
        satisfacao: data.satisfacao,
        comentarios: data.comentarios,
      }

      console.log("🚀 ~ file: Base.js ~ line 71 ~ send ~ body", body)
      const result = await axios.post(url, body, { headers })
      const apiMensagem = result.data

      messageParams.created_at = moment(apiMensagem.data_criacao).toDate()
      messageParams.id = apiMensagem.id
    } else {
      messageParams.created_at = moment().toDate()
      messageParams.id = `${currentUser.uid}-${moment().unix()}`
    }

    clearForm()
    setResult(true)
    setLoading(false)
  })

  const sendMessage = useCallback(() => {
    if (!atendimentoId || atendimentoId.length <= 0) {
      alert('Atendimento inválido')
      return
    }

    if (!satisfacao || satisfacao.length <= 0) {
      alert('Escolha seu nível de satisfação')
      return
    }

    // if (!comentarios || comentarios.length <= 0) {
    //   alert('Escreva um comentário')
    //   return
    // }

    send({
      atendimentoId,
      satisfacao,
      comentarios,
      nome,
      telefone,
      email
    })
  }, [send, atendimentoId, satisfacao, comentarios, nome, telefone, email])

  const renderError = useCallback(
    () => (
      <div className="container">
        <div className="titenquete">{title}</div>
        <div className="descenquete">
          {hasError}
        </div>
      </div>
    ),
    [hasError, title]
  )

  const renderContent = useCallback(() => {
    if (!result) {
      return (
        <div className="container">
          <div className="titenquete">{title}</div>
          <div className="descenquete">{description()}</div>

          <div className="btsalvar">
            <button onClick={sendMessage}>Enviar</button>
          </div>
        </div>
      )
    }
    return (
      <div className="container">
        <div className="titenquete">Avaliação enviada com sucesso!</div>
      </div>
    )
  }, [back, description, result, sendMessage, title])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        {hasError ? renderError() : loading ? <Loading /> : renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

import React, { useCallback, useState } from 'react'
import ImagePicker from '../../components/ImagePicker'
import InputWithLabel from '../../components/InputWithLabel'
import MediaGallery from '../../components/MediaGallery'
import Modal from '../../components/Modal'
import ToggleSwitch from '../../components/ToggleSwitch'

import TermoAmorDeMae from '../../termosAceite/termoAmorDeMae'
import Base from './Base'

const characterLimitArea = 240
const characterLimit = 50


const AmorDeMae = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState([])
    const [termoAceito, setTermoAceito] = useState(false)
    const [descricao, setDescricao] = useState('')
    const [titulo, setTitulo] = useState('')

    const handleDescricaoChange = useCallback((event) => {
        const axCount = event.target.value.length
        if (axCount <= characterLimitArea) {
            setDescricao(event.target.value)
        }
    }, [])
    
    const handleTituloChange = useCallback((event) => {
        const axCount = event.target.value.length
        if (axCount <= characterLimit) {
            setTitulo(event.target.value)
        }
    }, [])

    return (
        <Base
            title="Envie a sua foto para a novela Amor De Mãe!"
            description={() => (
                <div className="container">
                    <MediaGallery medias={selectedMedia} />
                    <ImagePicker setMedias={setSelectedMedia} />
                    
                    <InputWithLabel title={'Título'} inputValue={titulo} onChange={handleTituloChange}/>
                    <InputWithLabel title={'Descrição'} subTitle={"escreva o nome da mãe e a cidade onde ela mora"} inputValue={descricao} onChange={handleDescricaoChange} className="textInputArea" />

                    <div className="opcenquete align">
                        <ToggleSwitch checked={termoAceito} setChecked={setTermoAceito}/>
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>Li e aceito os termos de compromisso</span>
                        </button>
                    </div>

                    {
                        mostrarTermoModal &&    
                        <Modal setTermoAceito={setTermoAceito} setShowModal={setMostrarTermoModal}>
                            <TermoAmorDeMae />
                        </Modal>
                    }                   
                </div>
            )}
            imagePath="/imgs/amordemae.png"
            programName="Amor de Mae"
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            tituloFoto={titulo}
            setTituloFoto={setTitulo}
            descricaoFoto={descricao}
            setDescricaoFoto={setDescricao}
            salvarOk = {selectedMedia.length !== 1 || !termoAceito}
            tipoTermo="amor_de_mae"
            mediaID={30}
        />
    )
}

export default AmorDeMae

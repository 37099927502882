import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Divider, Alert } from 'antd'

import MediaGallery from '../../../../components/MediaGallery'
import ImagePicker from '../../../../components/ImagePicker'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import Modal from '../../../../components/Modal'
import TermoCaminhosDoCampo from '../../../../termosAceite/termoCaminhosDoCampo'
import FormularioInscricao from '../../CaminhosDoCampo/FormularioInscricao'

import { checkUnfilledFields } from '../../../../utils/helpers'

const FotoReceita = (props) => {
  const [formValues, setFormValues] = props.form
  const { setLoading } = props

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [mostrarFormInscriModal, setMostrarFormInscriModal] = useState(false)
  const [mediaError, setMediaError] = useState('')

  useEffect(() => {
    if(mediaError.length > 0) {
      setFormValues(prev => ({ ...prev, media: [] }))
    }
  }, [mediaError])

  const handleSetMedia = useCallback((media) => {
    const filename = media.name
    if (filename.length < 2) {
      console.error('Nome do arquivo de mídia invalido')
      setMediaError('Erro: nome do arquivo de mídia é invalido');
      return
    }

    const typeMatch = filename.match(/\.([^.]*)$/)
    if (!typeMatch) {
        console.error('typeMatch inválido')
        setMediaError('Erro: nome do arquivo de mídia é inválido');
        return
    }

    const mediaFormat = media.type
    if (!mediaFormat.includes('image/')) {
        console.error(`Extensão do arquivo inválido ${mediaFormat}`)
        setMediaError(`Erro: tipo de arquivo não suportado (${mediaFormat}). É permitido somente o envio de arquivos .jpg e .png`);
        return
    }

    setMediaError('')
    setFormValues(prev => ({...prev, media: [media] }))
  }, [])

  const handleCheckedTermo = useCallback(() => {
    setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
  }, [])

  const handleCheckedFormInscri = useCallback(() => {
    setFormValues(prev => ({...prev, formularioInscricaoAceito: !prev.formularioInscricaoAceito}))
  }, [])

  const isNextButtonDisabled = useMemo(() => {
    const {
      nomeCompleto,
      formularioCidade,
      cpf,
      telefone,
      email,
      receitaNome,
      receitaCategoria,
      receitaIngredientes,
      receitaModoPreparo,
      receitaInspiracao,
      receitaTempoCozimentoHoras,
      receitaTempoCozimentoMinutos,
      receitaPorcoes,
      receitaNivelDificuldade,
      receitaDiasQuentesFrios,
      receitaPratoAssadoCozido,
      termoAceito,
      formularioInscricaoAceito,
      media,
    } = formValues;

    const requiredFields = checkUnfilledFields({
      nomeCompleto: {
        value: nomeCompleto,
        minLength: 1
      },
      formularioCidade: {
        value: formularioCidade,
        minLength: 1
      },
      cpf: {
        value: cpf,
        minLength: 11
      },
      telefone: {
        value: telefone,
        minLength: 10
      },
      email: {
        value: email,
        minLength: 1
      },
      receitaNome: {
        value: receitaNome,
        minLength: 1,
      },
      receitaCategoria: {
        value:  receitaCategoria,
        minLength: 1
      },
      receitaIngredientes: {
        value: receitaIngredientes,
        minLength: 1
      },
      receitaModoPreparo: {
        value: receitaModoPreparo,
        minLength: 1
      },
      receitaInspiracao: {
        value: receitaInspiracao,
        minLength: 1
      },
      receitaTempoCozimentoHoras: {
        value: receitaTempoCozimentoHoras,
        minLength: 1
      },
      receitaTempoCozimentoMinutos: {
        value:  receitaTempoCozimentoMinutos,
        minLength: 1
      },
      receitaPorcoes: {
        value: receitaPorcoes,
        minLength: 1
      },
      receitaNivelDificuldade: {
        value: receitaNivelDificuldade,
        minLength: 1
      },
      receitaDiasQuentesFrios: {
        value: receitaDiasQuentesFrios,
        minLength: 1
      },
      receitaPratoAssadoCozido: {
        value: receitaPratoAssadoCozido,
        minLength: 1
      }
    });

    return !(
      requiredFields.length === 0
      && media.length === 1
      && termoAceito
      && formularioInscricaoAceito
    )
  }, [formValues])

  console.log({ formValues })

  return <>
     <p style={{ margin: 0 }}>Foto da receita</p>
     <Divider style={{ margin: '0px 0px 10px' }} />

     {mediaError.length > 0 && <Alert
       type='error'
       style={{ borderRadius: '25px' }}
       message="Foto inválida"
       description={mediaError}
       onClose={() => setMediaError('')}
       closable
     />}

     <MediaGallery medias={formValues.media} />
     <ImagePicker
        handleSetMedias={handleSetMedia}
        required={true}
        title='Selecione uma foto da sua receita'
      />


      <div className="opcenquete align-left">
              <ToggleSwitch checked={formValues.formularioInscricaoAceito}
                  setChecked={handleCheckedFormInscri}
                  name='formularioInscricaoAceito'
              />
              <button type="button" className="btTransparente"
                  onClick={() => {
                      document.body.scrollTop = 0 // For Safari
                      document.documentElement.scrollTop = 0 // FF, GC, Opera
                      document.body.style.overflowY = 'hidden';
                      setMostrarFormInscriModal(_ => true)
                  }}
              >
                  <span>
                      Declaro que li e aceito os termos do formulário de inscrição para o concurso <strong>CAMINHOS DO CAMPO 2022</strong>.
                  ​</span>
              </button>

          </div>

          <div className="opcenquete align-left">
              <ToggleSwitch checked={formValues.termoAceito}
                  setChecked={handleCheckedTermo}
                  name='termoAceito'
                  style={{ width: '120px' }}
              />
              <button type="button" className="btTransparente"
                  onClick={() => {
                      document.body.scrollTop = 0 // For Safari
                      document.documentElement.scrollTop = 0 // FF, GC, Opera
                      document.body.style.overflowY = 'hidden';
                      setMostrarTermoModal(_ => true)
                  }}
              >
                  <span>
                      Declaro que li e aceito os termos de privacidade do concurso <strong>CAMINHOS DO CAMPO 2022</strong>.
                  ​</span>

              </button>

          </div>
          <div style={{marginTop: '14px'}}>
          {(!formValues.termoAceito || !formValues.formularioInscricaoAceito) &&
          <span className='aviso-termo'>
              <strong>Atenção</strong>: Neste caso (<strong>NÃO ACEITE</strong>) não será possível sua participação no concurso <strong>CAMINHOS DO CAMPO 2022</strong>. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.
          </span>}
          </div>

          {
              mostrarTermoModal &&
              <Modal
                  setTermoAceito={handleCheckedTermo}
                  setShowModal={setMostrarTermoModal}
              >
                  <TermoCaminhosDoCampo />
              </Modal>
          }
          {
              mostrarFormInscriModal &&
              <Modal
                  setTermoAceito={handleCheckedFormInscri}
                  setShowModal={setMostrarFormInscriModal}>
                  <FormularioInscricao />
              </Modal>
          }

    <div className="btsalvar">
      <button onClick={() => setLoading(true)} className="btsalvar button-caminhos-do-campo" disabled={isNextButtonDisabled}>
          Enviar receita
      </button>
    </div>
  </>
}

export default FotoReceita

import React from 'react'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { DatePicker } from 'antd'
import moment from 'moment'

const DatePickerWithLabel = ({
  title,
  subTitle,
  onChange,
  inputValue = '',
  inputPlaceholder = `${title}...`,
  className = "datePicker",
  format = 'DD/MM/YYYY',
  disabledDate = (current) => {},
  showToday = true,
  readonly = false,
  defaultValue = moment(),
  popupStyle = {},
  style = {}
}) => {
    return (
        <>
            <p className="title">
                {title}
                {subTitle &&
                <span className="subTitle">
                    {`(${subTitle})`}
                </span>
                }
            </p>

            <DatePicker
              locale={locale}
              className={className}
              placeholder={inputPlaceholder}
              onChange={(value) => onChange(value)}
              value={inputValue}
              inputReadOnly={readonly}
              disabledDate={disabledDate}
              defaultPickerValue={defaultValue}
              showToday={showToday}
              format={format}
              popupStyle={popupStyle}
              style={style}

            />
            {inputValue.length > 0 &&
            <span className="invalidInput">Necessário preencher o campo</span>}
        </>
    )
}

export default DatePickerWithLabel

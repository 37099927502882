import React, { useCallback, useState } from 'react'
import { Radio, Space } from 'antd'

import InputWithLabel from '../../components/InputWithLabel'

import Base from './Base'

// const characterLimitArea = 240;
const characterLimit = 50

const Avaliacao = () => {
  const [satisfacao, setSatisfacao] = useState('')
  const [comentarios, setComentarios] = useState('')
  const [nome, setNome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')

  const clearForm = useCallback(() => {
    setSatisfacao('')
    setComentarios('')
    setNome('')
    setTelefone('')
    setEmail('')
  }, [setSatisfacao, setComentarios, setNome, setTelefone, setEmail])

  const onChangeEventHandler = useCallback(
    (setter, maxLength) => (event) => {
      const value = event.target.value
      const valueLength = value.length

      if (valueLength <= maxLength) {
        setter(value)
      }
    },
    []
  )

  return (
    <Base
      title="Atendimento RPC - Pesquisa de Satisfação!"
      description={() => (
        <div className="container">
          <div className="subtitle">
            Para garantir a sua privacidade, esta pesquisa não é identificada e
            seus resultados terão uso exclusivamente interno, com o objetivo de
            melhorarmos a nossa prestação de serviço.
          </div>

          <br />

          <div>
            <div className="title">
              Qual a sua satisfação com relação ao atendimento realizado pela
              central do telespectador?'
            </div>

            <Radio.Group
              value={satisfacao}
              onChange={onChangeEventHandler(setSatisfacao, characterLimit)}
            >
              <Space direction="vertical">
                <Radio value="Muito Satisfeito">Muito Satisfeito</Radio>
                <Radio value="Satisfeito">Satisfeito</Radio>
                <Radio value="Nem satisfeito, nem insatisfeito">
                  Nem satisfeito, nem insatisfeito
                </Radio>
                <Radio value="Insatisfeito">Insatisfeito</Radio>
                <Radio value="Muito insatisfeito">Muito insatisfeito</Radio>
              </Space>
            </Radio.Group>

            <br />
            <br />
          </div>

          <InputWithLabel
            title={'Deixe aqui seus comentários:'}
            inputValue={comentarios}
            className="textInputArea"
            maxInputLength="3000"
            onChange={onChangeEventHandler(setComentarios, 3000)}
          />

          {/* <hr />

          <h5>
            Se desejar que a Central de Relacionamento com o telespectador,
            entre em contato para tratar de algum caso específico, por favor
            indique os dados abaixo:
          </h5>

          <InputWithLabel
            title={'Nome'}
            inputValue={nome}
            onChange={onChangeEventHandler(setNome, characterLimit)}
          />

          <InputWithLabel
            title={'Telefone'}
            inputValue={telefone}
            onChange={onChangeEventHandler(setTelefone, characterLimit)}
          />

          <InputWithLabel
            title={'E-mail'}
            inputValue={email}
            onChange={onChangeEventHandler(setEmail, characterLimit)}
          /> */}
        </div>
      )}
      clearForm={clearForm}
      satisfacao={satisfacao}
      comentarios={comentarios}
      nome={nome}
      telefone={telefone}
      email={email}
      imagePath="/imgs/recicla.png"
      programName="Atendimento RPC - Pesquisa de Satisfação"
      mediaID={30}
    />
  )
}

export default Avaliacao

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { batch } from 'react';
import VideoPicker from '../../../components/VideoPicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'
import DropdownWithLabel from '../../../components/DropdownWithLabel'

import { TermoProfessor } from '../../../termosAceite/termoTelevisando'
import Base from './Base'
import { checkUnfilledFields } from '../../../utils/helpers';

const characterLimit = 100

const TelevisandoProfessor = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [cidades, setCidades] = useState() 

    const [formValues, setFormValues] = useState({
        type: 'Professor',
        nomeCompletoProfessor: '',
        cpf: '',
        email: '',
        cidade: '',
        escola: '',
        turma: '',
        descricao: '',
        termoAceito: false,
        media: []
    })

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json())
            const cidadesObj = {}
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj[`${cidade.nome}`] = cidade.nome 
                }
            )
            setCidades(cidadesObj)
        }
        loadCidades()
    }, [])
    
    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength=0, unlimitedLength=false) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
        
            if(unlimitedLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: value}))
            } else if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: value}))
            }
        }, []
    );

    const onChangeDropdownEventHandler = useCallback((attribute) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text
        setFormValues(prev => ({...prev, [`${attribute}`]: value}))
    }, [])

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}));
    }, [])

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [])

    const salvarOk = useMemo(() => {
        const { 
            nomeCompletoProfessor,
            cpf,
            email,
            cidade,
            escola,
            turma,
            descricao,
            media
        } = formValues;

        const requiredFields = checkUnfilledFields({
            nomeCompletoProfessor: {
                value: nomeCompletoProfessor,
                minLength: 0
            },
            cpf: {
                value: cpf,
                minLength: 0
            },
            email: {
                value: email,
                minLength: 0
            },
            cidade: {
                value: cidade,
                minLength: 0
            },
            escola: {
                value: escola,
                minLength: 0
            },
            turma: {
                value: turma,
                minLength: 0
            },
            descricao: {
                value: descricao,
                minLength: 10
            }    
        });

        return !(
            formValues.termoAceito
            && requiredFields.length === 0
            && media.length !== 0
        )
    }, [formValues])

    return (
        <Base
            title="Professor Televisando"
            description={() => (
                <div className="container">
                    <InputWithLabel
                        title={"Nome completo do professor"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompletoProfessor}
                        onChange={onChangeInputEventHandler('nomeCompletoProfessor', characterLimit)}
                    />
                    <InputWithLabel
                        title={"CPF"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.cpf}
                        onChange={onChangeInputEventHandler('cpf', 11)}
                        />
                    <InputWithLabel
                        title={"E-mail"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.email}
                        onChange={onChangeInputEventHandler('email', characterLimit)}
                        />
                                        
                    <DropdownWithLabel
                        title={'Cidade'}
                        subTitle="Campo obrigatório"
                        values={cidades}
                        onChange={onChangeDropdownEventHandler('cidade')}
                    />

                    <InputWithLabel
                        title={"Escola"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.escola}
                        onChange={onChangeInputEventHandler('escola', 200)}
                        />
                    <InputWithLabel
                        title={"Turma que leciona"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.turma}
                        onChange={onChangeInputEventHandler('turma', 20)}
                    /> 
                    <MediaGallery medias={formValues.media} />
                    <VideoPicker handleSetMedias={handleSetMedia} required={true}/>
                    
                    <InputWithLabel
                        title={"Nos conte: como foi realizar o Telejornal? Quais foram os resultados que você alcançou com ele? ​"}
                        inputValue={formValues.descricao}
                        subTitle="Campo obrigatório"
                        minInputLength={10}
                        maxInputLength={1400}
                        onChange={onChangeInputEventHandler('descricao', 0, true)} className="textInputArea"
                        inputPlaceholder={'Descrição...'}
                    />

                    <div className="opcenquete align">
                        <ToggleSwitch checked={formValues.termoAceito}
                            setChecked={handleCheckedTermo}
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos de privacidade do Televisando
                            ​</span>
                        </button>
                    </div>

                    {
                        mostrarTermoModal &&    
                        <Modal
                            setTermoAceito={handleCheckedTermo}
                            setShowModal={setMostrarTermoModal}
                        >
                            <TermoProfessor />
                        </Modal>
                    }                   
                </div>
            )}
            imagePath="/imgs/televisando.png"
            programName="Televisando"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="televisando"
            mediaID={31}
        />
    )
}

export default TelevisandoProfessor

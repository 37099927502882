import React from 'react';

const TermoVivaPedreira = () => (
    <>
      <p>Promoção válida de 18/12 a 31/12/2021 – Acesso à Pedreira Paulo Leminski</p>

<p>Ao me cadastrar nesta promoção estou ciente que o limite diário de vouchers é limitado, devido ao espaço do local e normas de segurança. Se estiver esgotado o número de voucher do dia você receberá o recado <strong>“Volte amanhã que o de hoje esgotou”</strong>. </p>

<p>O voucher é pessoal e exclusivo para o usuário cadastrado no APP Você da RPC e dá acesso única e exclusivamente à entrada na Pedreira Paulo Leminski, não incluindo utilização de brinquedos, restaurantes ou demais atrações do local. </p>

<p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoVivaPedreira

import React, { useContext, useCallback, useState, useEffect } from 'react'

import Loading from '../../components/Loading'

import { CONTEUDO_API_URL } from '../../constants/api'
import { FirebaseContext, CurrentUserContext, UploadContext } from '../../Contexts'
import { useCallbackStatic } from '../../hooks/utils'
import { useRequest } from '../../hooks/request'
import moment from 'moment'

import getClientIPv4 from '../../utils/getPublicIPv4'
import getCity from '../../utils/getCity'
import getPraca from '../../utils/getPraca'
import getDeviceInfos from '../../utils/getDeviceInfos'

const  Base = ({ title, description, mediaID, imagePath, programName, selectedMedia, setSelectedMedia, tituloFoto, setTituloFoto, descricaoFoto, setDescricaoFoto, tipoTermo, customFirebaseCollection = null, customFirebaseControlKey = null, salvarOk = false }) => {
    const { S3StartUpload } = useContext(UploadContext)

    const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
    const { currentUser } = useContext(CurrentUserContext)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(false)
    const [userIdToken, setUserIdToken] = useState('')

    useEffect(() => {
        const getFirebaseToken = async () => {
            if(firebaseAuth.currentUser) {
                const token = await firebaseAuth.currentUser.getIdToken()
                setUserIdToken(_ => token)
            }
        }
        getFirebaseToken()
    }, [firebaseAuth.currentUser])

    console.log(currentUser)

    const back = useCallbackStatic(() => {
        setResult(false)
    })

    const [, , fetchSave] = useRequest(
        null,
        {
          url: `${CONTEUDO_API_URL}/midias`,
          method: 'post',
          headers: { Authorization: `Bearer ${userIdToken}` },
          data: {
            id_categoria_envio: mediaID,
            titulo: tituloFoto,
            descricao: descricaoFoto,
            midias: selectedMedia.map(item => ({ path: item.path, type: item.type }))
          }
        },
        null,
        {
          onComplete: data => onComplete(data)
        }
    )    

    const cleanDataState = () => {
        setSelectedMedia([])
        setTituloFoto("")
        setDescricaoFoto("")
        setResult(true)
    }

    const FirestoreStartUpload = async () => {
        const firebaseMensagem = {
            cidade: getCity(currentUser),
            created_at: moment().toDate(),
            id: `${currentUser.uid}-${moment().unix()}`,
            praca: await getPraca(currentUser),
            usr_email: currentUser.email,
            usr_id: currentUser.uid,
            usr_name: currentUser.nome,
            termo: tipoTermo,
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }
        
        const termosCollection = firebaseFirestore.collection('flerken-interatividade').doc('TERMOS_ACEITE').collection('termos')
        try {
            await termosCollection.doc(firebaseMensagem.id).set(firebaseMensagem)
            console.log('Dados do termo enviados com sucesso para o firestore')
        } catch (err) {
            console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
        }
    }

    const send = useCallbackStatic( async () => {
        setLoading(true)
        getDeviceInfos()
        fetchSave()
        cleanDataState()
    })
    
    const onComplete = async (data) => {
        setLoading(false)
        if (data.status === 200) {
            const parts = data.token.split('.')
    
            if(parts.length < 3) return

            const encodedPayload = parts[1]
            const payload = JSON.parse(atob(encodedPayload))
    
            const files = []
            payload.ids_arquivos.forEach((item, index) => {
                const filename = selectedMedia[index].name
                if (filename.length < 2) {
                    console.log('Skipping non-image')
                    return
                }
    
                const typeMatch = filename.match(/\.([^.]*)$/)
                if (!typeMatch) {
                    console.log('Skipping non-image2')
                    return
                }
        
                const imageType = typeMatch[1].toLowerCase()
                if (imageType !== 'jpg' && imageType !== 'png' && imageType !== 'mp4') {
                    console.log('Skipping non-image3')
                    return
                }
        
                const s3Filename = btoa(`{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`)
                const name = `${s3Filename}.${imageType}`
                const file = selectedMedia[index]
                files.push({ name, file })
            })
            S3StartUpload(files)
            FirestoreStartUpload()
        }
    }

    const renderContent = useCallback(() => {
        if (!result) {
            return (
                <div className="container">
                    <div className="titenquete">{title}</div>
                    <div className="descenquete">{description()}</div>
                    <div className="btsalvar">
                        <button onClick={() => send()} className="btsalvar"  disabled={salvarOk}>
                            Enviar
                        </button>
                    </div>
                </div>
            )
        }
        return (
            <div className="container">
                <div className="titenquete">Foto enviada com sucesso!</div>
                <br/>
                <br/>
                <div className="btsalvar"><button onClick={back}>Enviar nova foto</button></div>
            </div>
        )
    }, [result, back, title, description, salvarOk, send])

    return (
        <React.Fragment>
            <div className="enqueterpc">
                <div className="imagemdestaque">
                    <img src={imagePath} alt="banner"/>
                </div>
                {renderContent()}
            </div>
            {loading ? <Loading /> : null}
        </React.Fragment>
    )
}

export default Base

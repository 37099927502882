import React, { useCallback, useState } from "react";

import InputWithLabel from "../../components/InputWithLabel";

import Base from "./Base";

// const characterLimitArea = 240;
const characterLimit = 50;

const Indicar = () => {
  const [nome, setNome] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [telefone, setTelefone] = useState("");

  const clearForm = useCallback(() => {
    setNome("");
    setCidade("");
    setBairro("");
    setTelefone("");
  }, [setNome, setCidade, setBairro, setTelefone]);

  const onChangeEventHandler = useCallback(
    (setter, maxLength) => (event) => {
      const value = event.target.value;
      const valueLength = value.length;

      if (valueLength <= maxLength) {
        setter(value);
      }
    },
    []
  );

  return (
    <Base
      title="Indique um estabelecimento!"
      description={() => (
        <div className="container">
          <InputWithLabel
            title={"Nome do estabelecimento"}
            inputValue={nome}
            onChange={onChangeEventHandler(setNome, characterLimit)}
          />

          <InputWithLabel
            title={"Cidade"}
            inputValue={cidade}
            onChange={onChangeEventHandler(setCidade, characterLimit)}
          />

          <InputWithLabel
            title={"Bairro"}
            inputValue={bairro}
            onChange={onChangeEventHandler(setBairro, characterLimit)}
          />

          <InputWithLabel
            title={"Se souber, indique o telefone"}
            inputValue={telefone}
            onChange={onChangeEventHandler(setTelefone, characterLimit)}
          />
        </div>
      )}
      clearForm={clearForm}
      nome={nome}
      cidade={cidade}
      bairro={bairro}
      telefone={telefone}
      imagePath="/imgs/recicla.png"
      programName="RPC Recicla"
      mediaID={30}
    />
  );
};

export default Indicar;

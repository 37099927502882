export const checkUnfilledFields = fields => {
    return Object
        .entries(fields)
        .filter(field => field[1].value.length === 0 || field[1].value.length < field[1].minLength)
        .map(field => field[0]);
};

export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .replace(/[^a-z0-9 ]/gi, '')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { Result } from 'antd'

import InscricaoPessoal from './formulario/InscricaoPessoal'

import { FirebaseContext } from '../../../Contexts'
import { CurrentUserContext } from '../../../Contexts'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getDeviceInfos from '../../../utils/getDeviceInfos'
import getPraca from '../../../utils/getPraca'

import sendUserConsent from '../../../utils/sendUserConsent'
import { useRequest } from '../../../hooks/request'

import originConsents from '../../../constants/originConsents'
import { Controls, Player } from '@lottiefiles/react-lottie-player'

import { ReactComponent as FacebookSVG } from '../../../assets/images/facebook.svg'
import { ReactComponent as InstagramSVG } from '../../../assets/images/instagram.svg'
import { ReactComponent as LinkedinSVG } from '../../../assets/images/linkedin.svg'

const termoGloboEsporte2024 = 'termoGloboEsporte2024'

const HeaderComponent = ({ children }) => (
  <div className="enqueterpc globo-esporte-2024">
    <div className="imagemdestaque">
      <img src="/imgs/globo-esporte-2024.png" alt="banner" />
    </div>
    {children}
  </div>
)

const GloboEsporte2024 = () => {
  const { firebaseFirestore, firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  console.log({ currentUser })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [finish, setFinish] = useState(false)
  const [userIdToken, setUserIdToken] = useState(null)

  const [formValues, setFormValues] = useState({
    nomeCompleto: '',
    telefone: '',
    cidadeEndereco: {
      idIbge: '',
      nome: '',
      outroEstado: false
    },
    time: '',
    participar: '',
    termoAceito: false,
    timestamp: moment().unix()
  })

  useEffect(() => {
    const getUserToken = async () => {
      const token = await firebaseAuth.currentUser.getIdToken()
      setUserIdToken(token)
    }

    if (currentUser) {
      getUserToken()

      const { nome, idIbge, outroEstado } = currentUser.cidadeEndereco

      if ((nome && nome.length > 0) || (idIbge && idIbge.length > 0)) {
        setFormValues((prev) => ({
          ...prev,
          nomeCompleto: currentUser.nome || '',
          telefone: currentUser.telefone || '',
          cidadeEndereco: {
            nome: nome,
            idIbge: idIbge,
            outroEstado: outroEstado || false
          }
        }))
      } else {
        setFormValues((prev) => ({
          ...prev,
          nomeCompleto: currentUser.nome || '',
          telefone: currentUser.telefone || '',
          cidadeEndereco: {
            nome: '',
            idIbge: '',
            outroEstado: false
          }
        }))
      }
    }
  }, [currentUser])

  const sendData = async () => {
    setLoading(true)
    if (
      currentUser.nome !== formValues.nomeCompleto ||
      currentUser.telefone !== formValues.telefone ||
      currentUser.cidadeEndereco.nome !== formValues.cidadeEndereco.nome
    ) {
      try {
        const user = await firebaseFirestore
          .collection('flerken-users')
          .doc(currentUser.uid)

        await user.update({
          nome: formValues.nomeCompleto,
          telefone: formValues.telefone,
          cidadeEndereco: {
            idIbge: formValues.cidadeEndereco.idIbge,
            nome: formValues.cidadeEndereco.nome,
            outroEstado: false
          }
        })
      } catch (e) {
        console.error('Erro ao atualizar dados do usuário:', e)
        setLoading(false)
        setError('Erro ao atualizar dados do usuário')
      }
    }

    const firebaseMensagem = {
      ...formValues,
      created_at: moment().toDate(),
      id: `${currentUser.uid}-${formValues.timestamp}`,
      termo: termoGloboEsporte2024
    }
    console.log('Dados Firebase: ', firebaseMensagem)

    const mainCollection = firebaseFirestore
      .collection('especiais')
      .doc('globo-esporte-2024')
    const listCollection = mainCollection.collection('2024')
    await listCollection.add(firebaseMensagem)

    const firebaseTermo = {
      ...formValues,
      created_at: moment().toDate(),
      id: `${currentUser.uid}-${formValues.timestamp}`,
      praca: await getPraca(currentUser),
      usr_email: currentUser.email,
      usr_id: currentUser.uid,
      usr_name: currentUser.nome,
      termo: termoGloboEsporte2024,
      ipV4: await getClientIPv4(),
      device_infos: getDeviceInfos()
    }

    console.log('firebaseTermo: ', firebaseTermo)

    const concursoGloboEsporte2024 = firebaseFirestore
      .collection('flerken-interatividade')
      .doc('TERMOS_ACEITE')
      .collection('termos')

    try {
      await concursoGloboEsporte2024.doc(firebaseTermo.id).set(firebaseTermo)
      console.log('Dados do termo enviados com sucesso para o firestore')

      await sendUserConsent({
        name: formValues.nomeCompleto,
        email: currentUser.email,
        origin: originConsents[termoGloboEsporte2024]
      })

      setFinish(true)
    } catch (err) {
      console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
      setError('Erro ao enviar os dados para o firestore')
    }
    setLoading(false)
  }

  if (!currentUser) {
    return (
      <HeaderComponent>
        <div className="container-avaliacao-salesforce">
          <div className="title">
            <h1>Usuário não autenticado</h1>
          </div>
        </div>
      </HeaderComponent>
    )
  }

  if (error.length > 0) {
    return (
      <HeaderComponent>
        <div className="container-avaliacao-salesforce container-globo-esporte-2024">
          <div className="logo">
            <Player
              autoplay
              keepLastFrame
              src="https://lottie.host/ee06a1b9-4a4a-4cbe-a006-7cb657c7e574/dRkEbGjWl5.json"
              style={{ height: '250px', width: '250px' }}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
          </div>
          <div className="title">
            <h1>500</h1>
          </div>
          <div className="subtitle">
            <h6>{error}</h6>
          </div>
          <div className="btsalvar">
            <button
              onClick={() => setError('')}
              className="btsalvar button-globo-esporte-2024"
            >
              Tentar novamente
            </button>
          </div>
        </div>
      </HeaderComponent>
    )
  }

  if (loading) {
    return (
      <HeaderComponent>
        <div className="container-avaliacao-salesforce">
          <div className="title">
            <h1>Enviando inscrição</h1>
          </div>
          <div className="subtitle">
            <h6>Por favor, não feche esta tela até o processo finalizar.</h6>
          </div>
        </div>
        <img src="/imgs/giphy.gif" className="img-loading" />
      </HeaderComponent>
    )
  }

  if (finish) {
    return (
      <HeaderComponent>
        <div className="container-avaliacao-salesforce">
          <div className="title">
            <h1>Agradecemos pela sua participação</h1>
          </div>
          <div className="subtitle">
            <h6>Sua resposta foi registrada</h6>
          </div>
          <div className="logo">
            <Player
              autoplay
              keepLastFrame
              src="https://lottie.host/9b0cc4f5-937f-4dcc-bd4c-0450d0fa6792/l1erLfIKCb.json"
              style={{ height: '200px', width: '200px' }}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
          </div>
          <div className="subtitle">
            <h6>Fique por dentro das novidades da RPC:</h6>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/rpcparana/?locale=pt_BR"
              target="_blank"
            >
              <FacebookSVG />
            </a>
            <a href="https://www.instagram.com/rpcparana/" target="_blank">
              <InstagramSVG />
            </a>
            <a
              href="https://br.linkedin.com/company/rede-paranaense-de-comunica-o"
              target="_blank"
            >
              <LinkedinSVG />
            </a>
          </div>
        </div>
      </HeaderComponent>
    )
  }

  return (
    <React.Fragment>
      <HeaderComponent>
        <InscricaoPessoal
          form={[formValues, setFormValues]}
          setLoading={setLoading}
          sendData={sendData}
        />
      </HeaderComponent>
    </React.Fragment>
  )
}

export default GloboEsporte2024

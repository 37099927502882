import dayjs from 'dayjs';

import { visitanteRPCCollection, termoVisitanteRPC, blockedBeforeDays, maxVisitantes } from '../../constants'

const validatedDate = async (firebaseFirestore, setBlockedDate) => {
  const visitanteRPC = await firebaseFirestore
          .collection(visitanteRPCCollection)
          .get()
  const date =[]

  visitanteRPC.docs.map((doc) => date.push(doc.data().formularioDataVisita))

  date.map(async (data) => {
    const {docs} = await firebaseFirestore
        .collection(visitanteRPCCollection)
        .where("formularioDataVisita", "==", data)
        .where("linguagem_ofensiva", "==", null)
        .get()

    // Verifica se há 15 pessoas e ou se faltam 3 dias para a visita, se sim em algum dos casos
    // Bloqueia a data
    if(docs.length === maxVisitantes || dayjs(docs[0]?.data().formularioDataVisita.seconds*1000).diff(dayjs(), 'days') < blockedBeforeDays){
      setBlockedDate(rest => [...rest, dayjs(docs[0].data().formularioDataVisita.seconds*1000).format("YYYY-MM-DD")])
    }
  })
}

export default validatedDate;

import React from 'react'
import { Slider as AntdSlider } from 'antd'
import styled, { css } from 'styled-components'

const marginTop = css`
  margin-top: 5px;
`
export const Wrapper = styled.div`
  display: flex;
`

export const Left = styled.div`
  ${({ prefixColor, max, value = 0, range }) =>
    prefixColor &&
    css`
      color: ${prefixColor};
      filter: grayscale(${!range && (value / max) * 100}%);
    `};
  float: left;
  ${marginTop}
`

export const Center = styled.div`
  text-align: center;
  width: 100%;
  padding: 0 10px;
`

export const Right = styled.div`
  ${({ suffixColor, max, value = 0, range }) =>
    suffixColor &&
    css`
      color: ${suffixColor};
      filter: grayscale(${!range && 100 - (value / max) * 100}%);
    `};
  float: right;
  ${marginTop}
`

export const Slider = styled(({ ...rest }) => <AntdSlider {...rest} />)`
  ${({ range }) =>
    range &&
    css`
      .ant-slider-mark-text {
        cursor: default;
      }
    `};
`

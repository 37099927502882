import React from 'react';

const TermoVisitanteRPC = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – PROGRAMA DE VISITAS RPC</strong></p>
        <p><strong>1.</strong> - Pelo presente instrumento, o(a) cedente, neste ato devidamente representado por seu responsável legal (acima qualificado e abaixo firmado), cede a <strong>SOCIEDADE RÁDIO EMISSORA PARANAENSE S.A.</strong>, pessoa jurídica de direito privado, inscrita no <strong>CNPJ/MF sob o n. 76.494.806/0001-45</strong>, doravante denominado RPC, de forma inteiramente gratuita, a título universal e em caráter definitivo, irrevogável e irretratável,
        seus direitos de imagem para fins de participação no <strong>Programa de Visitas</strong> às emissoras integrantes da <strong>REDE PARANAENSE DE COMUNICAÇÃO - RPC.</strong></p>
        <p><strong>2.</strong> - O(a) cedente/autorizador(a) expressamente autoriza que sua imagem e voz seja captada em vídeo, foto e áudio durante a realização da visita, manifestando ciência e concordância com a utilização das gravações/fotos durante a programação da RPC, bem como em qualquer meio de comunicação, inclusive na internet,
         tais como em sites, lives, webinários, redes sociais, postagens e fanpages das emissoras RPC e em materiais de divulgação deste e dos próximos <strong>Programas de Visitas.</strong></p>
        <p><strong>3.</strong> - O(a) cedente/autorizador(a) está ciente e concorda que todos os dados pessoais informados serão armazenados em um banco de dados protegido da RPC, de acordo com a <a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">Política de Privacidade da RPC</a>, bem está de acordo com os termos da referida Política de Privacidade
         e aceita receber informações sobre o projeto ou outras ações promovidas e apoiadas pela RPC a partir da presente data em seu e-mail e por telefone</p>
        <p><strong>4.</strong> - A <strong>RPC</strong> se compromete a utilizar as imagens captadas somente com a finalidade de divulgar seu Programa de Visitas.</p>
        <p><strong>5.</strong> - Em caso de divergências quanto a este instrumento e, havendo a necessidade de intervenção do Poder Judiciário, as partes concordam as partes concordam e elegem o foro da Comarca de Curitiba</p>

        <p>Ao enviar meus dados e clicar no aceite abaixo, estou de acordo com os termos/condições acima, bem como com os Termos de Uso do aplicativo "VOCÊ NA RPC" (<a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank">toque para acessar o termo</a>) e das Políticas de Privacidade e Proteção de Dados da GLOBO (<a href="https://privacidade.globo.com/privacy-policy" target="_blank">toque para acessar o termo</a>) e da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">toque para acessar o termo</a>).</p>
    </>
)

export default TermoVisitanteRPC;

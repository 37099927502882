import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from 'react'
import { DatePicker, Divider, Radio } from 'antd'
import moment from 'moment'

import InputWithLabel from '../../../components/InputWithLabel'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'

import TermoEncontroAnimalFoz2021 from '../../../termosAceite/termoEncontroAnimalFoz2021'

import Base from './Base'

import { checkUnfilledFields } from '../../../utils/helpers'

import parsers from '../../../utils/parsers/index'
import { FirebaseContext } from '../../../Contexts'

const characterLimit = 255

const ConcursoCaminhosDoCampo = () => {
  const { firebaseFirestore: db } = useContext(FirebaseContext)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [availableHours, setAvailableHours] = useState([])
  const [availableHoursRef, setAvailableHoursRef] = useState()

  const [formValues, setFormValues] = useState({
    // Dados cadastrais
    nomeCompleto: '',
    nomePet: '',
    quantidadeAcompanhantes: 0,
    horarioConsulta: '',
    dataConsulta: '09/10/2021',
    termoAceito: false
  })

  const onChangeInputEventHandler = useCallback(
    (attribute, maxLength) => (event) => {
      const { value } = event.target
      const valueLength = value.length
      const parseType = event.target.dataset.parser
      const parsedValue = parseType ? parsers[parseType](value) : value
      if (valueLength <= maxLength) {
        setFormValues((prev) => ({ ...prev, [`${attribute}`]: parsedValue }))
      }
    },
    []
  )

  const handleCheckedTermo = useCallback(() => {
    setFormValues((prev) => ({ ...prev, termoAceito: !prev.termoAceito }))
  }, [])

  const salvarOk = useMemo(() => {
    const {
      nomeCompleto,
      nomePet,
      quantidadeAcompanhantes,
      horarioConsulta,
      termoAceito
    } = formValues

    const requiredFields = checkUnfilledFields({
      nomeCompleto: {
        value: nomeCompleto,
        minLength: 0
      },
      nomePet: {
        value: nomePet,
        minLength: 0
      }
    })

    return !(
      termoAceito &&
      quantidadeAcompanhantes >= 0 &&
      quantidadeAcompanhantes <= 3 &&
      horarioConsulta.length === 5 &&
      requiredFields.length === 0
    )
  }, [formValues])
  useEffect(() => {
    const getAvailableHours = async () => {
      const collectionRef = await db
        .collection('sistema-agendamentos')
        .doc('encontro-animal-foz-2021')
        .collection('09-out-2021')

      const values = []
      ;(await collectionRef.get()).forEach((doc) => {
        values.push(doc.data())
      })
      setAvailableHoursRef(collectionRef)
      setAvailableHours(values)
    }
    getAvailableHours()
  }, [])

  return (
    <Base
      description={() => (
        <div className="container">
          <Divider>Dados Cadastrais</Divider>

          <InputWithLabel
            title={'Qual é o seu nome?'}
            subTitle="Campo obrigatório"
            inputValue={formValues.nomeCompleto}
            onChange={onChangeInputEventHandler('nomeCompleto', characterLimit)}
          />
          <InputWithLabel
            title={'Qual é o nome do seu pet?'}
            subTitle="Campo obrigatório"
            inputValue={formValues.nomePet}
            onChange={onChangeInputEventHandler('nomePet', characterLimit)}
          />

          <div>
            <p className="title">
              Selecione o número de acompanhantes* do pet durante o atendimento:
              <span className="subTitle">(Campo obrigatório)</span>
            </p>
            <Radio.Group
              onChange={(e) => {
                setFormValues((prev) => ({
                  ...prev,
                  quantidadeAcompanhantes: e.target.value
                }))
              }}
              defaultValue={0}
            >
              <Radio.Button value={0}>0</Radio.Button>
              <Radio.Button value={1}>1</Radio.Button>
              <Radio.Button value={2}>2</Radio.Button>
              <Radio.Button value={3}>3</Radio.Button>
              ))
            </Radio.Group>
            <p className="subTitle">
              * Acompanhantes: preferencialmente o menor número de pessoas por
              conta do cenário atual de pandemia
            </p>
          </div>

          <Divider>Selecione horário da consulta do pet</Divider>

          <div style={{ marginTop: '10px' }}>
            <p className="title">Data do evento:</p>
            <DatePicker
              defaultValue={moment('2021/10/09')}
              format={'DD/MM/YYYY'}
              disabled={true}
            />
          </div>

          <div style={{ marginTop: '10px' }}>
            <p className="title">
              Horário do agendamento:
              <span className="subTitle">(Campo obrigatório)</span>
            </p>
          </div>

          <Radio.Group
            onChange={(e) => {
              setFormValues((prev) => ({
                ...prev,
                horarioConsulta: e.target.value
              }))
            }}
          >
            {availableHours.map((availableHour) => (
              <Radio.Button
                key={availableHour.hora}
                value={availableHour.hora}
                disabled={availableHour.disponivel === 0}
              >
                {availableHour.hora}
              </Radio.Button>
            ))}
          </Radio.Group>

          <Divider />
          <div className="opcenquete align-left">
            <ToggleSwitch
              checked={formValues.termoAceito}
              setChecked={handleCheckedTermo}
              name="termoAceito"
            />
            <button
              type="button"
              className="btTransparente"
              onClick={() => {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0 // FF, GC, Opera
                document.body.style.overflowY = 'hidden'
                setMostrarTermoModal(() => true)
              }}
            >
              <span>
                Declaro que li e aceito os termos de privacidade do
                <strong>ENCONTRO ANIMAL - Foz do Iguaçu 2021</strong>.
              </span>
            </button>
          </div>
          <div style={{ marginTop: '14px' }}>
            {!formValues.termoAceito && (
              <span>
                <strong>Atenção</strong>: Neste caso (
                <strong>NÃO ACEITE</strong>) não será possível sua participação
                no <strong>Encontro Animal - Foz do Iguaçu 2021</strong>. Por
                favor, em caso de dúvidas, envie uma mensagem para o aplicativo
                “Você na RPC”.
              </span>
            )}
          </div>

          {mostrarTermoModal && (
            <Modal
              setTermoAceito={handleCheckedTermo}
              setShowModal={setMostrarTermoModal}
            >
              <TermoEncontroAnimalFoz2021 />
            </Modal>
          )}
        </div>
      )}
      title="Agende o atendimento para o seu pet no dia 16 de outubro:"
      imagePath="/imgs/encontroAnimalFoz2021.png"
      programName="Encontro Animal - Foz do Iguaçu 2021"
      form={formValues}
      setForm={setFormValues}
      salvarOk={salvarOk}
      tipoTermo="encontroAnimalFoz2021"
      availableHoursRef={availableHoursRef}
      db={db}
    />
  )
}

export default ConcursoCaminhosDoCampo

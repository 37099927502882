import axios from 'axios'

const sendUserConsent = async (data) => {
  const requestUrl = 'https://privacytools.lgpd.rpc.com.br/sendUserConsent';

  console.log(`Enviando 'POST' para '${requestUrl}'`);

  try {
    await axios({
      url: requestUrl,
      method: 'POST',
      data,
    });

    return true;
  } catch (e) {
    console.error('Erro ao enviar requisição para a api privacy tools:', e);
  }

  return false;
};

export default sendUserConsent

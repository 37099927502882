import React from 'react';

const TermoExpoFlor2022 = () => (
    <>
      <p>Promoção válida de 05/08 a 12/08/2022 – Voucher com 50% de desconto para inscrição 5ª Corrida Rústica Expo&Flor.</p>

<p>Ao me cadastrar nesta promoção estou ciente que o voucher é pessoal e exclusivo para o usuário cadastrado no APP Você da RPC, com direito único e exclusivamente à desconto para inscrição da 5ª Corrida Rústica Expo&Flor via site https://www.cavaliproeventos.com.br/. </p>

<p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoExpoFlor2022

/* eslint-disable import/prefer-default-export */

import moment from 'moment'

import getDeviceInfos from '../../../utils/getDeviceInfos'
import getClientIPv4 from '../../../utils/getPublicIPv4'

export const uploadFirestore = async ({
  currentUser,
  tipoTermo,
  form,
  setLoading,
  setResult,
  setHasError,
  hasError,
  db,
  availableHoursRef
}) => {
  const firebaseMensagem = {
    ...form,
    created_at: moment().toDate(),
    id: `${currentUser.uid}-${moment().unix()}`,
    usr_email: currentUser.email,
    usr_id: currentUser.uid,
    usr_name: currentUser.nome,
    termo: tipoTermo,
    ipV4: await getClientIPv4(),
    device_infos: getDeviceInfos()
  }

  try {
    await db.runTransaction(async (transaction) => {
      const hourRef = availableHoursRef.doc(form.horarioConsulta)
      const doc = await transaction.get(hourRef)

      // Add one person to the city population.
      // Note: this could be done without a transaction
      //       by updating the population using FieldValue.increment()
      const newAvailablity = doc.data().disponivel - 1
      console.log(newAvailablity)
      if (newAvailablity < 0) {
        setHasError('Horário selecionado está esgotado.')
      } else {
        transaction.update(hourRef, { disponivel: newAvailablity })
        setResult(true)
      }
    })

    console.log('Transaction success!')
  } catch (e) {
    console.log('Transaction failure:', e)
  }

  if (!hasError) {
    try {
      const collection = await db
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')
      collection.doc(firebaseMensagem.id).set(firebaseMensagem)

      console.log('Dados do formulario enviados com sucesso')
      setLoading(false)
      setResult(true)
    } catch (err) {
      console.log('Ocorreu um erro ao enviar os dados do formulario', err)
      setHasError('Falha ao enviar os dados do formulário.')
    }
  }
}

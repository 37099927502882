import React from 'react'

const TermoPesquisaDeSinal = () => (
    <>
        <p><strong>TERMO DE AUTORIZACÃO DE USO DE BAIRRO</strong></p>
        <p><strong>1.</strong> - Pelo presente instrumento, o(a) <strong>Autorizador(a)</strong> abaixo qualificado <strong>e</strong> assinado, autoriza à <strong>SOCIEDADE RÁDIO EMISSORA PARANAENSE S.A. (RPC)</strong> - Controlador, CNPJ/MF 76.494.806/0001-45, de forma inteiramente gratuita, a título universal, em caráter total, a utilização do BAIRRO, pela <strong>RPC</strong>, para pesquisa visando o aprimoramento do sinal da RPC. </p>

        <p><strong>2.</strong> - Quanto ao bairro informado pelo <strong>Autorizador</strong>, fica certo e acordado, que o referido dado será mantido/arquivado em banco de dados específico e devidamente protegido na <strong>RPC</strong> e vinculado aos demais dados de cadastro.</p>

        <p><strong>3.</strong> - O <strong>Autorizador</strong> fica ciente de que poderá revogar, a qualquer tempo, esta autorização perante a <strong>RPC</strong> mediante petição de titulares disponível no próprio APP “Você na RPC”.</p>

        <p>Estou ciente e de acordo com os termos acima.</p>
    </>
)

export default TermoPesquisaDeSinal;
import React, { useMemo, useState } from 'react'
import * as S from './styles'

const Component = (props) => {
  const {
    label,
    onChange,
    fieldHelp,
    defaultValue = undefined,
    onAfterChange,
    prefix = undefined,
    prefixColor,
    suffixColor,
    suffix = undefined,
    included = true,
    min = 0,
    max = 10,
    required,
    marks: { ...marks } = { [min]: min, [max]: max },
    ...rest
  } = props

  const [inputValue, setInputValue] = useState(defaultValue || {})


  return useMemo(() => {
    return (
        <S.Wrapper>
          <S.Left
            prefixColor={prefixColor}
            max={max}
            value={inputValue}
            range={rest.range}
          >
            {prefix}
          </S.Left>
          <S.Center>
            <S.Slider
              value={inputValue}
              marks={marks}
              min={min}
              max={max}
              included={included}
              onChange={(event) => {
                setInputValue(event)
                if (onChange) {
                  onChange(event)
                }
              }}
              onAfterChange={(event) => {
                if (onAfterChange) {
                  onAfterChange(event)
                }
              }}
              {...rest}
            />
          </S.Center>
          <S.Right
            suffixColor={suffixColor}
            max={max}
            value={inputValue}
            range={rest.range}
          >
            {suffix}
          </S.Right>
        </S.Wrapper>
    )
  }, [prefixColor, max, inputValue, rest, prefix, marks, min, included, suffixColor, suffix, onChange, onAfterChange])
}

const Slider = (props) => <Component {...props} />


export default Slider

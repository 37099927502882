import React from 'react'

const FormularioInscricao = () => (
    <>
        <ol>
            <li>Quanto aos seus <strong>DADOS PESSOAIS</strong>, esclarecemos:</li>
                <ol type='a'>
                    <li>Você deverá ter o máximo de atenção no momento da sua inscrição, ficando certo, desde já, que a RPC não se responsabiliza por erros cadastrais dos inscritos, tais como: cadastro duplo, equívocos de dados, e outros.</li>

                    <li>Todos os dados dos inscritos serão armazenados num banco de dados único do BEM VERÃO 2021, de acordo com o seu Regulamento e com as políticas de privacidade da Políticas de Privacidade e Proteção de Dados da GLOBO (<a href='https://privacidade.globo.com/privacy-policy' target="_blank" rel="noopener noreferrer">https://privacidade.globo.com/privacy-policy</a>) e da RPC (<a href='https://www.negociosrpc.com.br/politica-de-privacidade-rpc/' target='_blank' rel="noopener noreferrer">https://www.negociosrpc.com.br/politica-de-privacidade-rpc/</a>).</li>

                    <li>Ao realizar sua inscrição você autoriza expressamente a RPC a fazer o tratamento de seus dados pessoais, pelo prazo de 05 (cinco) anos, para que estes sejam usados pela RPC ou terceiros ligados a fiscalização e/ou ao desenvolvimento do quadro, ou seja: Plataforma Aplicativo Você na RPC, para fins de desenvolvimento e implementação do BEM VERÃO, bem como para fins de relacionamento com você, identificação, localização, preservação dos seus direitos e obrigações e da RPC, sem o direito de obter o reconhecimento, compensação ou remuneração de qualquer espécie para tal uso.</li>

                    <li>Caso queira, e mediante requisição, você poderá solicitar à RPC a retificação, correção e atualização de seus dados pessoais, durante a vigência do BEM VERÃO 2021, para tanto deverá entrar em contato por meio do Aplicativo Você na RPC.</li>

                    <li>Também, mediante requisição, você poderá solicitar o cancelamento de sua inscrição no BEM VERÃO 2021 (por meio do Aplicativo Você na RPC), todavia, ficará resguardado o direito de arquivamento de seus dados pela RPC, pelo prazo de 05 (cinco) anos, conforme acima estabelecido.</li>

                    <li>A RPC se reserva ao direito de cancelar e/ou bloquear o acesso e cadastro de qualquer inscrito no <strong>BEM VERÃO 2021</strong>, a qualquer momento e sem prévio aviso, caso seja constatado que este pratica ou venha a praticar algum ato ou conduta que (i) viole o <strong>REGULAMENTO do BEM VERÃO 2021</strong>; (ii) viole as leis e regulamentos federais, estaduais e/ou municipais (iii) viole os princípios da moral e dos bons costumes.</li>
                </ol>
            <li>Quanto aos <strong>DIREITOS IMAGEM E AUTORAIS:</strong></li>
                <ol type='a'>
                    <li>Ao realizar sua inscrição você cede e autoriza à RPC, a título gratuito, de forma global, integral, permanente, irretratável, irrevogável e exclusiva, todos os direitos de utilização de imagem, nome e voz para divulgação do <strong>BEM VERÃO 2021</strong>, por meio de todos os meios de comunicação atualmente existentes.</li>

                    <li>Você concede à RPC, bem como à terceiros por ela indicados, com exclusividade, automaticamente e gratuitamente, o direito e licença em caráter exclusivo, definitivo, universal, irrevogável e irretratável, para fins de utilização das imagens e/ou sons contidos nos vídeos e materiais enviados, além de utilizar seu conteúdo para promover ações de merchandising ou veicular propaganda, bem como desenvolver qualquer atividade de licenciamento de produtos e/ou serviços derivados das obras audiovisuais produzidas pela RPC, disseminá-las através da Internet e/ou telefonia, fixa e/ou móvel, ceder os direitos autorais sobre as obras audiovisuais a terceiros, para qualquer espécie de utilização, ou ainda dar-lhes qualquer outra utilização que proporcione à RPC alguma espécie de vantagem econômica. Nenhuma dessas utilizações previstas anteriormente tem limitação de tempo ou de número de vezes (ainda que ultrapasse o período do programa), podendo ocorrer no Brasil e/ou no exterior, sem que seja devido ao candidato qualquer remuneração ou compensação.</li>

                    <li>Você cede e transfere à RPC, em caráter definitivo, irrevogável, irretratável e sem qualquer ônus, todo e qualquer direito patrimonial de autor relativo (ressalvados seus direitos autorais), quanto aos materiais fotográficos, vídeos e demais materiais eventualmente enviados por você no presente Quadro, bem como, declara-se ciente de que todo e qualquer material por ele enviado à RPC quando de sua inscrição, poderá ser utilizado em associação com outros textos, títulos, documentos, gráficos e demais materiais de propriedade da RPC, sem que para isso lhe seja devida qualquer remuneração.</li>

                    <li>A autorização ora concedida para a RPC, nos termos dos itens acima, é de sua total responsabilidade, e entra em vigor no ato da sua inscrição, e assim perdurará por todo o prazo de proteção da obra audiovisual. Entende-se por prazo de proteção legal da obra aquele estabelecido na Lei 9.610/98.</li>

                    <li>O vídeo, a(s) fotografia(s) e/ou qualquer material encaminhado por você não será devolvido pela RPC, que poderá dar-lhes qualquer destinação, a seu exclusivo critério, podendo, inclusive, destruí-los.</li>

                    <li>Você, desde já, declara, para todos os fins de direito, que somente enviará materiais (vídeos, fotos, etc) que detenha os direitos necessários, inclusive direitos de uso de imagem de terceiros, para licenciá-lo na forma aqui determinada, sem que tal utilização pela RPC e demais usuários importe em violação de direitos de terceiros, sendo que:</li>
                        <ol>
                            <li>Ao enviar a(s) foto(s) e/ou o(s) vídeo(s), você será o único responsável pelo seu conteúdo, perante à RPC e terceiros, inclusive quanto à propriedade intelectual, assumindo, portanto, responsabilidade exclusiva por eventuais danos ou ônus a terceiros, ou qualquer tipo de infração à legislação autoral, excluindo e indenizando a RPC e terceiros, em caso de demanda judicial ou extrajudicial intentada por terceiros, sob qualquer alegação relacionada à violação de direitos de propriedade intelectual, industrial, patentes, imagem, voz e nome e/ou o vídeo enviados, especialmente nos casos de:</li>
                                <ol type='a'>
                                <li>utilização não autorizada de obras e/ou imagens de terceiros;</li>

                                <li>questões relacionadas a direitos morais, autorais e/ou conexos, sendo certo que você, ao enviar a(s) foto(s) e/ou o vídeo, no caso de propositura de medida judicial em face da RPC ou demais usuários, deverá assumir o polo passivo da relação processual arcando com todas e quaisquer custas judiciais, honorários advocatícios e indenizações de qualquer natureza.</li>
                                </ol>
                            <li>Se, em qualquer hipótese, a RPC ou demais usuários vierem a ser compelidos a indenizar terceiros em razão de demanda judicial ou extrajudicial que tiver por objeto a(s) foto(s) e/ou o(s) vídeo(s) ou seu conteúdo, você compromete-se, desde já, a assumir a posição da RPC, e, eventualmente, de seus diretores, empregados e colaboradores, em quaisquer pleitos e demandas de terceiros com relação ou em decorrência da utilização da(s) foto(s) e/ou do(s) vídeo(s) que viole o presente regulamento e/ou qualquer legislação aplicável ou quaisquer direitos de terceiros.</li>
                            <li>Caso você não seja denunciado à lide, ou não seja possível assumir a posição da RPC, seus diretores, empregados ou colaboradores em eventual demanda, você obriga-se a arcar com todos os custos decorrentes de tais demandas, incluindo, mas sem se limitar a: custas processuais, honorários advocatícios e eventuais verbas indenizatórias.</li>
                        </ol>
                </ol>
        </ol>
    </>
)

export default FormularioInscricao;
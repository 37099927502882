const getCity = (currentUser) => {
    if (currentUser.cidadeEndereco) {
        if (currentUser.cidadeEndereco.outroEstado) {
            return 'De outro Estado'
        }
        return currentUser.cidadeEndereco.nome || currentUser.nomeCidade
    }
    return currentUser.nomeCidade || null
}

export default getCity
import React from 'react';

const TermoFestivalMiaCara2022 = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – Festival - Dez anos de Mia Cara</strong></p>
        <p>Ao me cadastrar nesta promoção estou ciente que o voucher é pessoal e exclusivo para o usuário cadastrado no APP.</p>
        <p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoFestivalMiaCara2022;

import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react'
import { Progress } from 'antd'
import moment from 'moment'

import { FirebaseContext, CurrentUserContext, UploadContext } from '../../../Contexts'
import { CONTEUDO_API_URL } from '../../../constants/api'
import { useCallbackStatic } from '../../../hooks/utils'
import { useRequest } from '../../../hooks/request'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getDeviceInfos from '../../../utils/getDeviceInfos'

import CustomHTTPHandler from '../../../utils/CustomHTTPHandler'

const customHTTPHandler = new CustomHTTPHandler()

const  Base = ({ title, description, mediaID, imagePath, programName, form, setForm, tipoTermo, salvarOk }) => {
    const { S3StartUpload } = useContext(UploadContext)
    const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
    const { currentUser } = useContext(CurrentUserContext)
    const [userIdToken, setUserIdToken] = useState('')
    const [result, setResult] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [hasError, setHasError] = useState(undefined)

    customHTTPHandler.onProgress$.subscribe(progress => {
        const percentComplete = progress.progressEvent.loaded / progress.progressEvent.total * 100;
        setUploadProgress(percentComplete)
    });

    useEffect(() => {
        const getFirebaseToken = async () => {
            if(firebaseAuth.currentUser) {
                const token = await firebaseAuth.currentUser.getIdToken()
                setUserIdToken(_ => token)
            } else {
                setHasError('Usuário não autenticado')
            }
        }
        getFirebaseToken()
    }, [firebaseAuth.currentUser])


    const back = useCallbackStatic(() => {
        setResult(false)
    })

    const [, statusAPI, fetchSave] = useRequest(
        null,
        {
          url: `${CONTEUDO_API_URL}/midias`,
          method: 'post',
          headers: { Authorization: `Bearer ${userIdToken}` },
          data: {
            id_categoria_envio: mediaID,
            titulo: form.titulo,
            descricao: form.descricao,
            midias: form.media.map(item => ({ path: item.path, type: item.mediaType }))
          }
        },
        null,
        {
          onComplete: data => onComplete(data)
        }
    )

    const cleanDataState = useCallback(() => {
        const cleanedForm = {}
        Object.keys(form).forEach(key => {
            cleanedForm[`${key}`] = ''
        })
        cleanedForm.media = []
        cleanedForm.categoria = form.categoria
        cleanedForm.termoAceito = false
        cleanedForm.formularioInscricaoAceito = false
        setForm(prev => cleanedForm)
        setResult(prev =>true)
        setLoading(prev =>false)
        setUploadProgress(prev => 0);
        setUploading(prev => false);
        setHasError(prev => undefined);
    }, [form, setForm])

    useEffect(() => {
        if(statusAPI) {
            if (statusAPI.error) {
                cleanDataState()
                setHasError('Erro: envio dos dados.')
            }
        }
    }, [cleanDataState, statusAPI])

    console.log(currentUser)

    const FirestoreStartUpload = useCallback(async () => {
        const firebaseMensagem = {
            ...form,
            created_at: moment().toDate(),
            id: `${currentUser.uid}-${moment().unix()}`,
            usr_email: currentUser.email,
            usr_id: currentUser.uid,
            usr_name: currentUser.nome,
            termo: tipoTermo,
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }
        // REMOVENDO OS DADOS DA IMAGEM LOCAL
        delete firebaseMensagem.media
        delete firebaseMensagem.type

        const collection = firebaseFirestore.collection('flerken-interatividade').doc('TERMOS_ACEITE').collection('termos')
        collection.doc(firebaseMensagem.id).set(firebaseMensagem).then(() => {
            console.log('Dados do formulario enviados com sucesso')
            setLoading(false)
        }).catch((err) => {
            console.log('Ocorreu um erro ao enviar os dados do formulario', err)
            setHasError('Falha ao enviar os dados do formulário.')
        })
    }, [currentUser, firebaseFirestore, form, tipoTermo])

    const send = useCallback( async () => {
        setLoading(true)
        getDeviceInfos()
        fetchSave()
    }, [fetchSave])

    const onComplete = async (data) => {
        if (data.status === 200) {
            const parts = data.token.split('.')

            if(parts.length < 3) return

            const encodedPayload = parts[1]
            const payload = JSON.parse(atob(encodedPayload))

            const files = []
            payload.ids_arquivos.forEach((item, index) => {
                const filename = form.media[index].name
                if (filename.length < 2) {
                    console.error('Nome do arquivo de mídia invalido')
                    setHasError('Erro: nome do arquivo de mídia é invalido');
                    cleanDataState()
                    return
                }

                const typeMatch = filename.match(/\.([^.]*)$/)
                if (!typeMatch) {
                    console.error('typeMatch inválido')
                    setHasError('Erro: nome do arquivo de mídia é inválido');
                    cleanDataState()
                    return
                }

                const mediaFormat = form.media[index].type
                if (!mediaFormat.includes('image/')) {
                    console.error(`Extensão do arquivo inválido ${mediaFormat}`)
                    setHasError(`Erro: tipo de arquivo não suportado (${mediaFormat}). É permitido somente o envio de arquivos .jpg e .png`);
                    cleanDataState()
                    return
                }

                const s3Filename = btoa(`{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`)
                const name = `${s3Filename}.${typeMatch[1].toLowerCase()}`
                const file = form.media[index]
                files.push({ name, file })
            })
            if(!hasError) {
                setUploading(true)
                S3StartUpload(files, setUploading, customHTTPHandler, setHasError)
                FirestoreStartUpload()
                setResult(true);
            }
        } else {
            console.error('Falha no envio para API de conteudo')
            setHasError('Ocorreu uma falha inesperada');
            cleanDataState()
        }
    }

    const onBack = useCallback(() => {
        cleanDataState();
        back();
    }, [back, cleanDataState]);

    const renderError = useCallback(() => (
        <div className="container">
            <div className="titenquete">
                <p>Ocorreu um erro ao cadastrar.</p>
                <p>{hasError}</p>
                <p>Por favor, tente novamente.</p>
            </div>
            <div className="btsalvar">
                <button onClick={onBack}>
                    Tentar novamente
                </button>
            </div>
        </div>
    ), [hasError, onBack])

    const renderContent = useCallback(() => {


        const showSendFormComponent = (
        <>
            <div className="container">
                <div className="titenquete">{title}</div>
                <div className="descenquete">{description()}</div>
                <div className="btsalvar">
                    <button onClick={send} className="btsalvar"  disabled={salvarOk}>
                        Enviar
                    </button>
                </div>
            </div>
        </>)

        const showSuccessComponent = (
        <div className="container">
            <div className="titenquete">{`Foto enviada com sucesso!`}</div>
        </div>)

        return !result ? showSendFormComponent : showSuccessComponent

    }, [title, description, send, salvarOk, result])

    const renderLoading = useCallback(() => {
        if(loading) {
            return (
                <>
                    <div className="container">
                        <div className='titenquete'>
                            <p>Seu formulário de inscrição está sendo enviado.</p>
                        </div>
                        <div className='titenquete'>
                            <p>Por favor, não feche esta tela até o processo finalizar.</p>
                        </div>
                    </div>
                </>
            )
        }
        return (<>
            <div className="container">
                <div className='titenquete'>
                    <p>A foto da receita está sendo enviada, isto pode demorar um pouco.</p>
                </div>
                <div className='titenquete'>
                    <p>Por favor, não feche esta tela até o processo finalizar.</p>
                </div>
            </div>
            <div className="centeringLoadingContainer">
                <Progress type="circle" percent={uploadProgress.toFixed(2)} />
            </div>
        </>)

    }, [loading, uploadProgress])

    return (
        <React.Fragment>
            <div className="enqueterpc">
                <div className="imagemdestaque">
                    <img src={imagePath} alt="banner"/>
                </div>
                {hasError? renderError() : loading || uploading ? renderLoading() : renderContent()}
            </div>
        </React.Fragment>
    )
}

export default Base

import React, { useState, useContext, useEffect, useMemo } from 'react'
import moment from 'moment'
import { Steps, Result } from 'antd'
import { HeaderComponent } from './components/Header'
import { MessageComponent } from './components/Message'

import { Components, termoCaminhosDoCampo2024, categoriaReceita } from './constants'

import { FirebaseContext, UploadContext } from '../../../Contexts'
import { CurrentUserContext } from '../../../Contexts'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getDeviceInfos from '../../../utils/getDeviceInfos'
import getPraca from '../../../utils/getPraca'

import sendUserConsent from '../../../utils/sendUserConsent'
import { useRequest } from '../../../hooks/request'

import originConsents from '../../../constants/originConsents'
import { CONTEUDO_API_URL } from '../../../constants/api'

const { Step } = Steps

const CaminhosDoCampo2024 = () => {
  const { firebaseFirestore, firebaseFunctions,  firebaseAuth } = useContext(FirebaseContext)
  const { S3StartUpload } = useContext(UploadContext)
  const { currentUser } = useContext(CurrentUserContext)

  const { data } =  firebaseFunctions.httpsCallable('getMediaKeys')()

  const [current, setCurrent] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [finish, setFinish] = useState(false)
  const [userIdToken, setUserIdToken] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [hasUploadingError, setHasUploadError] = useState(false)

  const [formValues, setFormValues] = useState({
    nomeCompleto: '',
    formularioCidade: '',
    formularioPraca: '',
    formularioIbge: '',
    cpf: '',
    telefone: '',
    email: '',
    receitaNome: '',
    receitaCategoria: '',
    receitaIngredientes: '',
    receitaModoPreparo: '',
    receitaInspiracao: '',
    receitaTempoCozimentoHoras: '',
    receitaTempoCozimentoMinutos: '',
    receitaPorcoes: '',
    dificuldadeReceita: '',
    consumoTemperaturaReceita: '',
    preferenciaClimaticaReceita: '',
    consumoReceita: '',
    termoAceito: false,
    formularioInscricaoAceito: false,
    timestamp: moment().unix(),
    media: []
  })

   const descricaoDashConteudo = useMemo(() => {
      return `categoria: ${formValues.receitaCategoria}\nnomeCompleto: ${formValues.nomeCompleto}\ndocumento: ${formValues.cpf}\ntelefone: ${formValues.telefone}\nemail: ${formValues.email}\nnomeReceita: ${formValues.receitaNome}`
  }, [formValues])

   const [, statusAPI, fetchSave] = useRequest(
    null,
    {
      url: `${CONTEUDO_API_URL}/midias`,
      method: 'post',
      headers: { Authorization: `Bearer ${userIdToken}` },
      data: {
        id_categoria_envio: categoriaReceita[formValues.receitaCategoria],
        titulo: formValues.receitaNome,
        descricao: descricaoDashConteudo,
        midias: formValues.media.map(item => ({ path: item.path, type: item.mediaType })),
        timestamp_firebase: formValues.timestamp
      }
    },
    null,
    {
      onComplete: data => onMediaComplete(data)
    }
  )

  const onMediaComplete = async (data) => {
    if (data.status === 200) {
      const parts = data.token.split('.')

      if(parts.length < 3) return

      const encodedPayload = parts[1]
      const payload = JSON.parse(atob(encodedPayload))

      const files = []
      payload.ids_arquivos.forEach((item, index) => {
          const filename = formValues.media[index].name
          const typeMatch = filename.match(/\.([^.]*)$/)

          const s3Filename = btoa(`{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`)
          const name = `${s3Filename}.${typeMatch[1].toLowerCase()}`
          const file = formValues.media[index]
          files.push({ name, file })
      })
      if(error.length === 0) {
          setUploading(true)
          S3StartUpload(files, setUploading, null, setHasUploadError)
      }
    } else {
        console.error('Falha no envio para API de conteudo')
        setError('Ocorreu uma falha inesperada');
    }
  }

  useEffect(() => {
    if(statusAPI && statusAPI.error) {
      setError('Erro: envio do formulário de inscricao.')
    }
  }, [statusAPI])

  useEffect(() => {
    const firestoreUpdateUserData = async () => {
      try {
        const user = await firebaseFirestore
          .collection('flerken-users')
          .doc(currentUser.uid)

        await user.update({
          nome: formValues.nomeCompleto,
          cpf: formValues.cpf,
          email: formValues.email,
          telefone: formValues.telefone,
          cidadeEndereco: {
            idIbge: formValues.formularioIbge,
            nome: formValues.formularioCidade,
            outroEstado: false
          }
        })
      } catch (e) {
        console.error('Erro ao atualizar dados do usuário:', e)
        setLoading(false)
        setError('Erro ao atualizar dados do usuário')
      }
    }

    const firestoreStartUpload = async () => {
      const firebaseMensagem = {
          ...formValues,
          created_at: moment().toDate(),
          id: `${currentUser.uid}-${formValues.timestamp}`,
          praca: await getPraca(currentUser),
          usr_email: currentUser.email,
          usr_id: currentUser.uid,
          usr_name: currentUser.nome,
          termo: termoCaminhosDoCampo2024,
          ipV4: await getClientIPv4(),
          device_infos: getDeviceInfos(),
      }

      delete firebaseMensagem.media

      const concursoCaminhosDoCampo2024 = firebaseFirestore
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')

      try {
          await concursoCaminhosDoCampo2024.doc(firebaseMensagem.id).set(firebaseMensagem)
          console.log('Ddos ado termo enviados com sucesso para o firestore')

          await sendUserConsent({
            name: formValues.nomeCompleto,
            email: formValues.email,
            origin: originConsents[termoCaminhosDoCampo2024]
          })

          if(!uploading && !hasUploadingError) {
            setFinish(true)
          }
      } catch (err) {
          console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
          setError('Erro ao enviar os dados para o firestore')
      }

      setLoading(false)
    }

    if(loading) {
      fetchSave()
      firestoreUpdateUserData()
      firestoreStartUpload()
    }
  }, [loading])

  useEffect(() => {
    const getUserToken = async () => {
      const token = await firebaseAuth.currentUser.getIdToken()
      setUserIdToken(token)
    }

    if(currentUser) {
      getUserToken()
      setFormValues(prev => ({
       ...prev,
        nomeCompleto: currentUser.nome || '',
        cpf: currentUser.cpf || '',
        email: currentUser.email || '',
        telefone: currentUser.telefone || ''
     }))
    }
  }, [currentUser])

  if(!currentUser) {
    return <HeaderComponent>
      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Usuário não autenticado</p>
    </HeaderComponent>
  }

   if(error.length > 0) {
    return <HeaderComponent>
      <Result
        status="500"
        title="500"
        subTitle={error}
        extra={
          <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button onClick={() => setError('')} className="btsalvar button-caminhos-do-campo">
                Tentar novamente
            </button>
          </div>
        }
      />
    </HeaderComponent>
  }

  if(!uploading && hasUploadingError) {
    return <HeaderComponent>
      <Result
        status="500"
        title="500"
        subTitle='Erro ao enviar a foto do prato.'
        extra={
          <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button onClick={() => setError('')} className="btsalvar button-caminhos-do-campo">
                Tentar novamente
            </button>
          </div>
        }
      />
    </HeaderComponent>
  }

  if (loading) {
    return <HeaderComponent>
      <MessageComponent
        title="Enviando inscrição"
        description="Por favor, não feche esta tela até o processo finalizar."
      >
        <img src="/imgs/giphy.gif" className="img-loading"/>
      </MessageComponent>
    </HeaderComponent>
  }

  if (finish) {
    return <HeaderComponent>
      <Result
        status="success"
        title="Receita inscrita 😊"
        extra={<>
          <p style={{ fontWeight: '700' }}>Oba! Seu prato foi inscrito com sucesso.</p>
          <p>Que tal participar mais uma vez? Caso queira inscrever mais uma receita é só voltar para a tela inicial 😋</p>
           <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button
              className="btsalvar button-caminhos-do-campo"
              onClick={() => {
                setFormValues(prev => ({
                  ...prev,
                  receitaNome: '',
                  receitaCategoria: '',
                  receitaIngredientes: '',
                  receitaModoPreparo: '',
                  receitaInspiracao: '',
                  receitaTempoCozimentoHoras: '',
                  receitaTempoCozimentoMinutos: '',
                  receitaPorcoes: '',
                  receitaNivelDificuldade: '',
                  receitaDiasQuentesFrios: '',
                  receitaPratoAssadoCozido: '',
                  termoAceito: false,
                  formularioInscricaoAceito: false,
                  timestamp: moment().unix(),
                  media: []
                }))
                setCurrent(0)
                setFinish(false)
              }}>
                Tela inicial
            </button>
          </div>
        </>}
      />
    </HeaderComponent>
  }

  const Component = Components[current]

  return <React.Fragment>
    <HeaderComponent>
     <Steps
          current={current}
          onChange={(value) => setCurrent(value)}
          style={{ padding: '0px 15px 25px' }}
        >
          {Object.keys(Components).map(key => <Step key={key} />)}
      </Steps>
      <Component
        form={[formValues, setFormValues]}
        setCurrentStep={setCurrent}
        setLoading={setLoading}
      />

      <p style={{ marginTop: '20px', fontWeight: 700, textAlign: 'center', fontSize: '14px' }}>AUTORIZAÇÃO SECAP Nº 03.034858/2024.</p>
    </HeaderComponent>
  </React.Fragment>
}

export default CaminhosDoCampo2024

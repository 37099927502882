import React, { useState, useCallback, useMemo, useEffect } from 'react'

import parsers from '../../../../utils/parsers'
import InputWithLabel from '../../../../components/InputWithLabel'
import DropdownWithLabel from '../../../../components/DropdownWithLabel'

import { checkUnfilledFields } from '../../../../utils/helpers'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import Modal from '../../../../components/Modal'

import TermoGloboEsporte2024 from '../../../../termosAceite/termoGloboEsporte2024'

const characterLimit = 255

const InscricaoPessoal = (props) => {
  const [formValues, setFormValues] = props.form
  const [cidades, setCidades] = useState()
  const [pracas, setPracas] = useState()
  const [ibges, setIbges] = useState()
  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [errorRequired, setErrorRequired] = useState({
    cidadeEndereco: false,
    time: false,
    participar: false
  })

  useEffect(() => {
    const loadCidadesPracas = async () => {
      const cidades = await fetch('/cidades.json').then((res) => res.json())
      const cidadesObj = {},
        pracasObj = {},
        ibgesObj = {}
      Object.entries(cidades).forEach(([key, cidade]) => {
        cidadesObj[`${cidade.nome}`] = cidade.nome
        pracasObj[`${cidade.nome}`] = cidade.praca
        ibgesObj[`${cidade.nome}`] = cidade.id_ibge
      })
      setCidades(cidadesObj)
      setPracas(pracasObj)
      setIbges(ibgesObj)
    }
    loadCidadesPracas()
  }, [])

  useEffect(() => {
    const { cidadeEndereco } = formValues
    const praca = pracas ? pracas[cidadeEndereco] : undefined
    const ibge = ibges ? ibges[cidadeEndereco] : undefined

    if (!!cidadeEndereco && !!praca && !!ibge) {
      setFormValues((prev) => ({
        ...prev,
        formularioPraca: praca,
        formularioIbge: ibge
      }))
    }
  }, [formValues.cidadeEndereco])

  const isNextButtonDisabled = useMemo(() => {
    const {
      nomeCompleto,
      telefone,
      cidadeEndereco,
      time,
      participar,
      termoAceito
    } = formValues

    const requiredFields = checkUnfilledFields({
      nomeCompleto: {
        value: nomeCompleto,
        minLength: 1
      },
      telefone: {
        value: telefone,
        minLength: 11
      },
      cidadeEndereco: {
        value: cidadeEndereco.nome,
        minLength: 1
      },
      time: {
        value: time,
        minLength: 1
      },
      participar: {
        value: participar,
        minLength: 1
      }
    })

    return !(requiredFields.length === 0 && termoAceito)
  }, [formValues])

  const onBlurInputEventHandler = useCallback(
    (attribute) => (event) => {
      const value = event.target.value
      const valueLength = value.length
      if (valueLength === 0 || value === 'default') {
        setErrorRequired((prev) => ({ ...prev, [`${attribute}`]: true }))
      }
    },
    []
  )

  const onChangeDropdownEventHandler = (attribute) => (event) => {
    setErrorRequired((prev) => ({ ...prev, [`${attribute}`]: false }))
    const { options, selectedIndex } = event.target
    const value = options[selectedIndex].text
    const objCity = {
      idIbge: ibges[value],
      nome: value,
      outroEstado: false
    }
    setFormValues((prev) => ({ ...prev, [`${attribute}`]: objCity }))
  }

  const onChangeInputEventHandler = useCallback(
    (attribute, maxLength) => (event) => {
      const value = event.target.value
      const valueLength = value.length
      if (valueLength === 0) {
        setErrorRequired((prev) => ({ ...prev, [`${attribute}`]: true }))
      }
      const parseType = event.target.dataset.parser
      const parsedValue = parseType ? parsers[parseType](value) : value
      if (valueLength <= maxLength) {
        setFormValues((prev) => ({ ...prev, [`${attribute}`]: parsedValue }))
      }
    },
    []
  )
  const handleCheckedTermo = useCallback(() => {
    setFormValues((prev) => ({ ...prev, termoAceito: !prev.termoAceito }))
  }, [])

  return (
    <>
      <div className="form-globo-esporte-2024">
        <InputWithLabel
          title={'Qual é o seu nome?'}
          inputPlaceholder={'Digite seu nome'}
          inputValue={formValues.nomeCompleto}
          onChange={onChangeInputEventHandler('nomeCompleto', characterLimit)}
          required
        />
        <InputWithLabel
          title={'Qual é o seu telefone?'}
          inputPlaceholder={'Digite seu telefone'}
          inputValue={formValues.telefone}
          onChange={onChangeInputEventHandler('telefone', characterLimit)}
          maxInputLength={16}
          mask="telefone"
          parser="onlyNumbers"
          required={true}
        />
        <DropdownWithLabel
          title={'Em qual cidade você mora?'}
          inputPlaceholder={'Selecione uma opção'}
          values={cidades}
          defaultValue={formValues.cidadeEndereco.nome || 'default'}
          onChange={onChangeDropdownEventHandler('cidadeEndereco')}
          onBlur={onBlurInputEventHandler('cidadeEndereco')}
          required={errorRequired.cidadeEndereco}
        />
        <InputWithLabel
          title={'Para qual time você torce?'}
          inputValue={formValues.time}
          onChange={onChangeInputEventHandler('time', characterLimit)}
          onBlur={onBlurInputEventHandler('time')}
          required={errorRequired.time}
        />

        <InputWithLabel
          title={'Por que você deveria ser escolhido para participar?'}
          inputPlaceholder={'Defenda aqui a sua participação no GE na Feira...'}
          className="textInputArea"
          inputValue={formValues.participar}
          onChange={onChangeInputEventHandler('participar', characterLimit)}
          onBlur={onBlurInputEventHandler('participar')}
          required={errorRequired.participar}
        />

        <div className="opcenquete align-left">
          <ToggleSwitch
            checked={formValues.termoAceito}
            setChecked={handleCheckedTermo}
            name="termoAceito"
          />
          <button
            type="button"
            className="btTransparente"
            onClick={() => {
              document.body.scrollTop = 0 // For Safari
              document.documentElement.scrollTop = 0 // FF, GC, Opera
              document.body.style.overflowY = 'hidden'
              setMostrarTermoModal((_) => true)
            }}
          >
            <span>
              <strong>TERMOS DE ACEITE/INSCRIÇÃO – GE NA FEIRA</strong>​
            </span>
          </button>
        </div>

        <div className="observation-termo">
          <p>
            Ao me cadastrar neste quadro estou ciente que o eventual convite é
            pessoal e exclusivo para o usuário cadastrado no APP.
            <br />
            <br />A RPC se reserva ao direito de encerrar o quadro a qualquer
            momento, sem que seja devido ao usuário do APP qualquer tipo de
            indenização.
          </p>
        </div>

        {mostrarTermoModal && (
          <Modal
            setTermoAceito={handleCheckedTermo}
            setShowModal={setMostrarTermoModal}
            classNameButton="button-globo-esporte-2024"
          >
            <TermoGloboEsporte2024 />
          </Modal>
        )}

        <div className="btsalvar">
          <button
            onClick={props.sendData}
            className="btsalvar button-globo-esporte-2024"
            disabled={isNextButtonDisabled}
          >
            Enviar
          </button>
        </div>
      </div>
    </>
  )
}

export default InscricaoPessoal

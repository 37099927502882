import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Divider, Result } from 'antd';

import ToggleSwitch from '../../../components/ToggleSwitch';
import InputWithLabel from '../../../components/InputWithLabel';
import DropdownWithLabel from '../../../components/DropdownWithLabel';
import DatePickerWithLabel from '../../../components/DatePickerWithLabel';
import Modal from '../../../components/Modal';
import TermoPlateiaEstudioC from '../../../termosAceite/termoPlateiaEstudioC';
import cpfMask from '../../../utils/masks/cpfMask';
import convertFirebaseTimestampToDate from '../../../utils/convertFirebaseTimestampToDate';

import { FirebaseContext, CurrentUserContext } from '../../../Contexts';

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getPraca from '../../../utils/getPraca'
import getDeviceInfos from '../../../utils/getDeviceInfos'

import { checkUnfilledFields } from '../../../utils/helpers';

import sendUserConsent from '../../../utils/sendUserConsent';
import originConsents from '../../../constants/originConsents'
import plateiaEstudioCCount from '../../../assets/images/plateiaEstudioC_Count.png'


const HeaderComponent = ({ children }) => (
  <div className="enqueterpc">
    <div className="imagemdestaque">
        <img src="/imgs/inscricao_plateia_estudioc.png" alt="banner"/>
    </div>
    {children}
  </div>
);

const MessageComponent = ({ children, title, description }) => (
  <HeaderComponent>
    <div className="container">
        <div className="titenquete">
            <p className="title">{title}</p>
        </div>
        <div className="titenquete">
            <p style={{ fontWeight: 400 }}>{description}</p>
        </div>
        {children}
    </div>
  </HeaderComponent>
);

const termoPlateiaEstudioC = 'plateiaEstudioC2022';
const initialBirthdayDate = moment().subtract(18, 'year')

const PlateiaEstudioC = () => {
  const { firebaseFirestore } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [error, setError] = useState(false)

  const [cidades, setCidades] = useState([]);
  const [mostrarTermoModal, setMostrarTermoModal] = useState(false);

  const [dataParticipacao, setDataParticipacao] = useState(undefined);
  const [timeLeft, setTimeLeft] = useState('00 dias 00 h 00 min');

  const [formValues, setFormValues] = useState({
      cidade: {
          key: 0,
          nome: '',
          ibge: '',
          praca: ''
      },
      nomeCompleto: '',
      cpf: '',
      email: '',
      telefone: '',
      termoAceito: false,
      termoPessoaPublica: false,
      dataNascimento: '',
      observacao: ''
  });

  const onChangeInputEventHandler = useCallback(
    (attribute, maxLength = 0) => (event) => {
      const value = event.target.value;
      const valueLength = value.length;

      if (valueLength <= maxLength) {
          setFormValues(prev => ({...prev, [`${attribute}`]: value}))
      }
  })

  const onChangeDropdownEventHandler = useCallback(
    (attribute, cities) => (event) => {
      const {options, selectedIndex} = event.target
      const value = options[selectedIndex].text

      const cidade = cities.find(({ nome }) => nome === value);

      setFormValues(prev => ({...prev, cidade }))
  })

  const updateTimeLeft = (diff) => {
    const duration = moment.duration(diff);
    const days = String(Math.floor(duration.asDays())).padStart(2, '0');
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');

    setTimeLeft(`${days} ${days > 1 ? 'dias' : 'dia'} ${hours} h ${minutes} min`);
  };

  const disabledButon = useMemo(() => {
      const {
        nomeCompleto,
        cpf,
        email,
        telefone,
        dataNascimento,
        termoAceito,
        termoPessoaPublica,
        cidade
      } = formValues

      const requiredFields = checkUnfilledFields({
        nomeCompleto: {
          value: nomeCompleto,
          minLength: 5
        },
        cpf: {
          value: cpf,
          minLength: 11
        },
        dataNascimento: {
          value: dataNascimento,
          minLength: 1
        },
        cidadeNome: {
          value: cidade.nome,
          minLength: 1
        },
        email: {
          value: email,
          minLength: 5
        },
        telefone: {
          value: telefone,
          minLength: 10
        }
      })

      return !(termoAceito && termoPessoaPublica && requiredFields.length === 0)
  }, [formValues]);

  useEffect(() => {
    const firestoreStartUpload = async () => {
      const firebaseMensagem = {
          formularioCidadeNome: formValues.cidade.nome,
          formularioCidadePraca: formValues.cidade.praca,
          formularioDataNascimento: formValues.dataNascimento.toDate(),
          formularioCpf: formValues.cpf.replace(/[^\d]/g, ''),
          formularioTelefone: formValues.telefone,
          formularioNomeCompleto: formValues.nomeCompleto,
          formularioEmail: formValues.email,
          formularioObservacao: formValues.observacao,
          created_at: moment().toDate(),
          id: `${currentUser.uid}-${moment().unix()}`,
          praca: await getPraca(currentUser),
          usr_email: currentUser.email,
          usr_id: currentUser.uid,
          usr_name: currentUser.nome,
          termo: termoPlateiaEstudioC,
          ipV4: await getClientIPv4(),
          device_infos: getDeviceInfos(),
      }

      const plateiaEstudioCDoc = firebaseFirestore
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')

      try {
          await plateiaEstudioCDoc.doc(firebaseMensagem.id).set(firebaseMensagem)
          console.log('Ddos ado termo enviados com sucesso para o firestore')

          await sendUserConsent({
            name: formValues.nomeCompleto,
            email: formValues.email,
            origin: originConsents.plateiaEstudioC2022
          })

          setFinish(true)
      } catch (err) {
          console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
          setError(true)
      }

      setLoading(false)
    }

    if(loading) {
      firestoreStartUpload()
    }
  }, [loading])

  useEffect(() => {
    const userExistsInDB = async () => {
       const registerInDB = await firebaseFirestore
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')
        .where('usr_id', '==', currentUser.uid)
        .where('termo', '==', termoPlateiaEstudioC)
        .orderBy('created_at', 'desc')
        .limit(1)
        .get()

      let dataDeCadastro
      let canRegister = true

      if(registerInDB.docs.length > 0) {
        const { created_at: { seconds, nanoseconds } } = registerInDB.docs[0].data()

        dataDeCadastro = convertFirebaseTimestampToDate(seconds, nanoseconds)
        canRegister = moment().diff(dataDeCadastro, 'days') > 29
      }

      if(!canRegister) {
        setDataParticipacao(dataDeCadastro)
      }
    }

    if(currentUser) {
     userExistsInDB()
     setFormValues(prev => ({
        ...prev,
        nomeCompleto: currentUser.nome || '',
        cpf: currentUser.cpf ? cpfMask(currentUser.cpf) : '',
        email: currentUser.email || '',
        telefone: currentUser.telefone || ''
     }))
    }
  }, [currentUser])

  useEffect(() => {
    const loadCidades = async () => {
        const cidades = await fetch('/cidades.json').then(res => res.json());
        const cidadesObj = [
          {
            key: 0,
            nome: 'Sou de fora do Paraná',
            ibge: '',
            praca: ''
          }
        ];

        Object.entries(cidades).forEach(
            ([key, cidade]) => {
                cidadesObj.push({
                    key: key + 1,
                    nome: cidade.nome,
                    ibge: cidade.id_ibge,
                    praca: cidade.praca
                });
            }
        );
        setCidades(cidadesObj);
    }
    loadCidades();
  }, []);

  useEffect(() => {
    if(dataParticipacao) {
      const interval = setInterval(() => {
        const diff = moment(dataParticipacao).add(30, 'days').diff(moment())

        updateTimeLeft(diff)

        if(moment().diff(dataParticipacao, 'days') > 29) {
          clearInterval(interval)
          setDataParticipacao(undefined)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [dataParticipacao])

  if(!currentUser) {
    return <MessageComponent
      title="Usuário não identificado"
      description="É necessário se autenticar no app para efetuar a inscrição."
    />
  }

  if(error) {
    return <MessageComponent
      title="Ocorreu um erro ao enviar a sua inscrição"
      description="Por favor, tente novamente mais tarde."
    />
  }

  if (loading) {
    return <MessageComponent
      title="Enviando inscrição"
      description="Por favor, não feche esta tela até o processo finalizar."
    >
      <img src="/imgs/giphy.gif" className="img-loading"/>
    </MessageComponent>
  }

  if(dataParticipacao) {
    return <HeaderComponent>
      <div className="container" style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
      }}>
        <img src={plateiaEstudioCCount} alt="Contagem de inscrições"/>
        <div style={{ textAlign: 'left', marginBottom: '20px' }}>
          <p style={{ fontWeight: '700', margin: '25px 0px 5px', fontSize: '16px' }}>Você já possui cadastro realizado!</p>
          <p style={{ fontWeight: '500', marginBottom: '5px' }}>Para participar novamente, aguarde o tempo abaixo.</p>
          <p style={{ fontWeight: '500',  marginBottom: '0px' }}>Faltam:</p>
          <p style={{ fontWeight: '500', fontSize: '22px', marginBottom: '5px' }}>{timeLeft}</p>
          <p style={{ fontWeight: '500' }}>Estamos ansiosos para te ver de volta!</p>
        </div>
        {/* <button onClick={() => {
          const url = 'vocenarpc://home'
          window.location.replace(url)

        setTimeout(() => (window.location.href = url), 1000)
        }}
        type="button"
        style={{
          width: '300px',
          border: '1px solid #E965C8',
          borderRadius: '25px',
          padding: '10px 0px',
          fontWeight: '700',
          color: '#E965C8',
          textTransform: 'uppercase',
          backgroundColor: 'transparent',
          fontSize: '13px'
        }}>Voltar</button> */}
      </div>
    </HeaderComponent>
  }

  if (finish) {
    return <HeaderComponent>
      <Result
        status="success"
        title="Inscrição feita 😊"
        extra={<>
          <p style={{ fontWeight: '700' }}>Em breve entraremos em contato através do seu endereço de e-mail.</p>
          <p>Aguarde mais informações sobre a sua participação na plateia e continue acompanhando as novidades do Estúdio C - todos os sábados na tela da RPC. Até mais!</p>
        </>}
      />
    </HeaderComponent>
  }

  const handleCheckedTermo = (termo) => {
      setFormValues(prev => ({...prev, [termo]: !prev[termo]}))
  }

  const changeFormValue = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }))
  }

  return <HeaderComponent>
    <div className="container">
      <p>
        Quer acompanhar o Estúdio C bem de pertinho?
        Faça a sua inscrição para participar da plateia do 'Estúdio C', na RPC, em Curitiba.
      </p>
      <Divider />
      <InputWithLabel
          title="Nome completo"
          inputValue={formValues.nomeCompleto}
          onChange={(e) => changeFormValue('nomeCompleto', e.target.value)}
          minInputLength="6"
          required={true}
      />
      <InputWithLabel
          title="CPF"
          inputValue={formValues.cpf}
          onChange={(e) => changeFormValue('cpf', e.target.value)}
          mask="cpf"
          parser="cpf"
          minInputLength='14'
          required={true}
      />
      <InputWithLabel
          title="E-mail"
          inputValue={formValues.email}
          onChange={(e) => changeFormValue('email', e.target.value)}
          type="email"
          required={true}
      />
      <InputWithLabel
          title="Telefone"
          inputValue={formValues.telefone}
          onChange={(e) => changeFormValue('telefone', e.target.value)}
          maxInputLength={16}
          mask='telefone'
          parser='onlyNumbers'
          required={true}
      />
      <DatePickerWithLabel
        title="Data de nascimento"
        defaultValue={initialBirthdayDate}
        disabledDate={current => current > initialBirthdayDate}
        inputValue={formValues.dataNascimento}
        showToday={false}
        style={{ borderRadius: '25px' }}
        onChange={(value) => changeFormValue('dataNascimento', value)}
      />
      <div style={{ paddingTop: '10px' }} />
      <DropdownWithLabel
          title="Escolha a sua cidade"
          values={cidades.map(({ nome }) => nome)}
          defaultValue={!!formValues.cidade.nome ? formValues.cidade.key : 'default'}
          onChange={onChangeDropdownEventHandler('cidade', cidades)}
      />
      <InputWithLabel
          className="textInputArea"
          title="Observação"
          inputPlaceholder="Descreva aqui se você precisa de algum atendimento especial ou gostaria de trazer um grupo para participar da plateia."
          inputValue={formValues.observacao}
          onChange={onChangeInputEventHandler('observacao', 1000)}
      />
      <div className="opcenquete align">
          <ToggleSwitch name="toggle-aceito" checked={formValues.termoAceito} style={{ width: '50px' }}
              setChecked={() => handleCheckedTermo('termoAceito')}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}
              onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  document.body.style.overflowY = 'hidden';
                  setMostrarTermoModal(_ => true)
              }}
          >
              <span>
                  <strong>Declaro que li e aceito os termos de uso</strong>
              ​</span>
          </button>
      </div>
      <div className="opcenquete align">
          <ToggleSwitch name="toggle-pessoa-publica" checked={formValues.termoPessoaPublica} style={{ width: '50px' }}
              setChecked={() => handleCheckedTermo('termoPessoaPublica')}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}>
              <span>
                  <strong>Declaro que não sou candidato ao pleito de 2024.</strong>
              ​</span>
          </button>
      </div>

      {
          mostrarTermoModal &&
          <Modal
              setTermoAceito={() => handleCheckedTermo('termoAceito')}
              setShowModal={setMostrarTermoModal}
          >
              <TermoPlateiaEstudioC />
          </Modal>
      }
      <div className="btsalvar" style={{ paddingTop: '20px' }}>
          <button onClick={() => setLoading(true)} className="btsalvar" disabled={disabledButon}>
              Enviar solicitação de participação
          </button>
      </div>
    </div>
  </HeaderComponent>;
};

export default PlateiaEstudioC

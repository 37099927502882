import { visitanteRPCCollection, termoVisitanteRPC } from '../../constants'

const userRegister = async (firebaseFirestore, currentUser) => {
  return await firebaseFirestore
    .collection(visitanteRPCCollection)
    .where('usr_id', '==', currentUser.uid)
    .where('termo', '==', termoVisitanteRPC)
    .get()
}

export default userRegister

import React from 'react'

const Modal = ({
  setTermoAceito,
  setShowModal,
  onClick = null,
  onClose = null,
  children,
  classNameButton = ''
}) => {
  const onCloseModal = () => {
    setShowModal((_) => false)
    document.body.style.overflowY = 'unset'
  }

  const onCloseModalByCancel = () => {
    if (onClose) {
      onClose()
    }
    onCloseModal()
  }

  return (
    <>
      <div className="background-modal"></div>
      <div id="termoModal" className="modal modal-resize center">
        <div className="modal-content">
          <button
            className="btTransparente right"
            onClick={() => onCloseModalByCancel()}
          >
            <span className="close">&times;</span>
          </button>
          <div className="termoAceite">{children}</div>
          <div className="btsalvar btn-default">
            <button
              className={classNameButton}
              type="button"
              onClick={
                onClick
                  ? onClick
                  : () => {
                      if (setTermoAceito) {
                        setTermoAceito((_) => true)
                      }
                      onCloseModal()
                    }
              }
            >
              Aceitar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal

import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from 'react'

import Modal from '../../../../components/Modal'
import ToggleSwitch from '../../../../components/ToggleSwitch'

import TermoGunsNRoses2022 from '../../../../termosAceite/termoGunsNRoses2022'

import Base from './Base'

import { FirebaseContext } from '../../../../Contexts'

const termoGunsNRoses2022 = 'termoGunsNRoses2022'

const GunsNRoses2022 = () => {
  const { firebaseFirestore: db } = useContext(FirebaseContext)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [availableTicketsAmmount, setAvailableTicketsAmmount] = useState([])
  const [availableTicketsAmmountRef, setAvailableTicketsAmmountRef] = useState()

  const [formValues, setFormValues] = useState({
    termoAceito: false
  })

  const handleCheckedTermo = useCallback(() => {
    setFormValues((prev) => ({ ...prev, termoAceito: !prev.termoAceito }))
  }, [])

  const salvarOk = useMemo(() => {
    const {
      termoAceito
    } = formValues

    return !(
      termoAceito && availableTicketsAmmount > 0
    )
  }, [formValues, availableTicketsAmmount])

  useEffect(() => {
    const getAvailableTicketsAmmount = async () => {
      const docRef = await db
        .collection('sistema-reserva-tickets')
        .doc('guns-n-roses-2022')//alterar

      const data = await (await docRef.get()).data()

      setAvailableTicketsAmmountRef(docRef)
      setAvailableTicketsAmmount(data.quantidadeTicketsDisponiveis)
    }
    getAvailableTicketsAmmount()
  }, [])

  return (
    <Base
      description={() => (
        <div className="container">
          <div className="opcenquete align-left">
            <ToggleSwitch
              checked={formValues.termoAceito}
              setChecked={handleCheckedTermo}
              name="termoAceito"
            />
            <button
              type="button"
              className="btTransparente"
              onClick={() => {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0 // FF, GC, Opera
                document.body.style.overflowY = 'hidden'
                setMostrarTermoModal(() => true)
              }}
            >
              <span className="texto-termo">
                Declaro que li e aceito os <strong>termos de privacidade</strong> do
                <strong> show do Guns N' Roses</strong>.
              </span>
            </button>
          </div>
          <div className="texto-atencao" style={{ marginTop: '14px' }}>
            {!formValues.termoAceito && (
              <span>
                <strong>Atenção</strong>: Neste caso (
                <strong>NÃO ACEITE</strong>) não será possível sua participação
                no evento<strong> show do Guns N' Roses</strong>.
                <br /><br />
                Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo
                “Você na RPC”.
              </span>
            )}
          </div>

          {mostrarTermoModal && (
            <Modal
              setTermoAceito={handleCheckedTermo}
              setShowModal={setMostrarTermoModal}
            >
              <TermoGunsNRoses2022 />
            </Modal>
          )}
        </div>
      )}
      title="show do Guns N' Roses 2022"
      imagePath="/imgs/cupons/banner_guns.png"
      programName="show do Guns N' Roses"
      form={formValues}
      setForm={setFormValues}
      salvarOk={salvarOk}
      tipoTermo={termoGunsNRoses2022}
      availableTicketsAmmountRef={availableTicketsAmmountRef}
      db={db}
      availableTicketsAmmount={availableTicketsAmmount}
    />
  )
}

export default GunsNRoses2022

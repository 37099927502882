import React from 'react';

const TermoTributoQueen = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – QUEEN CELEBRATION IN CONCERT</strong></p>
        <p>Quanto aos seus DADOS PESSOAIS, esclarecemos:</p>
        <p><strong>a.</strong> - Você deverá ter o máximo de atenção no momento da sua inscrição, ficando certo, desde já, que a REDE PARANAENSE DE COMUNNICAÇÃO - RPC não se responsabiliza por erros cadastrais dos inscritos, tais como: cadastro duplo, equívocos de dados, e outros.</p>
        <p><strong>b.</strong> - Todos os dados dos inscritos serão armazenados num banco de dados único da <strong>Queen Celebration in Concert</strong>, de acordo com os Termos de Uso do aplicativo "VOCÊ NA RPC" (<a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank">toque para acessar o termo</a>) e com a política de privacidade da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">toque para acessar o termo</a>).  </p>
        <p><strong>c.</strong> - Ao realizar sua inscrição para se beneficiar do desconto, você autoriza expressamente a RPC a fazer o tratamento de seus dados pessoais, pelo prazo de 05 (cinco) anos, para que estes sejam usados pela RPC ou terceiros ligados ao show em questão, ou seja: Dinamize (sistema de envio de e-mails) - <a href="https://www.dinamize.com.br/politica-de-privacidade/" target="_blank">toque para acessar o termo</a>, Aplicativo Você na RPC (<a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank">toque para acessar o termo</a>) e com a política de privacidade da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">toque para acessar o termo</a>) para fins de desenvolvimento e implementação do sistema de desconto de ingressos, bem como para fins de relacionamento com você, identificação, localização, preservação dos seus direitos e obrigações e da RPC, sem o direito de obter o reconhecimento, compensação ou remuneração de qualquer espécie para tal uso. </p>
        <p><strong>d.</strong> - Caso queira, e mediante requisição, você poderá solicitar à RPC a retificação, correção e atualização de seus dados pessoais, durante a vigência da promoção <strong>Queen Celebration In Concert - 2022</strong>, para tanto deverá entrar em contato por meio do <strong>Aplicativo Você na RPC</strong> </p>
        <p><strong>e.</strong> - Também, mediante requisição, você poderá solicitar o cancelamento de sua inscrição por meio do <strong>Aplicativo Você na RPC</strong>, todavia, ficará resguardado o direito de arquivamento de seus dados pela RPC, pelo prazo de 05 (cinco) anos, conforme acima estabelecido. </p>
        <p><strong>f.</strong> - A RPC se reserva ao direito de cancelar e/ou bloquear o acesso e cadastro de qualquer inscrito na promoção de desconto de ingressos, a qualquer momento e sem prévio aviso, caso seja constatado que este pratica ou venha a praticar algum ato ou conduta que (i) viole as leis e regulamentos federais, estaduais e/ou municipais e (ii) viole os princípios da moral e dos bons costumes.  </p>

        <p>Ao enviar meus dados e clicar no aceite abaixo, estou de acordo com os termos/condições acima, bem como com os Termos de Uso do aplicativo "VOCÊ NA RPC" (<a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank">toque para acessar o termo</a>) e das Políticas de Privacidade e Proteção de Dados da GLOBO (<a href="https://privacidade.globo.com/privacy-policy" target="_blank">toque para acessar o termo</a>) e da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">toque para acessar o termo</a>), e aceito receber informações sobre descontos exclusivos em shows promovidos pela RPC, bem como de outros projetos, cursos e/ou outras ações promovidas e apoiadas pela RPC a partir da presente data em meu e-mail e telefone.  </p>
    </>
)

export default TermoTributoQueen;

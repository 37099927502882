import React, { useCallback, useMemo, useState } from 'react'
import { Divider } from 'antd';

import ImagePicker from '../../../components/ImagePicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import ToggleSwitch from '../../../components/ToggleSwitch'

import Base from './Base'

import { checkUnfilledFields } from '../../../utils/helpers';

import parsers from '../../../utils/parsers/index'

const characterLimit = 255

const RPCGame2021 = () => {
    const [formValues, setFormValues] = useState({
        nomeTime: '',
        nomeRepresentante: '',
        programaAssistido: '',
        comumRPC: '',
        // termoAceito: false,
        // termoAutorizacao: false,
        media: []
    })

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
            const parseType = event.target.dataset.parser
            const parsedValue = parseType ? parsers[parseType](value) : value
            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
            }
        }, []
    );

    // const handleCheckedTermo = useCallback(() => {
    //     setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    // }, [])

    // const handleCheckedTermoAutorizacao = useCallback(() => {
    //     setFormValues(prev => ({...prev, termoAutorizacao: !prev.termoAutorizacao}))
    // }, [])

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [])

    const salvarOk = useMemo(() => {
        const { 
            nomeTime,
            nomeRepresentante,
            programaAssistido,
            comumRPC,
            media,
            // termoAceito,
            // termoAutorizacao
        } = formValues;

        const requiredFields = checkUnfilledFields({
            nomeTime: { 
                value: nomeTime,
                minLength: 0
            },
            nomeRepresentante: {
                value: nomeRepresentante,
                minLength: 0
            },
            programaAssistido: {
                value: programaAssistido,
                minLength: 0
            },
            comumRPC: {
                value: comumRPC,
                minLength: 0
            },
        });

        return !(
            // termoAceito && 
            // termoAutorizacao &&
            requiredFields.length === 0
            && media.length > 0
        )
    }, [formValues])

    return (
        <Base
            title="RPC Game 2021"
            description={() => (
                <div className="container">
                    <InputWithLabel
                        title={"Nome do time"}
                        subTitle="Campo obrigatório"
                        inputPlaceholder="Escreva aqui o nome do seu time"
                        inputValue={formValues.nomeTime}
                        onChange={onChangeInputEventHandler('nomeTime', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Qual o nome do representante do seu time?"}
                        subTitle="Campo obrigatório"
                        inputPlaceholder="Escreva aqui o nome do representante do seu time"
                        inputValue={formValues.nomeRepresentante}
                        onChange={onChangeInputEventHandler('nomeRepresentante', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Qual programa você assistiu? "}
                        subTitle="Campo obrigatório"
                        inputPlaceholder="Escreva aqui o nome do programa que você assistiu"
                        inputValue={formValues.programaAssistido}
                        onChange={onChangeInputEventHandler('programaAssistido', characterLimit)}
                    />
                    <InputWithLabel
                        title={"O que você tem em comum com a RPC?"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.comumRPC}
                        maxInputLength={50000}
                        onChange={onChangeInputEventHandler('comumRPC', 50000)}className="textInputArea"
                        inputPlaceholder={'O que você tem em comum com a RPC?'}
                    />

                    
                    <MediaGallery medias={formValues.media} />
                    <ImagePicker handleSetMedias={handleSetMedia} required={true} title='Chegou a hora da gente ver a sua criatividade. Mande sua foto aqui.'/>
                    
                    
                    {/* <Divider /> */}
                    {/* <div className="opcenquete">
                        <span>Para validar sua inscrição, você precisa responder se concorda com os Termos de Uso de Dados , Política de Privacidade e Regulamento</span>
                        <div className="opcenquete align-left">
                            <ToggleSwitch checked={formValues.termoAceito}
                                setChecked={handleCheckedTermo}
                                name='termoAceito'
                                />
                            <span style={{marginLeft: '10px'}}>
                            Concordo com os itens 1 e 2 do Termo de Uso de Dados, Política de Privacidade e Regulamento.
                            ​</span> 
                        </div>                     
                    </div>
                    <div className="opcenquete">
                        <span>Para continuar sua inscrição <a href="https://bit.ly/rpcgametermo1" target="_blank" rel="noopener noreferrer">clique aqui</a> para ler o Termo de Autorização e cessão de direitos de imagem e voz e divulgação do projeto/iniciativa</span>
                        <div className="opcenquete align-left">
                            <ToggleSwitch checked={formValues.termoAutorizacao}
                                setChecked={handleCheckedTermoAutorizacao}
                                name='termoAutorizacao'
                                />
                            <span style={{marginLeft: '10px'}}>
                            Concordo com o Termo de Autorização e cessão de direitos de imagem e voz e divulgação do projeto/iniciativa
                            </span> 
                        </div>                    
                    </div>
                    {
                        (!formValues.termoAceito || !formValues.termoAutorizacao) && 
                            <span>
                                Atenção: não será possível sua participação no “RPC GAME - 2021” Por favor, envie um e-mail para game@rpc.com.br.
                            </span>
                    } */}
                </div>
            )}
            imagePath="/imgs/rpc-game.png"
            programName="RPC Game"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="concursoRPCGame2021"
            mediaID={41}
        />
    )
}

export default RPCGame2021

import React from 'react'

const TermoPlanetarioMaringa2024 = () => (
  <>
    <>
      <p>
        <strong>TERMOS DE ACEITE</strong>
      </p>
      <p>
        Estou ciente e aceito que o presente agendamento decorre de uma parceria
        entre a RPC e o parceiro, sendo este último o único responsável pela
        disponibilização dos horários e por todos os aspectos relativos à
        visitação;
      </p>
      <p>
        Manifesto meu expresso consentimento com o tratamento dos dados
        fornecidos, seja pela RPC ou pela parceiro, especialmente para fins de
        confirmação do agendamento da visita.
      </p>
    </>
  </>
)

export default TermoPlanetarioMaringa2024

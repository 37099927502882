import React from 'react'

const ToggleSwitch = ({checked, setChecked, name='toggleSwitch', style = {}}) => {
    return (
        <div className="toggle-switch" style={style}>
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name={name}
                id={name}
                checked={!!checked}
                onChange={() => setChecked(prev => !prev)}
            />
            <label className="toggle-switch-label" htmlFor={name}>
                <span className="toggle-switch-inner" />
                <span className="toggle-switch-switch" />
            </label>
        </div>
    )
}

export default ToggleSwitch

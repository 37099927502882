import React, { useState, useRef, useEffect, useCallback } from 'react'
import 'aframe'
import Loading from '../../components/Loading'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-E68TZ3CBPD')

const AniversarioTeatroGuaira = () => {
  const location = useLocation()
  const { videoPlay } = location.state || ''

  const [isLoading, setIsLoading] = useState(false)
  const [isBuffering, setIsBuffering] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isIconVisible, setIsIconVisible] = useState(true)
  const [initialPlayVideo, setInitialPlayVideo] = useState(true)

  const videoRef = useRef(null)
  const timerRef = useRef(null)

  const [startTime, setStartTime] = useState(0)
  const [watchedTime, setWatchedTime] = useState(0)

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/video-aniversario-teatro-guaira',
      title: `Video Assistido do Teatro Guaíra - ${videoPlay.title}`
    })

    return () => {
      ReactGA.event({
        category: 'Video',
        action: 'Watch',
        label: `Video Assistido do Teatro Guaíra - ${videoPlay.title}`,
        value: Math.floor(watchedTime)
      })
    }
  }, [])

  useEffect(() => {
    const video = videoRef.current

    if (video) {
      const handleWaiting = () => {
        setIsBuffering(true)
      }

      const handlePlaying = () => {
        setIsBuffering(false)
      }

      const handleTimeUpdate = () => {
        setWatchedTime(video.currentTime)
      }

      const handleEnded = () => {
        ReactGA.event({
          category: 'Video',
          action: 'Completed',
          label: `Video Assistido do Teatro Guaíra 360 - ${videoPlay.title}`
        })
      }

      video.addEventListener('waiting', handleWaiting)
      video.addEventListener('playing', handlePlaying)
      video.addEventListener('ended', handleEnded)
      video.addEventListener('timeupdate', handleTimeUpdate)

      return () => {
        video.removeEventListener('waiting', handleWaiting)
        video.removeEventListener('playing', handlePlaying)
        video.removeEventListener('ended', handleEnded)
        video.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [startTime])

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    if (isPlaying) {
      timerRef.current = setTimeout(() => {
        setIsIconVisible(false)
      }, 2000)
    }
  }, [isPlaying, isIconVisible])

  const handlePlayPause = useCallback(() => {
    setInitialPlayVideo(false)
    setIsIconVisible(true)
    const video = videoRef.current
    if (video) {
      if (isPlaying) {
        video.pause()
        setIsPlaying(false)
      } else {
        video.play()
        setIsPlaying(true)
      }
    }
  }, [videoRef, isPlaying])

  const handleScreenClick = () => {
    if (!initialPlayVideo) {
      setIsIconVisible(true)
    }
  }

  if (!videoPlay)
    return (
      <>
        <Loading />
      </>
    )

  return (
    <div
      onClick={handleScreenClick}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: '#000'
      }}
    >
      <div style={{ height: '100vh', width: '100%' }}>
        <a-scene>
          <a-assets>
            <video
              id="video360"
              src={videoPlay.url}
              ref={videoRef}
              preload="auto"
              crossOrigin="anonymous"
            ></video>
          </a-assets>
          <a-videosphere src="#video360"></a-videosphere>
        </a-scene>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            onClick={isIconVisible ? handlePlayPause : null}
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              opacity: isIconVisible ? 1 : 0,
              transition:
                !isIconVisible && !isBuffering
                  ? 'opacity 1s ease-in-out'
                  : 'none'
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="130"
              height="130"
              viewBox="0 0 24 24"
              fill="rgba(250,250,250,0.7)"
            >
              {isPlaying && !initialPlayVideo ? (
                <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
              ) : (
                <path d="M8 5v14l11-7z" />
              )}
            </svg>
          </div>

          {isBuffering && !isIconVisible && isPlaying && (
            <div
              style={{
                position: 'fixed',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1100
              }}
            >
              <Player
                autoplay
                loop
                src="https://lottie.host/c4d461c8-92d8-40fe-850e-d5b3fdb0957e/O9XmOo02DN.json"
                style={{ height: '150px', width: '150px' }}
              >
                <Controls
                  visible={false}
                  buttons={['play', 'repeat', 'frame', 'debug']}
                />
              </Player>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default AniversarioTeatroGuaira

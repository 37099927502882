import React from 'react'
import { HeaderComponent } from '../HeaderComponent';


export const MessageComponent = ({ children, title, description }) => {
  return(
    <HeaderComponent>
      <div className="container">
          <div className="titenquete">
              <p className="title">{title}</p>
          </div>
          <div className="titenquete">
              <p style={{ fontWeight: 400 }}>{description}</p>
          </div>
          {children}
      </div>
    </HeaderComponent>
  )
};

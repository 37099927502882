import React from 'react'

const ImagePicker = ({
  setMedias,
  handleSetMedias = undefined,
  required = false,
  title = 'Selecione sua foto'
}) => {
  return (
    <>
      <button
        className="btMedia"
        type="button"
        onClick={() => document.getElementById('filePicker').click()}
      >
        {title}
        {required && (
          <>
            <br />
            <span className="subTitle">(Campo obrigatório)</span>
          </>
        )}
      </button>
      <input
        className="margin"
        id="filePicker"
        type="file"
        accept="image/*"
        alt="Selecionar uma foto"
        multiple={false}
        onChange={(e) => {
          const foto = e.target.files[0]

          if (!foto) return

          var reader = new FileReader()
          reader.onload = (event) => {
            foto.binaryString = event.target.result
          }
          reader.readAsBinaryString(foto)

          foto.src = URL.createObjectURL(foto)
          foto.path = foto.src.split('/').slice(-1)[0]
          foto.mediaType = 'photo'
          if (handleSetMedias) {
            handleSetMedias(foto)
          } else {
            setMedias((_) => [foto])
          }
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default ImagePicker

export default {
  termo_pesquisa_qualidade_sinal_05_2021:
    '90248a88fe67b01ab549c5eec4a802583a5befdcde5421c295700e9421449e86',
  fantasmaNightRun32022:
    '32da4a9b9bfe1d6f733fd87a7e5ccca0d899e3f4bc6a22f02f6d9ade094f9d75',
  corridaCaminhadaDiaInternacionalMulher2022:
    '38cb0733f58321911a6ab4441d8fbebfa2aed6a8decc405d9038340d4107ae57',
  plateiaEstudioC2022:
    'fd47f5a69eaa075e18afc524ebdbb9002e7f1f8196334cec69028919da5df2c2',
  tributoQueen2022:
    '7b429d0ac1f41d07626a6ad7ed0368f7f2a2b3c76f58d0b2475290c5191c60a8',
  direStraitsLegacy2022:
    '46f06504f6f8447b7844486ba00cafc911dab78b6406ea7179289f331ea9d21e',
  marchaJesus2022:
    'bf8ad056e6fdac6512e2516bd2679608ecc2423f51ac9b977fded6a75619c76e',
  curitibaCountryFestival2022:
    '59e80fb74a785946a308a08f1d69f51941e1f77d64d09878c739374ae709b0c6',
  curitibaCountryFestivalSorteio2022:
    'a708e373b38e839f3df443154c72f8e00788afbdeae16370aaf06ea145fedd0d',
  forcaDoAmor2022:
    '3a8d2cdb3a41de5a11d120aa48c7139d596718b43feb9a4d31515aaed2d9ec1d',
  forcaDoAmorSorteio2022:
    'e4174fba51eaa76b7b4606ea9942a0bf6de53a3be24a79ad489e804008113002',
  termoCaminhosDoCampo2022:
    '9b38d0b34808c2d469f959bc767dacb3c735b842554ff1be32e8b8715bb54375',
  termoFamiliaETudo2024:
    '0f6bd146164fc7c5b132d7c3ab89d594be66e22ed6e23659bc2b54848716b212',
  termoMeiaMaratonaClubeAlice2022:
    '58cae0f68823c57b07d3b8630076bf3f4a204074636f995aa6e83961547fde7d',
  termoShowCabare2022:
    '5a779a3a88e79dd68e1ec98bfa4d8b2950347a12f4c4169ea8627e8b6a20a21e',
  termoShowCabareSorteio2022:
    '17d1ab419f8416bc54c5e1ce8cb3f9415ba018aea05cc850645c99fa65b1d7bd',
  termoGloboEsporte2024:
    '5d253b885f1612ef239ad5ccb9cc60e28cb57e67935aabee0a00c55f848eb4f8',
  termoPlanetarioMaringa2024:
    '5ecf80f6ead0e7f1f30ca1d00c206c55a57d82be3adb3810e4501c4f6c174e58',
  termoExpoFlor2022:
    '660083b8ae928d24935a4b853dc13a9e059a217fedf6fdde2bd0ba55ed315014',
  termoMarisaMonte2022:
    '1d25408934c7121edd17bdcd91c07e9dd4a17aadaeb2a2fc115243e7b060ad98',
  termoDinhoOuroPreto2022:
    'd0b44ead03807816dae3c12dbbb3d6233ebb20b0851089e017b885cd3394f72b',
  termoTheWailersLondrina2022:
    '82dce798de8b7f7228863085c943a7b584c493dbd826b4675e99c6709cb03427',
  termoTheWailersMaringa2022:
    'd875b720288f888e9feb6265c80cf2eace22d334bbbd65e909302b4f87372eed',
  termoCorinneBailey2022:
    'dcd0bf658551426f64177e459be5215cf16cf78228c63de2e230b0091b88e201',
  termoSambaSertaoBrasa2022:
    'b6f87c7db418cd0fb2256bd76b5e42f71b1e6559676443c8ac5700399f5b871d',
  termoFestivalMiaCara2022:
    'e586adb7ac96c62612deb05ae5d11e5ed259175a4cc20f02c5c825b827c79e6b',
  termoGunsNRoses2022:
    'bb0e6a606254fca59bfe955489f8bc6d9e7895225d4bb6c48d108ebe5afa588d',
  termoCountryFestival2022:
    '274755f1bfa92eb62b4771e4d7428970c36cd205e16ff2ffbb72250e087ba7d6',
  termoBaileNegoVeio2022:
    '5d3a73fda06859ee8befc5fbca315bfb68f4d9e2b2760a4ed961356ea18aa447',
  termoSambaCuritibaSorteio2022:
    'f58f2ba64fb7138bfcdfa62001208d59cca3ecd812bfbe6a560772f514f9a7e7',
  termoVisitanteRPC:
    '0c9d3550c8aa1a3dda681a91d7fb12fe6c3159384fff307c275cce636526df52',
  termoCaminhosDoCampo2023:
    '3fbb239eb8952700274fb8c29f2c8071547fba1ec2a4cdff4bdba2f493fc835b',
  termoCaminhosDoCampo2024:
    'b8f49430f74676107a33f945a248e7defa343dfd1596b1e653d6427018704d67'
}

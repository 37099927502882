import rgMask from "./rgMask";
import cpfMask from "./cpfMask";
import telefoneMask from './telefoneMask'
import alturaMask from './alturaMask'
import dataNascimentoMask from './dataNascimentoMask'

export default {
    'rg': rgMask,
    'cpf': cpfMask,
    'telefone': telefoneMask,
    'altura': alturaMask,
    'dataNascimento': dataNascimentoMask,
}

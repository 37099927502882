import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from 'react'

import Modal from '../../../../components/Modal'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import { CurrentUserContext } from '../../../../Contexts'

import Base from './Base'

import { FirebaseContext } from '../../../../Contexts'

const TheWailers2022 = () => {
  const { firebaseFirestore: db } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [availableTicketsAmmount, setAvailableTicketsAmmount] = useState([])
  const [availableTicketsAmmountRef, setAvailableTicketsAmmountRef] = useState()
  const [userRegion, setUserRegion] = useState(undefined)

  const [formValues, setFormValues] = useState({
    termoAceito: false
  })

  const TermoTheWailers2022 = useCallback(() => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – The Wailers - A Lendária Banda de Bob Marley</strong></p>
        <p>Ao me cadastrar nesta promoção estou ciente que o código é pessoal e exclusivo para o usuário cadastrado no APP "Você na RPC", com direito único e exclusivamente ao código de inscrição via site <a target="_blank" href={`${userRegion === 'londrina' ? 'https://www.diskingressos.com.br/evento/3572/26-08-2022/pr/londrina/the-wailers-a-lendaria-banda-do-bob-marley' : 'https://www.diskingressos.com.br/evento/3615/27-08-2022/pr/maringa/the-wailers-a-lendaria-banda-do-bob-marley'}`}>Disk Ingressos</a>.</p>
        <p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
  ), [userRegion])

  const handleCheckedTermo = useCallback(() => {
    setFormValues((prev) => ({ ...prev, termoAceito: !prev.termoAceito }))
  }, [])

  const salvarOk = useMemo(() => {
    const {
      termoAceito
    } = formValues

    return !(
      termoAceito && availableTicketsAmmount > 0
    )
  }, [formValues, availableTicketsAmmount])

  useEffect(() => {
   if(currentUser) {
    const { praca } = currentUser
    setUserRegion(['LON', 'PR3'].includes(praca) ? 'londrina' : 'maringa')
   }
  }, [currentUser])

  useEffect(() => {
    const getAvailableTicketsAmmount = async () => {
      const docRef = await db
        .collection('sistema-reserva-tickets')
        .doc(`the-wailers-${userRegion}-2022`)

      const data = await (await docRef.get()).data()

      setAvailableTicketsAmmountRef(docRef)
      setAvailableTicketsAmmount(data.quantidadeTicketsDisponiveis)
    }

    if(userRegion) {
      getAvailableTicketsAmmount()
    }
  }, [userRegion])

  const termoTheWailers2022 = useCallback(() => userRegion ? `termoTheWailers${userRegion.charAt(0).toUpperCase() + userRegion.slice(1)}2022` : 'termoTheWailers2022', [userRegion])

  console.log({termo: termoTheWailers2022()})

  return (
    <Base
      description={() => (
        <div className="container">
          <div className="opcenquete align-left">
            <ToggleSwitch
              checked={formValues.termoAceito}
              setChecked={handleCheckedTermo}
              name="termoAceito"
            />
            <button
              type="button"
              className="btTransparente"
              onClick={() => {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0 // FF, GC, Opera
                document.body.style.overflowY = 'hidden'
                setMostrarTermoModal(() => true)
              }}
            >
              <span className="texto-termo">
                Declaro que li e aceito os <strong>termos de privacidade</strong> do
                <strong> show The Wailers - A Lendária Banda de Bob Marley</strong>.
              </span>
            </button>
          </div>
          <div className="texto-atencao" style={{ marginTop: '14px' }}>
            {!formValues.termoAceito && (
              <span>
                <strong>Atenção</strong>: Neste caso (
                <strong>NÃO ACEITE</strong>) não será possível sua participação
                no <strong>show The Wailers - A Lendária Banda de Bob Marley</strong>.
                <br /><br />
                Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo
                “Você na RPC”.
              </span>
            )}
          </div>

          {mostrarTermoModal && (
            <Modal
              setTermoAceito={handleCheckedTermo}
              setShowModal={setMostrarTermoModal}
            >
              <TermoTheWailers2022 />
            </Modal>
          )}
        </div>
      )}
      title="The Wailers"
      imagePath={`/imgs/cupons/banner-wailers-${userRegion}.png`}
      programName="The Wailers"
      form={formValues}
      setForm={setFormValues}
      salvarOk={salvarOk}
      tipoTermo={termoTheWailers2022()}
      availableTicketsAmmountRef={availableTicketsAmmountRef}
      db={db}
      availableTicketsAmmount={availableTicketsAmmount}
      userRegion={userRegion}
      currentUser={currentUser}
    />
  )
}

export default TheWailers2022

import { useRef, useEffect, useReducer } from 'react'

export const useCallbackStatic = callback => (
  useRef(callback).current
)

export const useCallbackRef = (callback, states) => {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [states, callback])

  return useRef((...args) => (
    callbackRef.current(...args)
  )).current
}

export const useGetterState = (state) => {
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  return useRef(() => (
    stateRef.current
  )).current
}

export const useThunkReducer = (reducer, initialArg, init = a => a) => {
  const [state, dispatch] = useReducer(reducer, initialArg, init)

  const thunkDispatch = useCallbackRef(action => (
    typeof action === 'function' ? action(state, thunkDispatch) : dispatch(action)
  ), [state])

  return [state, thunkDispatch]
}

export default {
  useCallbackRef,
  useGetterState,
  useCallbackStatic,
  useThunkReducer,
}

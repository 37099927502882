import React, { useCallback, useState } from 'react'
import { Radio, Space } from 'antd'

import InputWithLabel from '../../components/InputWithLabel'

import Base from './Base'

import { ReactComponent as AngrySVG } from '../../assets/images/angry.svg'
import { ReactComponent as SadSVG } from '../../assets/images/sad.svg'
import { ReactComponent as NeutralSVG } from '../../assets/images/neutral.svg'
import { ReactComponent as HappySVG } from '../../assets/images/happy.svg'
import { ReactComponent as LoveSVG } from '../../assets/images/love.svg'

// const characterLimitArea = 240;
const characterLimit = 50

const Avaliacao = () => {
  const [satisfacao, setSatisfacao] = useState('')
  const [comentarios, setComentarios] = useState('')
  const [nome, setNome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')

  const clearForm = useCallback(() => {
    setSatisfacao('')
    setComentarios('')
    setNome('')
    setTelefone('')
    setEmail('')
  }, [setSatisfacao, setComentarios, setNome, setTelefone, setEmail])

  const onChangeEventHandler = useCallback(
    (setter, maxLength) => (event) => {
      const value = event.target.value
      const valueLength = value.length

      if (valueLength <= maxLength) {
        setter(value)
      }
    },
    []
  )

  return (
    <Base
      title="Pesquisa de Satisfação!"
      description={(error) => (
        <div className="container">
          <div>
            <div
              id="satisfacao-required"
              className={error ? 'title error' : 'title'}
            >
              Estamos constantemente empenhados em melhorar nossos serviços. Por
              isso, gostaríamos de saber como foi o atendimento recebido.
              Participe da nossa Pesquisa de Satisfação respondendo uma única
              pergunta.🙂
            </div>

            <div className="emoji">
              <button
                className={
                  satisfacao.length !== 0
                    ? satisfacao === 'Muito insatisfeito'
                      ? 'selectedEmoji'
                      : 'notSelectedEmoji'
                    : 'emoji-svg'
                }
                onClick={() => setSatisfacao('Muito insatisfeito')}
              >
                <AngrySVG />
              </button>

              <button
                className={
                  satisfacao.length !== 0
                    ? satisfacao === 'insatisfeito'
                      ? 'selectedEmoji'
                      : 'notSelectedEmoji'
                    : 'emoji-svg'
                }
                onClick={() => setSatisfacao('insatisfeito')}
              >
                <SadSVG />
              </button>

              <button
                className={
                  satisfacao.length !== 0
                    ? satisfacao === 'Nem satisfeito, nem insatisfeito'
                      ? 'selectedEmoji'
                      : 'notSelectedEmoji'
                    : 'emoji-svg'
                }
                onClick={() =>
                  setSatisfacao('Nem satisfeito, nem insatisfeito')
                }
              >
                <NeutralSVG />
              </button>

              <button
                className={
                  satisfacao.length !== 0
                    ? satisfacao === 'Satisfeito'
                      ? 'selectedEmoji'
                      : 'notSelectedEmoji'
                    : 'emoji-svg'
                }
                onClick={() => setSatisfacao('Satisfeito')}
              >
                <HappySVG />
              </button>

              <button
                className={
                  satisfacao.length !== 0
                    ? satisfacao === 'Muito satisfeito'
                      ? 'selectedEmoji'
                      : 'notSelectedEmoji'
                    : 'emoji-svg'
                }
                onClick={() => setSatisfacao('Muito satisfeito')}
              >
                <LoveSVG />
              </button>
            </div>
          </div>

          <InputWithLabel
            title={'Deixe aqui seus comentários:'}
            inputValue={comentarios}
            className="textInputArea"
            maxInputLength="3000"
            onChange={onChangeEventHandler(setComentarios, 3000)}
            inputPlaceholder="Escreva aqui sua mensagem..."
          />

          <div className="subtitle">
            Para garantir a sua privacidade, esta pesquisa não é identificada e
            seus resultados terão uso exclusivamente interno, com o objetivo de
            melhorarmos a nossa prestação de serviço.
          </div>
        </div>
      )}
      clearForm={clearForm}
      satisfacao={satisfacao}
      comentarios={comentarios}
      nome={nome}
      telefone={telefone}
      email={email}
      imagePath="/imgs/recicla.png"
      programName="Atendimento RPC - Pesquisa de Satisfação"
      mediaID={30}
    />
  )
}

export default Avaliacao

import React, { useEffect } from 'react'

import Loading from '../../components/Loading'

const AppRedefinirSenha = () => {
  const queryParameters = new URLSearchParams(window.location.search)

  const mode = queryParameters.get("mode")
  const oobCode = queryParameters.get("oobCode")

  useEffect(() => {
    setTimeout(() => {
      window.location.href = `https://vocenarpc-239119.firebaseapp.com/__/auth/action?mode=${mode}&oobCode=${oobCode}&apiKey=AIzaSyBH16Vo5ncQqDnH2JDvPOBL81bZxQv2F-g&lang=pt-BR`
    }, 2000)
  }, [])

  return <Loading />
}

export default AppRedefinirSenha

import React, { useCallback, useState } from 'react'
import ImagePicker from '../../../components/ImagePicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'

import TermoFamiliaETudo from '../../../termosAceite/termoFamiliaETudo'

import Base from './Base'

const characterLimitArea = 240
const characterLimit = 50

const FamiliaETudo = () => {
  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState([])
  const [termoAceito, setTermoAceito] = useState(false)
  const [descricao, setDescricao] = useState('')
  const [titulo, setTitulo] = useState('')

  const handleDescricaoChange = useCallback((event) => {
    const axCount = event.target.value.length
    if (axCount <= characterLimitArea) {
      setDescricao(event.target.value)
    }
  }, [])

  const handleTituloChange = useCallback((event) => {
    const axCount = event.target.value.length
    if (axCount <= characterLimit) {
      setTitulo(event.target.value)
    }
  }, [])

  return (
    <Base
      title="Envie a sua foto para a novela Família é Tudo!"
      description={() => (
        <div>
          <MediaGallery medias={selectedMedia} />
          <ImagePicker setMedias={setSelectedMedia} />

          <div className="form">
            <InputWithLabel
              title={'Nome da Família'}
              inputValue={titulo}
              onChange={handleTituloChange}
            />
            <InputWithLabel
              title={'Descrição'}
              subTitle={'Quem está presente na foto?'}
              inputValue={descricao}
              onChange={handleDescricaoChange}
              className="textInputArea"
            />

            <div className="opcenquete align">
              <ToggleSwitch checked={termoAceito} setChecked={setTermoAceito} />
              <button
                type="button"
                className="btTransparente"
                onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  setMostrarTermoModal((_) => true)
                }}
              >
                <span>
                  Declaro que li e estou cientes das autorizações constantes no
                  Termo de Uso de Imagem
                </span>
              </button>
            </div>
            <div className="observation-termo">
              <p>
                A foto enviada poderá ou não ser veiculada nos intervalos da
                programação da RPC no período de março a dezembro de 2024. E
                estará na galeria do app Você na RPC (álbum intitulado "Família
                é Tudo"), de março a dezembro de 2024.
                <br />
                Enviando essa foto você declara que todas as pessoas presentes
                na mesma estão cientes e autorizam o envio.
              </p>
            </div>
          </div>

          {mostrarTermoModal && (
            <Modal
              setTermoAceito={setTermoAceito}
              setShowModal={setMostrarTermoModal}
            >
              <TermoFamiliaETudo />
            </Modal>
          )}
        </div>
      )}
      imagePath="/imgs/familiaetudo.png"
      programName="Família é Tudo"
      selectedMedia={selectedMedia}
      setSelectedMedia={setSelectedMedia}
      tituloFoto={titulo}
      setTituloFoto={setTitulo}
      descricaoFoto={descricao}
      setDescricaoFoto={setDescricao}
      setTermoAceito={setTermoAceito}
      salvarOk={selectedMedia.length !== 1 || !termoAceito}
      tipoTermo="familia_e_tudo"
      mediaID={10}
    />
  )
}

export default FamiliaETudo

import { checkUnfilledFields } from '../../../../../utils/helpers'
import { cpfValidator } from './cpfValidator'

export const validateRequiredFields = (formValues, openFormResponsavel) =>{
  const {
    nomeCompleto,
    cpf,
    rg,
    email,
    telefone,
    dataVisita,
    termoAceito,
    endereco,
    cidade,
    nomeCompletoPrimeiroResponsavel,
    rgPrimeiroResponsavel,
    cpfPrimeiroResponsavel,
    emailPrimeiroResponsavel,
    telefonePrimeiroResponsavel,
    enderecoPrimeiroResponsavel,
    cidadePrimeiroResponsavel,
    nomeCompletoSegundoResponsavel,
    rgSegundoResponsavel,
    cpfSegundoResponsavel,
    emailSegundoResponsavel,
    telefoneSegundoResponsavel,
    enderecoSegundoResponsavel,
    cidadeSegundoResponsavel
  } = formValues

  const isCpfUsuario = cpfValidator(cpf)
  const isCpfResponsaveis = cpfValidator(cpfPrimeiroResponsavel) && cpfValidator(cpfSegundoResponsavel)


  const requiredFields = checkUnfilledFields({
    nomeCompleto: {
      value: nomeCompleto,
      minLength: 5
    },
    cpf: {
      value: cpf,
      minLength: 11,
      maxLength: 14
    },
    rg: {
      value: rg,
      minLength: 9,
      maxLength: 12
    },
    dataVisita: {
      value: dataVisita,
      minLength: 1
    },
    email: {
      value: email,
      minLength: 5
    },
    telefone: {
      value: telefone,
      minLength: 10
    },
    /* endereco: {
      value: endereco,
      minLength: 5
    }, */
   /*  cidade: {
      value: cidade,
      minLength: 4
    } */
  })

  const requiredFieldsResponsaveis = checkUnfilledFields({
    nomePrimeiroResponsavel: {
      value: nomeCompletoPrimeiroResponsavel,
      minLength: 5
    },
    rgPrimeiroResponsavel: {
      value: rgPrimeiroResponsavel,
      minLength: 9,
      maxLength: 12
    },
    cpfPrimeiroResponsavel: {
      value: cpfPrimeiroResponsavel,
      minLength: 11,
      maxLength: 14
    },
    emailPrimeiroResponsavel: {
      value: emailPrimeiroResponsavel,
      minLength: 5
    },
    telefonePrimeiroResponsavel: {
      value: telefonePrimeiroResponsavel,
      minLength: 10
    },
    nomeSegundoResponsavel: {
      value: nomeCompletoSegundoResponsavel,
      minLength: 5
    },
    rgSegundoResponsavel: {
      value: rgSegundoResponsavel,
      minLength: 9,
      maxLength: 12
    },
    cpfSegundoResponsavel: {
      value: cpfSegundoResponsavel,
      minLength: 11,
      maxLength: 14
    },
    emailSegundoResponsavel: {
      value: emailSegundoResponsavel,
      minLength: 5
    },
    telefoneSegundoResponsavel: {
      value: telefoneSegundoResponsavel,
      minLength: 10
    },

  })

  const validatedFormResponsavel = openFormResponsavel ? (requiredFieldsResponsaveis.length === 0 && isCpfResponsaveis) : true

  return termoAceito && requiredFields.length === 0 && isCpfUsuario && validatedFormResponsavel

}

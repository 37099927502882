import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ImagePicker from '../../../components/ImagePicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'
import DropdownWithLabel from '../../../components/DropdownWithLabel'

import { TermoFamilia } from '../../../termosAceite/termoTelevisando'
import Base from './Base'
import { checkUnfilledFields } from '../../../utils/helpers';

const characterLimit = 100

const vinculos = {
    pai: 'Pai', mae: 'Mãe', avom: 'Avó', avof: 'Avô', tio: 'Tio', tia: 'Tia', irmao: 'Irmão', irma: 'Irmã', outro: 'Outro'
}

const TelevisandoFamilia = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [cidades, setCidades] = useState() 

    const [formValues, setFormValues] = useState({
        type: 'Familia',
        nomeCompletoAluno: '',
        nomeCompletoResponsavel: '',
        vinculo: '',
        cidade: '',
        escola: '',
        nomeCompletoProfessor: '',
        anoTurma: '',
        descricao: '',
        termoAceito: false,
        media: []
    })

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json())
            const cidadesObj = {}
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj[`${cidade.nome}`] = cidade.nome 
                }
            )
            setCidades(cidadesObj)
        }
        loadCidades()
    }, [])
    
    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
        
            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: value}))
            }
        }, []
    );

    const onChangeDropdownEventHandler = useCallback((attribute) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text
        setFormValues(prev => ({...prev, [`${attribute}`]: value}))
    }, [])

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    }, [])

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [])

    const salvarOk = useMemo(() => {
        const { 
            nomeCompletoAluno,
            nomeCompletoResponsavel,
            vinculo,
            cidade,
            escola,
            nomeCompletoProfessor,
            anoTurma,
            descricao,
            media
        } = formValues;

        const requiredFields = checkUnfilledFields({
            nomeCompletoAluno: { 
                value: nomeCompletoAluno,
                minLength: 0
            },
            nomeCompletoResponsavel: {
                value: nomeCompletoResponsavel,
                minLength: 0
            },
            vinculo: {
                value: vinculo,
                minLength: 0
            },
            cidade: {
                value: cidade,
                minLength: 0
            },
            escola: {
                value: escola,
                minLength: 0
            },
            nomeCompletoProfessor: {
                value: nomeCompletoProfessor,
                minLength: 0
            },
            anoTurma: {
                value: anoTurma,
                minLength: 0
            },
            descricao: {
                value: descricao,
                minLength: 100
            }
        });

        return !(
            formValues.termoAceito 
            && requiredFields.length === 0
            && media.length !== 0
        )
    }, [formValues])

    return (
        <Base
            title="Família Televisando"
            description={() => (
                <div className="container">
                    <InputWithLabel
                        title={"Nome completo do Aluno"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompletoAluno}
                        onChange={onChangeInputEventHandler('nomeCompletoAluno', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Nome completo do responsável pelo aluno"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompletoResponsavel}
                        onChange={onChangeInputEventHandler('nomeCompletoResponsavel', characterLimit)}
                    />

                    <DropdownWithLabel 
                        title="Vinculo do responsável com o aluno"
                        subTitle="Campo obrigatório"
                        values={vinculos}
                        onChange={onChangeDropdownEventHandler('vinculo')}
                    />
                    <DropdownWithLabel title="Cidade" subTitle="Campo obrigatório" values={cidades} onChange={onChangeDropdownEventHandler('cidade')}/>

                    <InputWithLabel
                        title={"Escola"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.escola}
                        onChange={onChangeInputEventHandler('escola', 200)}
                    />
                    <InputWithLabel
                        title={"Nome completo do professor do aluno"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompletoProfessor}
                        onChange={onChangeInputEventHandler('nomeCompletoProfessor', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Ano e turma do Aluno"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.anoTurma}
                        onChange={onChangeInputEventHandler('anoTurma', 20)}
                    />

                    <MediaGallery medias={formValues.media} />
                    <ImagePicker handleSetMedias={handleSetMedia} required={true}/>
                    
                    <InputWithLabel
                        title={"Agora descreva a foto respondendo a pergunta: Como a RPC faz a diferença no dia a dia da sua família?"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.descricao}
                        minInputLength={100}
                        maxInputLength={400}
                        onChange={onChangeInputEventHandler('descricao', 400)}className="textInputArea"
                        inputPlaceholder={'Descrição...'}
                    />

                    <div className="opcenquete align">
                        <ToggleSwitch checked={formValues.termoAceito}
                            setChecked={handleCheckedTermo}
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos de privacidade do Televisando
                            ​</span>
                        </button>
                    </div>

                    {
                        mostrarTermoModal &&    
                        <Modal
                            setTermoAceito={handleCheckedTermo}
                            setShowModal={setMostrarTermoModal}
                        >
                            <TermoFamilia />
                        </Modal>
                    }                   
                </div>
            )}
            imagePath="/imgs/televisando.png"
            programName="Televisando"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="televisando"
            mediaID={32}
        />
    )
}

export default TelevisandoFamilia

import React, { useContext, useCallback } from 'react';

import { CurrentUserContext } from '../Contexts'

const  Home = () => {
    const { currentUser } = useContext(CurrentUserContext)

    const renderAvatar = useCallback(() => {
        if (currentUser && currentUser.avatarUrl) {
            return (
                <div className="avatarContainer">
                    <img src={currentUser.avatarUrl} className="avatar" />
                </div>
            )
        }
        return null
    }, [currentUser])

    return (
        <div className="enqueterpc">
            <div className="container">
                <div className="titenquete textCenter">Você na RPC</div>
                {renderAvatar()}
                <div className="descenquete">
                {
                    currentUser ? 
                    <>
                        Nome: {currentUser.nome}<br/>
                        E-mail: {currentUser.email}<br/>
                        Praça: {currentUser.praca}<br/>
                        Telefone: {currentUser.telefone}<br/>
                        CPF: {currentUser.cpf}<br/>
                    </> : <>Usuário não autenticado</>
                }
                </div>
            </div>
        </div>
    );
}

export default Home;

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { Result, Descriptions } from 'antd';

import { CurrentUserContext, FirebaseContext } from '../../Contexts'

import sendUserConsent from '../../utils/sendUserConsent'

import Modal from '../../components/Modal'
import ToggleSwitch from '../../components/ToggleSwitch'
import InputWithLabel from '../../components/InputWithLabel'
import RadioButton from '../../components/RadioButton'

import getClientIPv4 from '../../utils/getPublicIPv4'
import getDeviceInfos from '../../utils/getDeviceInfos'
import getTicket from '../../utils/getTicket'
import cpfMask from '../../utils/masks/cpfMask'

import { toTitleCase, checkUnfilledFields } from '../../utils/helpers'
import removeDiacritics from '../../utils/formatters/removeDiacritics';

import { CONTEUDO_API_URL } from '../../constants/api'

const HeaderComponent = ({ bannerUrl, children }) => (
  <React.Fragment>
    <div className="enqueterpc">
      <div className="imagemdestaque">
        <img src={bannerUrl} alt="banner" />
      </div>
      {children}
    </div>
   </React.Fragment>
)

const Evento = () => {
  const { id } = useParams()

  const { firebaseFirestore: db, firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [eventData, setEventData] = useState({})
  const [userEventData, setUserEventData] = useState({})

  const [termoAceite, setTermoAceite] = useState(false)
  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [error, setError] = useState('')

  const [userTicket, setUserTicket] = useState(undefined)
  const [soldOutTicket, setSoldOutTicket] = useState(false)

  const [prizeDrawTicket, setPrizeDrawTicket] = useState(undefined)

  const [buttonText, setButtonText] = useState('Carregando...')

  // sorteio
  const [formValues, setFormValues] = useState({
      nomeCompleto: '',
      cpf: '',
      rg: '',
      email: '',
      telefone: '',
      whatsApp: undefined,
      maiorDe18: false,
  });

  useEffect(() => {
    const getEventData = async () => {
      const docRef = await db
        .collection('sistema-reserva-tickets')
        .doc(id)

      const data = await (await docRef.get()).data()

      setEventData(data)
      setButtonText(getButtonText(data))
    }

    const getButtonText = (data) => {
      const { sorteio, quantidadeTicketsDisponiveis, exibirCupom } = data
      const temTicket = quantidadeTicketsDisponiveis > 0

      if(sorteio) {
        return 'Participar'
      }

      if(!exibirCupom) {
        return temTicket ? 'Gerar desconto' : 'Descontos esgotados'
      }

      return temTicket ? 'Gerar código' : 'Códigos esgotados'
    }

    if(id) {
      getEventData()
    }
  }, [id])

  useEffect(() => {
     const getUserEventData = async () => {
      console.log('Buscando dados do usuário')
      const userDocInTickets = await db
        .collection('sistema-reserva-tickets')
        .doc(id)
        .collection('tickets')
        .where('id', '==', currentUser.uid)
        .get()


      if(userDocInTickets.docs.length === 0) {
        console.log('Usuário não possui ticket')
        setUserEventData(undefined)
        return
      }

      const data = await userDocInTickets.docs[0].data()
      setUserEventData(data)
    }

    if(currentUser && (typeof userEventData !== 'undefined' && Object.keys(userEventData).length === 0)) {
      getUserEventData()
    }
  }, [userEventData, currentUser])

  useEffect(() => {
    const { sorteio } = eventData

    if(currentUser && sorteio) {
       setFormValues(prev => ({
        ...prev,
         nomeCompleto: currentUser.nome || '',
         cpf: currentUser.cpf ? cpfMask(currentUser.cpf) : '',
         email: currentUser.email || '',
         telefone: currentUser.telefone || ''
      }))
    }
  }, [currentUser, eventData.sorteio])

  useEffect(() => {
    const startRegister = async () => {
      const { sorteio, quantidadeTicketsDisponiveis } = eventData

      if(userEventData && Object.keys(userEventData).length > 0) {
        if(sorteio) {
          const { ticket, sorteioTicket } = userEventData

          setPrizeDrawTicket(sorteioTicket)

          if(ticket > quantidadeTicketsDisponiveis) {
            setSoldOutTicket(true)
          }
        }

        setUserTicket(userEventData.ticket)
        setLoading(false)
        setFinish(true)

        return
      }

      if(sorteio) {
        await updateUserData()
      }

      await saveData()
      await saveUserTerm()
      await saveInSalesforce()
    }

    const updateUserData = async () => {
      try {
        const user = await db
          .collection('flerken-users')
          .doc(currentUser.uid)

        await user.update({
          nome: formValues.nomeCompleto,
          cpf: formValues.cpf,
          email: formValues.email,
          telefone: formValues.telefone
        })
      } catch (e) {
        console.error(e)
        setLoading(false)
        setError('Erro ao atualizar dados do usuário')
      }
    }

    const saveData = async () => {
      try {
        const docRef = await db
          .collection('sistema-reserva-tickets')
          .doc(id)

        await db.runTransaction(async transaction => {
          const doc = await transaction.get(docRef)
          const docData = doc.data()

          const { sorteio, exibirCupom, codigoTicket } = docData

          const availableTickets = docData.quantidadeTicketsDisponiveis
          const newAvailablity = availableTickets - 1

          console.log('TICKETS DISPONÍVEIS', availableTickets)
          console.log('NOVA DISPONIBILIDADE',newAvailablity)

          const currentTicketIndex = docData.ticketCorrente
          const newTicketIndex = currentTicketIndex + 1
          const soldOff = newTicketIndex > availableTickets

          let ticket = newTicketIndex

          if(exibirCupom && !sorteio) {
            ticket = getTicket(codigoTicket, +newTicketIndex)
          }

          await transaction.update(docRef, {
            quantidadeTicketsDisponiveis: sorteio ? availableTickets : newAvailablity,
            ticketCorrente: newTicketIndex
          })

          setUserTicket(ticket)

          if(sorteio && soldOff) {
            setSoldOutTicket(true)
          }

          let transactionData = {
            ticket: ticket,
            id: currentUser.uid,
            nome: currentUser.nome,
            email: currentUser.email,
            created_at: new Date()
          }

          if(sorteio) {
            const sorteioTicket = getTicket(codigoTicket, ticket)

            setPrizeDrawTicket(sorteioTicket)

            transactionData = {
              ...transactionData,
              sorteioTicket,
              nome: formValues.nomeCompleto,
              email: formValues.email,
              cpf: formValues.cpf,
              rg: formValues.rg,
              telefone: formValues.telefone,
              whatsApp: formValues.whatsApp,
              maiorDe18: formValues.maiorDe18,
            }
          }

          const ticketDocRef = docRef.collection('tickets').doc()
          await transaction.set(ticketDocRef, transactionData)
        })

        console.log('Transaction success!')
      } catch(e) {
        setError('Erro ao computar ticket')
        console.error('Transaction failure:', e)
      }
    }

    const saveUserTerm = async () => {
      try {
        const { titulo, hashPrivacy } = eventData

        const collection = await db
          .collection('flerken-interatividade')
          .doc('TERMOS_ACEITE')
          .collection('termos')

        const docId = `${currentUser.uid}-${moment().unix()}`
        const termo = `termo${toTitleCase(removeDiacritics(titulo))}`

        await collection.doc(docId).set({
          termo,
          id: docId,
          created_at: moment().toDate(),
          usr_email: currentUser.email,
          usr_id: currentUser.uid,
          usr_name: currentUser.nome,
          cpf: currentUser.cpf,
          ipV4: await getClientIPv4(),
          device_infos: getDeviceInfos()
        })

        await sendUserConsent({
          name: currentUser.nome,
          email: currentUser.email,
          origin: hashPrivacy
        })
      } catch (e) {
        console.error(e)
        setError('Falha ao salvar termo de aceite.')
      }
    }

    const saveInSalesforce = async () => {
      const { idSalesforceTelespectador } = currentUser
      const { idSalesforceEvento } = eventData

      if(!idSalesforceTelespectador || !idSalesforceEvento) {
        if(!idSalesforceTelespectador) {
          console.error('Usuário sem idSalesforceTelespectador')
        }

        if(!idSalesforceEvento) {
          console.error('Evento sem idSalesforceEvento')
        }

        setFinish(true)
        setLoading(false)

        return
      }


      try {
        const token = await firebaseAuth.currentUser.getIdToken()

        await axios.post(`${CONTEUDO_API_URL}/evento/usuario-app/${idSalesforceEvento}`, {
          viewerId: idSalesforceTelespectador,
          salesforceEventId: idSalesforceEvento
        },
        {
          headers: {
           'Authorization': `Bearer ${token}`
        }})
      } catch (e) {
        console.error(e)
      }

      setFinish(true)
      setLoading(false)
    }

    if(loading) {
      startRegister()
    }
  }, [loading])

  useEffect(() => {
    if(error.length > 0) {
      setLoading(false)
      setFinish(false)
    }
  }, [error])

  const HeaderComponentRendered = useCallback(({ children }) => (
    <HeaderComponent bannerUrl={eventData.banner || ''}>
      {children}
    </HeaderComponent>
  ), [eventData.banner])

  const MessageComponent = useCallback(({ children, title, description }) => (
    <HeaderComponentRendered>
      <div className="container">
          <div className="titenquete">
              <p className="title">{title}</p>
          </div>
          <div className="titenquete">
              <p style={{ fontWeight: 400 }}>{description}</p>
          </div>
          {children}
      </div>
    </HeaderComponentRendered>
  ), [eventData.banner]);

  const changeFormValue = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }))
  }

  const disabledButon = () => {
    const {
      sorteio,
      quantidadeTicketsDisponiveis
    } = eventData

    if(!sorteio) {
      return !(termoAceite && quantidadeTicketsDisponiveis > 0)
    }

    const {
      nomeCompleto,
      cpf,
      rg,
      email,
      telefone,
      whatsApp,
      maiorDe18,
    } = formValues

    const requiredFields = checkUnfilledFields({
      nomeCompleto: {
        value: nomeCompleto,
        minLength: 5
      },
      rg: {
        value: rg,
        minLength: 6
      },
      cpf: {
        value: cpf,
        minLength: 11
      },
      email: {
        value: email,
        minLength: 5
      },
      telefone: {
        value: telefone,
        minLength: 10
      },
    })

    return !(
      termoAceite
      && maiorDe18
      && whatsApp !== undefined
      && requiredFields.length === 0)
  };


  if(!currentUser) {
    return <HeaderComponentRendered>
      <div className="container">
        <div className="titenquete">
            <p className="title">Usuário não identificado</p>
        </div>
        <div className="titenquete">
            <p style={{ fontWeight: 400 }}>É necessário se autenticar no app para efetuar a inscrição.</p>
        </div>
      </div>
    </HeaderComponentRendered>
  }

  if(error.length > 0) {
    const { exibirCupom, sorteio } = eventData

    if(sorteio) {
      return <MessageComponent
        title="Ocorreu um erro ao enviar a sua inscrição"
        description="Por favor, tente novamente mais tarde."
      />
    }

    return (
      <HeaderComponentRendered>
        <div className="container">
          <div className="titenquete title">
            <p>Ocorreu um erro ao gerar o seu {exibirCupom ? 'código' : 'desconto'}.</p>
            <p>{error}</p>
            <p>Por favor, tente novamente.</p>
          </div>
      </div>
      </HeaderComponentRendered>
    )
  }

  if(loading) {
    const { exibirCupom, sorteio } = eventData

    if(sorteio) {
      return <MessageComponent
          title="Enviando inscrição"
          description="Por favor, não feche esta tela até o processo finalizar."
        >
          <img src="/imgs/giphy.gif" className="img-loading"/>
        </MessageComponent>
    }

     return (
      <HeaderComponentRendered>
        <div className="container">
          <div className="titenquete title">
            <p>Seu {exibirCupom ? 'código' : 'desconto'} está sendo gerado.</p>
          </div>
          <div className="titenquete content">
            <p>Por favor, não feche esta tela até o processo finalizar.</p>
          </div>
        </div>
        <img src="/imgs/giphy.gif" className="img-loading"/>
      </HeaderComponentRendered>
    )
  }

  if(finish) {
    const {
      sorteio,
      sorteioTituloGanhador,
      sorteioTituloAguardando,
      sorteioTextoGanhador,
      sorteioTextoAguardando,
      exibirCupom,
      cadastroLink,
      textoBotaoCadastroLink,
      descricao,
    } = eventData

    if(sorteio) {
      return <HeaderComponentRendered>
        <Result
          status="success"
          title={!soldOutTicket ? sorteioTituloGanhador : sorteioTituloAguardando}
          extra={<>
            <p style={{ fontSize: '16px' }}>Seu número de inscrição é:</p>
            <p style={{ fontWeight: '700', fontSize: '18px' }}>{prizeDrawTicket}</p>
            <p style={{ fontWeight: '700' }}>{!soldOutTicket ? sorteioTextoGanhador : sorteioTextoAguardando}</p>
             <Descriptions title="Dados do participante" layout='vertical' bordered size='small'>
              <Descriptions.Item label="Nome">{formValues.nomeCompleto}</Descriptions.Item>
              <Descriptions.Item label="Email">{formValues.email}</Descriptions.Item>
              <Descriptions.Item label="Telefone">{formValues.telefone}</Descriptions.Item>
              <Descriptions.Item label="Documento">{formValues.cpf}</Descriptions.Item>
            </Descriptions>
          </>}
        />
      </HeaderComponentRendered>
    }

    return (
      <HeaderComponentRendered>
         <div className="container">
          <div className="titenquete title cupom">
            {exibirCupom ? 'Código' : 'Desconto'} gerado com sucesso!
          </div>
          {exibirCupom && userTicket && <>
              <div className="titenquete content cupom">
                Seu código é
              </div>
              <div className="titenquete cupom code">
                {userTicket}
              </div>
            </>}
          <div className="titenquete aviso parceiro">
            <div dangerouslySetInnerHTML={{ __html: descricao }} />
          </div>
          {cadastroLink && <>
            <div className="btsalvar" style={{ textAlign: 'center', margin: '20px 0px 10px' }}>
              <button onClick={() => window.open(cadastroLink, '_blank')} className="btsalvar">
                {textoBotaoCadastroLink}
              </button>
            </div>
          </>}
          <p>Eventuais dúvidas, entre em contato através do email <div style={{ display: 'inline-block', textDecoration: 'underline' }}>eventos@rpc.com.br</div> e não esqueça de colocar o nome do evento no assunto.</p>
      </div>
      </HeaderComponentRendered>
    )
  }

  const {
    sorteio,
    termo,
    titulo,
    codigoSecap,
    descricaoInicial,
    textoSorteioRetirada
  } = eventData

  return (
   <HeaderComponentRendered>
    <div className="container">
      {sorteio && <>
        <InputWithLabel
          title="Nome completo"
          inputValue={formValues.nomeCompleto}
          onChange={(e) => changeFormValue('nomeCompleto', e.target.value)}
          minInputLength="6"
          required={true}
        />
        <InputWithLabel
            title="RG"
            inputValue={formValues.rg}
            onChange={(e) => changeFormValue('rg', e.target.value)}
            mask="rg"
            parser="rg"
            minInputLength='6'
            required={true}
        />
        <InputWithLabel
            title="CPF"
            inputValue={formValues.cpf}
            onChange={(e) => changeFormValue('cpf', e.target.value)}
            mask="cpf"
            parser="cpf"
            minInputLength='11'
            required={true}
        />
        <InputWithLabel
            title="E-mail"
            inputValue={formValues.email}
            onChange={(e) => changeFormValue('email', e.target.value)}
            type="email"
            required={true}
        />
        <InputWithLabel
            title="Telefone"
            inputValue={formValues.telefone}
            onChange={(e) => changeFormValue('telefone', e.target.value)}
            maxInputLength={16}
            mask='telefone'
            parser='onlyNumbers'
            required={true}
        />
        <RadioButton
          name="radio-whatsapp"
          checked={formValues.whatsApp}
          style={{ width: '100%' }}
          setChecked={(value) => changeFormValue('whatsApp', value)}
          options={[
            {
              description: "Sim",
              value: true
            },
            {
              description: "Não",
              value: false
            }
          ]}
          title="O número de telefone informado tem WhatsApp?"
        />
        <div style={{ paddingTop: '15px' }} />
        <div dangerouslySetInnerHTML={{ __html: textoSorteioRetirada }}></div>
        <div className="opcenquete align">
            <ToggleSwitch name="toggle-maior-de-18" checked={formValues.maiorDe18} style={{ width: '50px' }}
                setChecked={() => changeFormValue('maiorDe18', !formValues.maiorDe18)}
            />
            <button type="button" className="btTransparente" style={{ width: '100%' }}>
                <span>
                    <strong>Declaro que sou maior de 18 anos</strong>
                ​</span>
            </button>
        </div>
      </>}
      <div className="descenquete">
        <div className="opcenquete align">
          <ToggleSwitch name="toggle-aceito" checked={termoAceite} style={{ width: '50px' }}
              setChecked={() => setTermoAceite(prev => !prev)}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}
              onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  document.body.style.overflowY = 'hidden';
                  setMostrarTermoModal(_ => true)
              }}
          >
              <span>
                  Declaro que li e aceito os <strong>termos de privacidade</strong> do evento <strong>{titulo}</strong>
              ​</span>
          </button>
        </div>
        <div className="texto-atencao" style={{ marginTop: '14px' }}>
          {descricaoInicial && <div dangerouslySetInnerHTML={{ __html: descricaoInicial }} style={{ margin: '15px 0px' }}></div>}
          {!termoAceite && (
            <span>
              <strong>Atenção</strong>: Neste caso (
              <strong>NÃO ACEITE</strong>) não será possível sua participação
              no evento<strong> {titulo}</strong>.
              <br /><br />
              Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo
              “Você na RPC”.
            </span>
          )}
        </div>

        {mostrarTermoModal && (
          <Modal
            setTermoAceito={() => setTermoAceite(prev => !prev)}
            setShowModal={setMostrarTermoModal}
          >
            <div dangerouslySetInnerHTML={{ __html: termo }} />
          </Modal>
        )}
        </div>
      <div className="btsalvar">
          <button onClick={() => setLoading(true)} className="btsalvar" disabled={disabledButon()}>
            {buttonText}
          </button>
      </div>
      {sorteio && codigoSecap && <p style={{ textAlign: 'center', fontSize: '12px', padding: '15px 0px' }}>
        <strong>CERTIFICADO DE AUTORIZAÇÃO SEAE/ME N. {codigoSecap}</strong>
      </p>}
    </div>
   </HeaderComponentRendered>
  )
}

export default Evento

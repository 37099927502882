import React from 'react'

export const MessageComponent = ({ children, title, description }) => (
  <div className="container">
      <div className="titenquete">
          <p className="title">{title}</p>
      </div>
      <div className="titenquete">
          <p style={{ fontWeight: 400 }}>{description}</p>
      </div>
      {children}
  </div>
);

import React, { useContext, useEffect, useMemo, useState } from 'react'
import Modal from '../../../../components/Modal'
import ToggleSwitch from '../../../../components/ToggleSwitch'

import TermoPlanetarioMaringa2024 from '../../../../termosAceite/termoPlanetarioMaringa2024'

import Base from './Base'
import { CurrentUserContext, FirebaseContext } from '../../../../Contexts'

const PlanetarioMaringa2024 = () => {
  const { currentUser } = useContext(CurrentUserContext)
  const { firebaseFirestore: db } = useContext(FirebaseContext)

  const [dataTickets, setDataTickets] = useState([])
  const [loadingTickets, setLoadingTickets] = useState(false)

  const availableTicketsCollections = [
    {
      day: 15,
      data: [
        {
          date: '15/04/2024',
          collection: '15-04-1930',
          hour: '19:30'
        },
        {
          date: '15/04/2024',
          collection: '15-04-2030',
          hour: '20:30'
        }
      ]
    },
    {
      day: 16,
      data: [
        {
          date: '16/04/2024',
          collection: '16-04-1930',
          hour: '19:30'
        },
        {
          date: '16/04/2024',
          collection: '16-04-2030',
          hour: '20:30'
        }
      ]
    },
    {
      day: 17,
      data: [
        {
          date: '17/04/2024',
          collection: '17-04-1930',
          hour: '19:30'
        },
        {
          date: '17/04/2024',
          collection: '17-04-2030',
          hour: '20:30'
        }
      ]
    },
    {
      day: 18,
      data: [
        {
          date: '18/04/2024',
          collection: '18-04-1930',
          hour: '19:30'
        },
        {
          date: '18/04/2024',
          collection: '18-04-2030',
          hour: '20:30'
        }
      ]
    },
    {
      day: 19,
      data: [
        {
          date: '19/04/2024',
          collection: '19-04-1930',
          hour: '19:30'
        },
        {
          date: '19/04/2024',
          collection: '19-04-2030',
          hour: '20:30'
        }
      ]
    }
  ]

  useEffect(() => {
    const getAvailableTicketsAmmount = async () => {
      try {
        setLoadingTickets(true)

        const newDataTickets = []

        for (const item of availableTicketsCollections) {
          const dataArray = []

          for (const dataCollection of item.data) {
            const docRef = db
              .collection('sistema-reserva-tickets')
              .doc(
                `visita-ao-planetario-de-maringa-${dataCollection.collection}-2024`
              )

            const docSnapshot = await docRef.get()
            const data = docSnapshot.data()

            dataArray.push({
              day: item.day,
              date: dataCollection.date,
              hour: dataCollection.hour,
              availableTicketsAmmount: data.quantidadeTicketsDisponiveis,
              availableTicketsAmmountRef: docRef
            })
          }

          const dataTicket = {
            day: item.day,
            data: dataArray
          }

          newDataTickets.push(dataTicket)
        }

        setDataTickets(newDataTickets)
      } catch (error) {
        console.error('Error fetching available tickets:', error)
      }
    }

    getAvailableTicketsAmmount()
  }, [])

  return (
    <Base
      description={() => (
        <div>
          <h2 className="subtitle">ESCOLHA O DIA E O HORÁRIO</h2>
        </div>
      )}
      imagePath="/imgs/planetario-maringa-2024.png"
      programName="Planetário Maringá 2024"
      tipoTermo="termoPlanetarioMaringa2024"
      dataTickets={dataTickets}
      loadingTickets={loadingTickets}
      setLoadingTickets={setLoadingTickets}
      db={db}
    />
  )
}

export default PlanetarioMaringa2024

import dayjs from 'dayjs';
import { availableDates, especialDate, initialDate } from '../../constants';
import isNotBlockedDate from './isNotBlockedDate';

const getDate = ( blockedDate) => {
  const date = [initialDate]

  while(date.length <= availableDates ){

    if(date[date.length -1] > dayjs() && isNotBlockedDate(blockedDate, date[date.length -1])){
      date.push(parseInt(date[date.length-1].format("DD")) > 25 ?
      date[date.length-1].add(7, "day") : date[date.length-1].add(14, "day"))
    }else{
      date[date.length -1] = date[date.length -1].add(14, "day")
    }

  }

  const addNewDate = especialDate.map(d => dayjs(d) > dayjs() && isNotBlockedDate(blockedDate, d) &&  dayjs(d))

  date.push(...addNewDate)
  date.sort((a, b) => a.format("YYYY-MM-DD").localeCompare(b.format("YYYY-MM-DD")))

  return date.filter((date) => (
      date > dayjs()
      && parseInt(date.format("DD")) < 25 && isNotBlockedDate(blockedDate, date)
    )
  )
}

export default getDate

import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'

const buttonStyles = {
  margin: '10px auto',
  textAlign: 'center'
}

const storeUrls = {
  android:
    'https://play.google.com/store/apps/details?id=br.com.rpc.vocenarpc&hl=pt_BR',
  ios: 'https://apps.apple.com/br/app/voc%C3%AA-na-rpc/id969200161'
}

const storeLogos = {
  android: '/imgs/playstore.png',
  ios: '/imgs/appstore.png'
}

const mobileOs = {
  android: 'android',
  ios: 'ios'
}

// eslint-disable-next-line react/prop-types
const StoreLinksComponent = ({ os }) => (
  <div style={buttonStyles}>
    <a href={storeUrls[os]}>
      <img width="200" src={storeLogos[os]} />
    </a>
  </div>
)

const AppStoreUpdate = () => {
  const os = useMemo(() => (isAndroid ? mobileOs.android : mobileOs.ios), [])

  useEffect(() => {
    window.location.replace(storeUrls[os])

    setTimeout(() => (window.location.href = storeUrls[os]), 1000)
  }, [os])

  return (
    <>
      {isAndroid || isIOS ? (
        <StoreLinksComponent os={os} />
      ) : (
        <>
          <StoreLinksComponent os={mobileOs.ios} />
          <StoreLinksComponent os={mobileOs.android} />
        </>
      )}
    </>
  )
}

export default AppStoreUpdate

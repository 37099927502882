import React from 'react'

export const CurrentUserContext = React.createContext()
export const FirebaseContext = React.createContext()
export const ParamsContext = React.createContext()
export const UploadContext = React.createContext()

export default {
  CurrentUserContext,
  FirebaseContext,
  ParamsContext,
  UploadContext
}

import React from 'react'

import { useCallbackStatic } from '../../hooks/utils'

import Base from './Base'

const  BoaNoitePr = () => {

    const goToMidias = useCallbackStatic(() => {
        const message = {type: 'navigate', url: 'vocenarpc://enviodemidia/1', local: 'site', version: 2.0}
        console.log(message)
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(message)); 
        }
    })

    return (
        <Base
            title="Envie a sua mensagem para o Boa Noite Paraná!"
            description={() => (
                <span>Se quiser mandar uma foto ou vídeo <a onClick={goToMidias}>clique aqui</a></span>
            )}
            imagePath="/imgs/boanoitepr.png"
            programName="Boa Noite Paraná"
        />
    )
}

export default BoaNoitePr

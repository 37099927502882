import React, { useCallback, useMemo } from 'react'
import { Divider } from 'antd'

import parsers from '../../../../utils/parsers'
import InputWithLabel from '../../../../components/InputWithLabel'
import RadioButton from '../../../../components/RadioButton'

import { checkUnfilledFields } from '../../../../utils/helpers'

const characterLimit = 2000

const InscricaoReceita = (props) => {
  const [formValues, setFormValues] = props.form
  const { setCurrentStep } = props

  const isNextButtonDisabled = useMemo(() => {
    const {
        receitaNome,
        receitaCategoria,
        receitaIngredientes,
        receitaInspiracao,
        receitaModoPreparo
    } = formValues;

    const requiredFields = checkUnfilledFields({
        receitaNome: {
            value: receitaNome,
            minLength: 0
        },
        receitaCategoria: {
            value: receitaCategoria,
            minLength: 0
        },
        receitaIngredientes: {
            value: receitaIngredientes,
            minLength: 0
        },
        receitaInspiracao: {
            value: receitaInspiracao,
            minLength: 0
        },
        receitaModoPreparo: {
            value: receitaModoPreparo,
            minLength: 0
        }
    });

    return !(requiredFields.length === 0)
  }, [formValues])

  const onChangeInputEventHandler = useCallback(
    (attribute, maxLength) => (event) => {
        const value = event.target.value;
        const valueLength = value.length;
        const parseType = event.target.dataset.parser
        const parsedValue = parseType ? parsers[parseType](value) : value
        if (valueLength <= maxLength) {
            setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
        }
    }, []
  );

  return <>
     <p style={{ margin: 0 }}>Inscrição da receita</p>
     <Divider style={{ margin: '0px 0px 10px' }} />
     <InputWithLabel
      title={"Qual o nome da receita?"}
      subTitle="Campo obrigatório"
      inputValue={formValues.receitaNome}
      onChange={onChangeInputEventHandler('receitaNome', 255)}
      required
    />
    <RadioButton
      name="receita-categoria"
      horizontal={true}
      checked={formValues.receitaCategoria}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px'
      }}
      setChecked={(value) => setFormValues(prev => ({ ...prev, receitaCategoria: value }))}
      options={[
        {
          description: "Doces",
          value: 'doce'
        },
        {
          description: "Salgados",
          value: 'salgado'
        }
      ]}
      title="Categoria"
    />
    <InputWithLabel
      className='textInputArea'
      title={"Quais são os ingredientes?"}
      subTitle="Campo obrigatório"
      inputValue={formValues.receitaIngredientes}
      onChange={onChangeInputEventHandler('receitaIngredientes', characterLimit)}
      required
    />
    <InputWithLabel
      className='textInputArea'
      title={"Como é o modo de preparo?"}
      subTitle="Campo obrigatório"
      inputValue={formValues.receitaModoPreparo}
      onChange={onChangeInputEventHandler('receitaModoPreparo', characterLimit)}
      required
    />
    <InputWithLabel
      className='textInputArea'
      title={"Qual a inspiração para a receita?"}
      subTitle="Campo obrigatório"
      inputValue={formValues.receitaInspiracao}
      onChange={onChangeInputEventHandler('receitaInspiracao', characterLimit)}
      required
    />

     <div className="btsalvar" style={{ marginTop: '20px' }}>
        <button onClick={() => setCurrentStep(prev => prev + 1)} className="btsalvar button-caminhos-do-campo" disabled={isNextButtonDisabled}>
            Próximo
        </button>
    </div>
  </>
}

export default InscricaoReceita

import React from 'react'

export const HeaderComponent = ({ children }) => {
  return(
      <div className="enqueterpc">
        <div className="imagemdestaque">
            <img src="/imgs/banner_programa_visitas.png" alt="banner"/>
        </div>
        {children}
      </div>
    )
};

import React from 'react';

import { Divider } from 'antd';

import InputWithLabel from '../../../components/InputWithLabel';
import DropdownWithLabel from '../../../components/DropdownWithLabel';
import Back from '../../../components/Back';

const characterLimit = 100;

const Endereco = ({ 
    formValues,
    onChangeStep,
    onChangeInputEventHandler,
    onChangeDropdownEventHandler,
    cidades
}) => {
    return <>
        <Back onClick={() => onChangeStep(prev => prev - 1)} />
        <Divider orientation="left">Os dados abaixo estão corretos?</Divider>
        <InputWithLabel
            title="Bairro"
            inputPlaceholder="Escreva aqui o seu BAIRRO"
            inputValue={formValues.bairro}
            onChange={onChangeInputEventHandler('bairro', characterLimit)}
        />
        <DropdownWithLabel 
            title="Cidade"
            values={cidades.map(({ nome }) => nome)}
            defaultValue={!!formValues.cidade.nome ? formValues.cidade.key : 'default'}
            onChange={onChangeDropdownEventHandler('cidade', cidades)}
        />
        <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button onClick={() => onChangeStep(prev => prev + 1)} className="btsalvar">
                Avançar
            </button>
        </div>
    </>;
};

export default Endereco;
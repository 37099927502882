import React, { useEffect, useState } from 'react';

import { Typography, Alert } from 'antd';

import InputWithLabel from '../../../components/InputWithLabel';
import DropdownWithLabel from '../../../components/DropdownWithLabel';

import { API_KEY } from '../../../constants/geocode';

const characterLimit = 100;

const { Text } = Typography;

const Cep = ({
    formValues,
    onChangeFormValues,
    onChangeInputEventHandler,
    onChangeDropdownEventHandler,
    onChangeStep,
    cidades
}) => {
    const [error, setError] = useState({
        api:        '',
        address:    ''
    });

    const compareCity = (city1, city2) => {
        const a = city1
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\ /g, '')
            .toLowerCase()
            .trim();
            
        const b = city2
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\ /g, '')
            .toLowerCase()
            .trim();

        return a === b;
    };

    const getData = async (address) => {
        setError({ address: '', api: '' });
        try {
            const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`)
                .then(res => res.json());

            let addressData = {
                bairro: '',
                cidade: '',
                cep: ''
            };

            const { results } = data;

            if(results.length === 0) {
                setError(prev => ({
                    ...prev,
                    address: `Endereço não encontrado. Por favor, digite corretamente o seu ${naoSeiCep ? 'Endereço' : 'CEP'}.`,
                }));
                return;
            }

            const { address_components } = results[0];

            address_components.map(component => {
                const { types, long_name } = component;

                if(types.includes('postal_code')) {
                    addressData = { ...addressData, cep: long_name };
                }

                if(types.includes('sublocality_level_1')) {
                    addressData = { ...addressData, bairro: long_name };
                }

                if(types.includes('administrative_area_level_2')) {
                    addressData = { ...addressData, cidade: long_name };
                }
            });

            const cidade = cidades.find(({ nome }) => compareCity(nome, addressData.cidade));

            if(!cidade) {
                setError(prev => ({
                    ...prev,
                    address: 'Desculpe, essa pesquisa é apenas para os telespectadores do Estado do Paraná.',
                }));
                return;
            }

            onChangeFormValues({
                ...formValues,
                bairro: !!addressData.bairro ? addressData.bairro : '',
                cep: !!addressData.cep ? addressData.cep.replace(/[^\d]/g, '') : formValues.cep,
                cidade
            });
        } catch(e) {
            setError(prev => ({
                ...prev,
                api: 'Houve um problema ao consultar o CEP informado. Por favor, tente mais tarde.',
            }));
            console.error('Erro ao obter o endereço:', e);
        }
    };

    const onChangeAddress = () => {
        const { rua, numero, cidade } = formValues;

        if(rua.length >= 5 && numero.length > 0 && !!cidade.nome) {
            getData(`${rua} ${numero} ${cidade.nome}`);
        }
    };

    useEffect(() => {
        const { cep } = formValues;

        if(cep.length === 8) {
            getData(cep);
        }
        
    }, [formValues.cep]);

    const onChangeCep = event => {
        const { value } = event.target;
        onChangeFormValues({ ...formValues, cep: value.replace(/\D/, '') });
    };

    const { naoSeiCep } = formValues;

    const buttonDisabled = formValues.bairro.length === 0
        || error.address.length > 0
        || error.api.length > 0;

    return <>
        {!!error.address && <Alert message={error.address} type="warning" showIcon closable />}
        {!!error.api && <Alert message={error.api} type="error" showIcon closable />}
        <div style={{ paddingBottom: '10px' }}>
            <Text style={{ fontSize: '16px' }}>Antes de responder a pesquisa de sinal, precisamos saber a sua localização.</Text>
        </div>
        {naoSeiCep ? <>
            <InputWithLabel
                title="Nome da rua"
                inputValue={formValues.rua}
                inputPlaceholder="Escreva o nome da sua rua aqui..."
                onChange={onChangeInputEventHandler('rua', characterLimit)}
                onBlur={onChangeAddress}
            />
            <InputWithLabel
                title="Número"
                inputPlaceholder="Escreva o seu número aqui..."
                inputValue={formValues.numero}
                onChange={onChangeInputEventHandler('numero', characterLimit)}
                onBlur={onChangeAddress}
            />
            <DropdownWithLabel 
                title="Cidade"
                values={cidades.map(({ nome }) => nome)}
                defaultValue={!!formValues.cidade.nome ? formValues.cidade.key : 'default'}
                onChange={onChangeDropdownEventHandler('cidade', cidades)}
                onBlur={onChangeAddress}
            />
        </> : <>
            <InputWithLabel
                title="Digite aqui seu CEP"
                inputValue={formValues.cep}
                inputPlaceholder="Escreva o seu CEP aqui..."
                onChange={onChangeCep}
                maxInputLength={8}
            />
        </>}
        {naoSeiCep ? <Text onClick={() => onChangeFormValues(prev => ({ ...prev, naoSeiCep: !naoSeiCep }))}>
            Sabe o seu CEP? Clique aqui
        </Text> : <Text onClick={() => onChangeFormValues(prev => ({ ...prev, naoSeiCep: !naoSeiCep }))}>
            Não sabe o seu CEP? Clique aqui
        </Text>}
        <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button onClick={() => onChangeStep(prev => prev + 1)} className="btsalvar" disabled={buttonDisabled}>
                Avançar
            </button>
        </div>
    </>;
};

export default Cep;
import dayjs from 'dayjs';

export const visitanteRPCCollection = '/especiais/visitantes-rpc/visitantes';
export const termoVisitanteRPC = 'termoVisitanteRPC';
export const maxVisitantes = 15
export const dateBlackList = [];
export const initialDate = dayjs(dayjs().year()).day(5); //Primeira sexta do ano
export const especialDate = [];
export const availableDates = 4;
export const blockedBeforeDays = 3

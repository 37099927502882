import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
import dayjs from 'dayjs'
import { Checkbox, Divider, message, Result } from 'antd';

import ToggleSwitch from '../../../components/ToggleSwitch';
import InputWithLabel from '../../../components/InputWithLabel';
import DropdownWithLabel from '../../../components/DropdownWithLabel';
import DatePickerWithLabel from '../../../components/DatePickerWithLabel';
import Modal from '../../../components/Modal';
import TermoVisitanteRPC from '../../../termosAceite/termoVisitanteRPC';
import cpfMask from '../../../utils/masks/cpfMask';

import { FirebaseContext, CurrentUserContext } from '../../../Contexts';

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getPraca from '../../../utils/getPraca'
import getDeviceInfos from '../../../utils/getDeviceInfos'

import { checkUnfilledFields } from '../../../utils/helpers';

import sendUserConsent from '../../../utils/sendUserConsent';
import originConsents from '../../../constants/originConsents'
import { MessageComponent } from './components/MessageComponent';
import { HeaderComponent } from './components/HeaderComponent';
import { visitanteRPCCollection, termoVisitanteRPC, dateBlackList } from './constants'
import { FinishComponent } from './components/FinishComponent';
import { FormCadastro } from './components/FormCadastro';
import {
  getDate,
  userRegister,
  validatedDate,
  validatedUser,
  getNationalHoliday,
  validateRequiredFields,
  handleFilterDate,
  firebaseMessageUpload
} from './utils';

const ProgramaVisitas = () => {
  const { firebaseFirestore } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [error, setError] = useState(false)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false);
  const [horario, setHorario] = useState('')
  const [blockedDate, setBlockedDate] = useState([...dateBlackList])
  const [user, setUser] = useState({})
  const [openFormResponsavel, setOpenFormResponsavel] = useState(false)

  const [formValues, setFormValues] = useState({
      nomeCompleto: '',
      cpf: '',
      rg: '',
      email: '',
      telefone: '',
      endereco:'',
      cidade: 'Paraná',
      estado: 'Paraná',
      termoAceito: false,
      dataVisita: '',
      visitaConfirmada: false,
      nomeCompletoPrimeiroResponsavel: '',
      rgPrimeiroResponsavel: "",
      cpfPrimeiroResponsavel: "",
      emailPrimeiroResponsavel: "",
      telefonePrimeiroResponsavel: "",
      enderecoPrimeiroResponsavel: 'Paraná',
      cidadePrimeiroResponsavel: 'Paraná',
      estadoPrimeiroResponsavel: 'Paraná',
      nomeCompletoSegundoResponsavel: '',
      rgSegundoResponsavel: "",
      cpfSegundoResponsavel: "",
      emailSegundoResponsavel: "",
      telefoneSegundoResponsavel: "",
      enderecoSegundoResponsavel: 'Paraná',
      cidadeSegundoResponsavel: 'Paraná',
      estadoSegundoResponsavel: 'Paraná',
  });

  const disabledButton = useMemo(() => {
      return !validateRequiredFields(formValues, openFormResponsavel)
  }, [formValues, openFormResponsavel ]);

  const handleGetHoliday = useCallback(async () => {
    const invalidDate = await getNationalHoliday()

    if(invalidDate && invalidDate.length > 0){
      setBlockedDate(rest => [...rest, ...invalidDate])
    }
  })

  useEffect(() => {
    const userExistsInDB = async () => {
      const registerInDB = await userRegister(firebaseFirestore, currentUser)
      const registerInDBExists = registerInDB.docs.filter(doc => (
        dayjs(doc.data().formularioDataVisita.seconds*1000).format("YYYY-MM-DD") === dayjs(formValues.dataVisita).format("YYYY-MM-DD")
      ))
      if(registerInDBExists.length > 0) {
        setUser(registerInDBExists[0].data())
        setLoading(false)
        setFinish(true)
      }
      else {
        firestoreStartUpload(registerInDB)
      }
    }

    const firestoreStartUpload = async (registerInDB) => {
      const { firebaseMensagem, firebaseMensagemTermo } = await firebaseMessageUpload(formValues, currentUser)

      setUser(firebaseMensagem)

      const visitanteRPC = firebaseFirestore
        .collection(visitanteRPCCollection)

      const visitanteRPCTermo = firebaseFirestore
        .collection('flerken-interatividade')
        .doc('TERMOS_ACEITE')
        .collection('termos')

      try {
        const date = dayjs(firebaseMensagem.formularioDataVisita.seconds*1000)
        if(blockedDate.includes(date.format("YYYY-MM-DD"))){
          message.open({
            type: 'error',
            content: `Vagas esgotadas para a data ${date.format("DD/MM/YYYY")}. Por favor, selecione outra data`
          })
          return
        }
        await visitanteRPCTermo.doc(firebaseMensagemTermo.id).set(firebaseMensagemTermo)
        await visitanteRPC.doc(firebaseMensagem.id).set(firebaseMensagem)
        console.log('Dados do termo enviados com sucesso para o firestore')

        if(registerInDB.docs.length === 0){
          await sendUserConsent({
            name: formValues.nomeCompleto,
            email: formValues.email,
            origin: originConsents.termoVisitanteRPC
          })
        }

        setFinish(true)
      } catch (err) {
          console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
          setError(true)
      }

      setLoading(false)
    }

    if(loading) {
      userExistsInDB()
    }
  }, [loading])

  useEffect(() => {
    handleGetHoliday()
    if(currentUser) {
     setFormValues(prev => ({
        ...prev,
        nomeCompleto: currentUser.nome || '',
        cpf: currentUser.cpf ? cpfMask(currentUser.cpf) : '',
        email: currentUser.email || '',
        telefone: currentUser.telefone || '',
        endereco: currentUser.bairroEndereco || '',
        cidade: currentUser.cidadeEndereco.nome || ''
     }))
    }
    validatedUser(firebaseFirestore, setLoading, setFinish, currentUser, setUser)
    validatedDate(firebaseFirestore, setBlockedDate)
  }, [currentUser])

  const date = getDate(blockedDate)

  if(!currentUser) {
    return <MessageComponent
      title="Usuário não identificado"
      description="É necessário se autenticar no app para efetuar a inscrição."
    />
  }

  if(error) {
    return <MessageComponent
      title="Ocorreu um erro ao enviar a sua inscrição"
      description="Por favor, tente novamente mais tarde."
    />
  }

  if (loading) {
    return <MessageComponent
      title="Enviando inscrição"
      description="Por favor, não feche esta tela até o processo finalizar."
    >
      <img src="/imgs/giphy.gif" className="img-loading"/>
    </MessageComponent>
  }

  if (finish) {
    return <HeaderComponent>
      <Result
        status={user.visitaConfirmada ? "success" : "info"}
        title={user.visitaConfirmada ? "Visita confirmada 😊" : "Inscrição feita 😊"}
        extra={<FinishComponent user={user} formValues={formValues}/>}
      />
    </HeaderComponent>
  }

  const handleCheckedTermo = (termo) => {
      setFormValues(prev => ({...prev, [termo]: !prev[termo]}))
  }

  const changeFormValue = (field, value) => {
    setFormValues(prev => ({ ...prev, [field]: value }))
  }

  const dateFiltered = handleFilterDate(date, horario)

  const onChangeDropdownEventHandler = (event) => {
    const {options, selectedIndex} = event.target
    const value = options[selectedIndex].text
    changeFormValue('dataVisita', value.split(" ")[0].split("/").reverse().join("/"))
  }

  return <HeaderComponent>
    <div className="container">
      <p>
        TEXTO DESCRITIVO
      </p>
      <Divider />

      <FormCadastro formValues={formValues} changeFormValue={changeFormValue}/>

      <div style={{marginBottom: '10px'}}>
        <label htmlFor="" style={{display: "block"}} className="title">Horários</label>
        <Checkbox checked={horario === "manha" && true} onChange={() => setHorario(s => s !== "manha" ? "manha" : "")}>10:00</Checkbox>
        <Checkbox checked={horario === "tarde" && true} onChange={() => setHorario(s => s !== "tarde" ? "tarde" : "")}>14:00</Checkbox>
      </div>
      <DropdownWithLabel
        title="Data de visita"
        values={dateFiltered}
        style={{ borderRadius: '25px' }}
        onChange={onChangeDropdownEventHandler}
        required
      />

      <div style={{ paddingTop: '10px' }}>
        <p>* As visitas serão confirmadas por email.</p>
      </div>
      <Divider />

      <div className="opcenquete align" style={{display: 'flex', alignItems: "center"}}>
        <ToggleSwitch
          name="toggle-menor-idade"
          style={{ width: '50px' }}
          checked={openFormResponsavel}
          setChecked={setOpenFormResponsavel}
        />
        <button
          type="button"
          className="btTransparente"
          style={{ width: '100%', height: '100%', margin: "auto 0" }}
          onClick={() => setOpenFormResponsavel(!openFormResponsavel)}
        >
          <span>
            <strong>Declaro que sou menor de 18 anos</strong>
          ​</span>
        </button>
      </div>

      {openFormResponsavel && (
        <>
         <div style={{ padding: '10px 0' }}>
          <span><strong>DADOS DOS PAIS OU RESPONSÁVEL LEGAL (CEDENTE)</strong></span>
        </div>
          <FormCadastro formValues={formValues} changeFormValue={changeFormValue} responsavel="PrimeiroResponsavel"/>
          <Divider />
          <FormCadastro formValues={formValues} changeFormValue={changeFormValue} responsavel="SegundoResponsavel"/>
        </>
      )}

      <div className="opcenquete align">
          <ToggleSwitch name="toggle-aceito" checked={formValues.termoAceito} style={{ width: '50px' }}
              setChecked={() => handleCheckedTermo('termoAceito')}
          />
          <button type="button" className="btTransparente" style={{ width: '100%' }}
              onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  document.body.style.overflowY = 'hidden';
                  setMostrarTermoModal(_ => true)
              }}
          >
            <span>
                <strong>Declaro que li e aceito os termos de uso</strong>
            ​</span>
          </button>
      </div>

      {
          mostrarTermoModal &&
          <Modal
              setTermoAceito={() => handleCheckedTermo('termoAceito')}
              setShowModal={setMostrarTermoModal}
          >
              <TermoVisitanteRPC />
          </Modal>
      }
      <div className="btsalvar" style={{ paddingTop: '20px' }}>
          <button onClick={() => setLoading(true)} className="btsalvar" disabled={disabledButton}>
              Enviar solicitação de participação
          </button>
      </div>
    </div>
  </HeaderComponent>;
};

export default ProgramaVisitas

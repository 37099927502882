import React from 'react'

const TermoCaminhosDoCampo = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO - Concurso CAMINHOS DO CAMPO 2023</strong></p>
        <p>Ao enviar meus dados e clicar no aceite abaixo, estou de acordo com os termos/condições acima, bem como com os termos do REGULAMENTO do concurso CAMINHOS DO CAMPO 2023 (<a href="https://s3.amazonaws.com/interatividade.vocenarpc.com.br/regulamentos/caminhos_do_campo/2023/regulamento.pdf" target="_blank">Regulamento</a>) das Políticas de Privacidade e Proteção de Dados da GLOBO (<a href="https://privacidade.globo.com/privacy-policy" target="_blank">privacidade.globo.com/privacy-policy</a>) e da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">negociosrpc.com.br/politica-de-privacidade-rpc</a>), e aceito receber informações sobre o concurso CAMINHOS DO CAMPO 2023, bem como de outros projetos, cursos e/ou outras ações promovidas e apoiadas pela RPC a partir da presente data em meu e-mail e telefone.</p>
        <p></p>
        <p><strong>( ) Aceito</strong> os termos do Termo de Aceite acima.</p>
        <p><strong>( ) NÃO ACEITO</strong> os termos do Termo de Aceite acima.<br /><strong>Atenção:</strong> Neste caso (NÃO ACEITE) não será possível sua participação no concurso CAMINHOS DO CAMPO 2023. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.</p>
    </>
)

export default TermoCaminhosDoCampo;

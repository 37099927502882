import React from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({url, onProgress, onStart, onEnded, controls=true, autoPlay=false}) => {
    return (
        <div className='player-wrapper'>
          <ReactPlayer
            id="videoID"
            className='react-player'
            url={url}
            width='100%'
            height='100%'
            controls={controls}
            volume={1}
            muted={true}
            playing={autoPlay}
            onProgress={onProgress}
            onStart={onStart}
            onEnded={onEnded}
            pip={false}
            config={{
                file:{
                    attributes: {
                        controlsList: "nodownload",
                    },
                    controls,
                    preload: 'metadata',
                }
            }}
          />
        </div>
    )
}

export default VideoPlayer
import React, { useCallback, useEffect, useState } from 'react';

import { Steps } from 'antd';

import Cep from './cep';
import Endereco from './endereco';
import Pesquisa from './pesquisa';
import Final from './final';

const { Step: Progress } = Steps;

const Step = {
    0: Cep,
    1: Endereco,
    2: Pesquisa,
    3: Final
};

const Bairro = () => {
    const [currentStep, setStep] = useState(0);
    const [cidades, setCidades] = useState([]);

    const [formValues, setFormValues] = useState({
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: {
            key: 0,
            nome: '',
            ibge: '',
            praca: ''
        },
        origemDaMidia: '',
        avaliacaoDaQualidade: 5,
        termoAceite: false,
        naoSeiCep: false,
        observacaoDaPesquisa: ''
    });

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength = 0) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;

            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: value}))
            }
        }, []
    );

    const onChangeDropdownEventHandler = useCallback((attribute, cities) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text

        const cidade = cities.find(({ nome }) => nome === value);

        setFormValues(prev => ({...prev, cidade }))
    }, []);

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json());
            const cidadesObj = [];
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj.push({
                        key,
                        nome: cidade.nome,
                        ibge: cidade.id_ibge,
                        praca: cidade.praca
                    });
                }
            );
            setCidades(cidadesObj);
        }
        loadCidades();
    }, []);

    const Component = Step[currentStep];

    return <div className="enqueterpc">
            <div className="imagemdestaque">
                <img src="/imgs/pesquisa-sinal_app.png" alt="banner"/>
            </div>
            <div className="container">
                {currentStep < 3 && <Steps size="small" current={currentStep} style={{ paddingBottom: '25px' }}>
                    {Object.entries(Step).map((step, key) => key < 3 && <Progress />)}
                </Steps>}
                <Component
                    currentStep={currentStep}
                    onChangeStep={setStep}
                    onChangeInputEventHandler={onChangeInputEventHandler}
                    onChangeDropdownEventHandler={onChangeDropdownEventHandler}
                    formValues={formValues}
                    onChangeFormValues={setFormValues}
                    cidades={cidades}
                />
            </div>
    </div>
};

export default Bairro

import React from 'react'

const MediaGallery = ({medias}) => {
    return (
        <div className="center margin">
            {medias.map(({src, name, mediaType="photo"}) => {
                return (
                <div key={src}>
                {
                    mediaType === "photo" ? 
                    <img key={src} className="img" src={src} alt="mídia selecionada"/>
                    :
                    // <video key={src} className="video" id="video" src={`${src}#t=0.2`} preload="metadata" 
                    // />
                   <span>{`Vídeo selecionado: ${name.replace(/^([a-z0-9_\-]+).*$/i, '$1')}`}</span>
                }
                </div>
            )})}
        </div>
    )
}

export default MediaGallery;
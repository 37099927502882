import React from 'react';
import dayjs from 'dayjs'

export const FinishComponent = ({user, formValues}) =>{
  return user.visitaConfirmada ?
          <>
            <p style={{ fontWeight: '700' }}>Enviamos mais informações sobre a sua visita aos estúdios da RPC para o seu endereço de e-mail: {user.formularioEmail}.</p>
            <p><strong>Data da visita:</strong> {dayjs(user.formularioDataVisita.seconds*1000).format("DD/MM/YYYY")}</p>
          </>
        :
          <>
            <p style={{ fontWeight: '700' }}>Em breve entraremos em contato para confirmar sua visita através do seu endereço de e-mail: {user.formularioEmail}.</p>
            <p><strong>Data desejada para visita:</strong> {dayjs(formValues.dataVisita).format("DD/MM/YYYY")}</p>

            <p>Aguarde mais informações sobre a sua visita aos estúdios da RPC. Até mais!</p>
          </>
}

const getPraca = async (currentUser) => {
    if (currentUser.cidadeEndereco) {
        if (currentUser.cidadeEndereco.outroEstado) {
            return 'PRE'
        }
        if (currentUser.cidadeEndereco.idIbge) {
            const cidades = await fetch('/cidades.json').then(res => res.json())
            const cidade = cidades.find(cidade => cidade.id_ibge === currentUser.cidadeEndereco.idIbge)
            if (cidade) {
                return cidade.praca
            }
        }
    }
    return currentUser.praca
}

export default getPraca;
import React from 'react'

export const TermoFamilia = () => (
    <>
        <p><strong>TERMO DE ACEITE</strong></p>

        <p>Ao enviar a fotolegenda para o concurso cultural do Televisando o responsável pelo aluno está ciente que:</p>

        <p><strong>1.</strong> A participação é exclusiva para alunos matriculados do 1º ao 5º ano do ensino fundamentalna rede municipal de ensino;</p>

	    <p><strong>2.</strong> O professor do aluno deve estar inscrito no projeto Televisando em 2021 e seu nome deve ser citado no formulário;</p>

        <p><strong>3.</strong> A fotolegenda deve responder a pergunta: <strong>Como a RPC faz parte do dia a dia da nossa família?</strong></p>

        <p><strong>4.</strong> As fotolegendas passarão por uma banca avaliadora e serão publicadas no site <a href="http://www.rpc.com.br/televisando" target="_blank">www.rpc.com.br/televisando</a> para votação popular, as 30 (trinta) que cumprirem os critérios de participação e avaliação, sendo que as 5 (cinco) mais votadas serão as vencedoras;</p>

        <p><strong>5.</strong> Os 5 (cinco) alunos e os professores das fotolegendas mais votadas ganham  uma Smart TV 40’ cada um;</p>
        
        <p><strong>6.</strong> Não será aceita a participação de qualquer pessoa que tenha parentes até 2.º (segundo) grau com todo e qualquer colaborador das empresas integrantes do Grupo Paranaense de Comunicação – GRPCOM;</p>
        
        <p><strong>7.</strong> O responsável pelo aluno ao enviar a Fotolegenda no concurso cultural, autoriza o uso da sua imagem e, bem como de todos que aparecem na foto e cede os direitos sob o trabalho (fotolegenda) para o IGRPCOM e RPC usarem em seus sites, redes sociais, TV, lives (webinários), EAD e demais materiais diversos de divulgação do projeto, pelo período de 5 (cinco) anos.  E é sua obrigação solicitar e/ou emitir a autorização do Termo de Uso de imagem de todas as pessoas que aparecem na foto (alunos, pais, família, comunidade, etc), conforme modelos disponibilizados no site <a href="http://www.rpc.com.br/televisando" target="_blank">www.rpc.com.br/televisando</a> aba concurso cultural e apresentar no prazo de 48 horas, caso seja solicitado pela equipe do IGRPCOM.</p>
        
        <p><strong>8.</strong> Confira o regulamento completo em <a href="http://www.rpc.com.br/televisando" target="_blank">www.rpc.com.br/televisando</a>, aba concurso cultural.</p>
    </>
);

export const TermoProfessor = () => (
    <>
        <p><strong>TERMO DE ACEITE</strong></p>

        <p>Ao enviar o Telejornal para o concurso cultural do Televisando o professor está ciente que:</p>

        <p><strong>1.</strong> A participação é exclusiva para professoresdo 1º ao 5º ano do ensino fundamental  na rede municipal de ensino, inscritos no Televisando 2021;</p>
        
        <p><strong>2.</strong> Serão premiados com uma Smart TV 50’,4 (quatro) professores no âmbito Estadual, que atenderem aos critérios de participação e melhor desempenho tiverem nos critérios de avaliação estabelecidos no regulamento para a categoria;</p>
        
        <p><strong>3.</strong> Serão premiadas as 4 (quatro) escolas em que os professores vencedores desenvolveram o Telejornal.</p>
        
        <p><strong>4.</strong> Não será aceita a participação de qualquer pessoa que tenha parentes até 2.º (segundo) grau com todo e qualquer colaborador das empresas integrantes do Grupo Paranaense de Comunicação – GRPCOM;</p>
        
        <p><strong>5.</strong> Ao inscrever seu trabalho (Telejornal) no concurso cultural, o professor autoriza o uso da sua imagem e direitos sob seu trabalho para o IGRPCOM e RPC usarem em seus sites, redes sociais, TV, lives (webinários), EAD e demais materiais de divulgação do projeto, pelo período de 5 (cinco) anos. E é sua obrigação solicitar a autorização do Termo de Uso de imagem de todas as pessoas que aparecem no Telejornal (alunos, pais, comunidade, etc), conforme modelos no Anexo I e disponibilizado no site <a href="http://www.rpc.com.br/televisando" target="_blank">www.rpc.com.br/televisando</a> aba concurso cultural e apresentar no prazo de 48 horas, caso seja solicitado pela equipe do IGRPCOM.</p>
        
        <p><strong>6.</strong> Confira o regulamento completo em <a href="http://www.rpc.com.br/televisando" target="_blank">www.rpc.com.br/televisando</a>, aba concurso cultural.</p>
    </>
);
import React from 'react'

const TermoFamiliaETudo = () => (
  <>
    <p>
      <strong>
        TERMO DE AUTORIZACÃO DE USO DE IMAGEM E VOZ E OUTRAS AVENÇAS
      </strong>
    </p>
    <p>
      <strong>1.</strong> Pelo presente instrumento, o(a){' '}
      <strong>Autorizador(a)</strong> cadastrado no Aplicativo “Você na RPC” ao
      optar por participar da <strong>Campanha “Família é Tudo”</strong>,
      autoriza à <strong>SOCIEDADE RÁDIO EMISSORA PARANAENSE S.A. (RPC)</strong>
      , com sede em Curitiba, Estado do Paraná, Rua Mamoré, n°. 753, CEP
      80.810-080, devidamente inscrita no CNPJ/MF sob o n°. 76.494.806/0001-45,
      de forma inteiramente gratuita, a título universal, em caráter total,
      definitivo, irrevogável e irretratável, a utilização de seu nome, imagem,
      voz e foto/vídeo para a fixação destes, na obra/ Campanha audiovisual por
      ela produzida, intitulada <strong>“Família é Tudo”</strong>, doravante
      denominada simplesmente <strong>“Obra”</strong>.
    </p>
    <p>
      <strong>2.</strong> Reconhece expressamente o <strong>Autorizador</strong>{' '}
      que a <strong>RPC</strong>, na qualidade de detentor dos direitos
      patrimoniais de autor sobre a <strong>Obra</strong> e tendo em vista a
      autorização efetuada neste Termo, poderá, a seu exclusivo critério,
      utilizar a <strong>Obra</strong> livremente, bem como seus extratos
      trechos ou partes, podendo, exemplificativamente, adaptá-la para fins de
      produção de obras audiovisuais novas, obras audiovisuais para fins de
      exibição em circuito cinematográfico, fotonovelas, obras literárias, peças
      teatrais e/ou peças publicitárias, utilizá-la, bem como a imagem e voz do{' '}
      <strong>Autorizador</strong> para produção de matéria promocional em
      qualquer tipo de mídia, inclusive impressa, seja para fins de divulgação
      da <strong>Obra</strong>, para a composição de qualquer produto ligado à
      mesma (tais como mas não limitados a capas & CD, DVI), “homevideo”, DAT,
      entre outros), assim como produção do “making of’ da <strong>Obra</strong>
      ; fixá-la em qualquer tipo de suporte material, tais como películas
      cinematográflcas de qualquer bitola, CD (“compact disc”), CD ROM, CD-I
      (“compact-disc” interativo), “homevideo”, DAT (“digital audio tape’, DVD
      (“digital video disc”) e suportes de computação gráfica em geral, ou
      armazená-la em banco de dados, exibi-la através de projeção em tela em
      casas de frequência coletiva ou em locais públicos, com ou sem ingresso
      pago, transmiti-la via rádio e/ou televisão de qualquer espécie (televisão
      aberta ou televisão por assinatura, através de todas as formas de
      transporte de sinal existentes, exemplificativamente UHF, VHF, cabo, MMDS
      e satélite, bem como independentemente da modalidade de comercialização
      empregada, incluindo “pay tv”, “pay per view”, “near vídeo on demand” ou
      “vídeo on demand”, independentemente das características e atributos do
      sistema de distribuição, abrangendo plataformas analógicas ou digitais,
      com atributos de interatividade, ou não), adaptá-la para forma de
      minissérie, comercializá-la ou alugá-la ao público em qualquer suporte
      material existente, promover ações de merchandising ou veicular
      propaganda, bem como desenvolver qualquer atividade de licenciamento de
      produtos e/ou serviços derivados da <strong>Obra</strong>, disseminá-la
      através da Intemet e telefonia, fixa ou móvel, inclusive via WhatsApp para
      colaboradores do <strong>GRPCOM</strong>, utilizá-la em parques de
      diversão, inclusive temáticos, ceder os direitos autorais sobre a{' '}
      <strong>Obra</strong> ou sobre as imagens cuja utilização foi autorizada
      através deste Termo a terceiros, para qualquer espécie de utilização,
      produzir novas obras audiovisuais (“remakes”), utilizar trechos ou
      extratos da mesma ou, ainda, dar-lhe qualquer outra utilização que
      proporcione a <strong>RPC</strong> alguma espécie de vantagem econômica.
    </p>

    <p>
      <strong>2.1</strong> Nenhuma das utilizações previstas no caput desta
      Cláusula, ou ainda qualquer outra que pretenda a <strong>RPC</strong> dar
      à <strong>Obra</strong> e/ou às imagens cuja utilização foi autorizada
      através deste Termo, têm limitação de número de vezes, podendo ocorrer no
      Brasil e/ou no exterior, sem que seja devida ao{' '}
      <strong>Autorizador</strong> qualquer remuneração.
    </p>

    <p>
      <strong>3.</strong> Quanto ao material e dados pessoais, enviado e/ou
      informado pelo <strong>Autorizador</strong>, fica certo e acordado, que o
      material (foto/vídeo) poderá ou não ser selecionado e, consequentemente,
      utilizado ou não pela <strong>RPC</strong>, ao seu exclusivo critério, no
      período entre <strong>março e dezembro de 2024</strong>, ficando desde já
      ajustado que o material enviado pelo usuário, selecionado ou não,{' '}
      <strong>será descartado pela RPC após 31/12/2024, sendo que</strong>:
      <ol type="a">
        <li>
          O material (e seus dados pessoais) encaminhado poderá ser veiculado
          nos telejornais e intervalos comerciais da RPC no período entre{' '}
          <strong>março e dezembro de 2024</strong>, bem como ficará disponível
          em galeria no aplicativo “VC na RPC”.
        </li>
        <li>
          Ao encaminhar o material à <strong>RPC</strong>, o usuário manifesta
          sua ciência dos usos que poderão ser dados por parte da emissora, bem
          como sua expressa concordância.
        </li>
      </ol>
    </p>

    <p>
      <strong>4.</strong> Ao declarar ciência o <strong>Autorizador</strong> se
      responsabiliza integralmente pela ausência de autorização dos terceiros
      envolvido bem como demais responsáveis legais para o uso das imagens em
      qualquer meio de comunicação, publicidade, divulgação ou material
      relacionado, garantindo que possui plenos direitos para conceder esta
      autorização, isentando o <strong>GRPCOM</strong> de qualquer
      responsabilidade decorrente do uso de imagem.
    </p>

    <p>
      <strong>5.</strong> Assim, obrigam-se as partes por si, seus herdeiros e
      sucessores a qualquer titulo, ficando eleito o Foro Central da Comarca da
      Região Metropolitana de Curitiba - PR para dirimir quaisquer dúvidas
      oriundas deste Termo.
    </p>

    <p>Estou ciente e de acordo com os termos acima.</p>
  </>
)

export default TermoFamiliaETudo

import React from 'react';

import { Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Back = ({ onClick }) => {
    return <div style={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
     }} onClick={onClick}>
         <LeftOutlined />
         <div style={{ 
             padding: '3px 0px 0px 5px',
             fontSize: '16px'
         }}>
            <Text href="#">Voltar</Text>
         </div>
    </div>;
};

export default Back;
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Divider, Radio, Space } from 'antd';

import ImagePicker from '../../../components/ImagePicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'
import DropdownWithLabel from '../../../components/DropdownWithLabel'

import TermoCaminhosDoCampo from '../../../termosAceite/termoCaminhosDoCampo'
import FormularioInscricao from './FormularioInscricao.js'

import Base from './Base'

import { checkUnfilledFields } from '../../../utils/helpers';

import parsers from '../../../utils/parsers/index'
import compareCities from '../../../utils/compareCities'

const characterLimit = 255

const idsMidias = {
    Doces: 36,
    Salgados: 37
}

const ConcursoCaminhosDoCampo = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [mostrarFormInscriModal, setMostrarFormInscriModal] = useState(false)
    const [cidades, setCidades] = useState() 

    const [formValues, setFormValues] = useState({
        //Categoria
        categoria: 'Doces',
        //Dados cadastrais
        nomeCompleto: '',
        endereco: '',
        cidade: '',
        praca: '',
        documento: '',
        telefone: '',
        email: '',
        //Receita
        nomeReceita: '',
        ingredientes: '',
        modoPreparo: '',
        descricao: '',
        termoAceito: false,
        formularioInscricaoAceito: false,
        media: []
    })

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json())
            const cidadesObj = {}
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj[`${cidade.nome}`] = cidade.nome 
                }
            )
            setCidades(cidadesObj)
        }
        loadCidades()
    }, [])

    useEffect(() => {
        const loadPraca = async () => {
            const importedCities = await fetch('/cidades.json').then(res => res.json())
            const cidade = Object.values(importedCities).find(cidade => 
                compareCities(formValues.cidade, cidade.nome)
            )
            if(!cidade) return;
            setFormValues(prev => ({...prev, praca: cidade.praca}))
        }
        loadPraca()
    }, [cidades, formValues.cidade])

    const onChangeDropdownEventHandler = useCallback((attribute) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text
        setFormValues(prev => ({...prev, [`${attribute}`]: value}))
    }, [])

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
            const parseType = event.target.dataset.parser
            const parsedValue = parseType ? parsers[parseType](value) : value
            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
            }
        }, []
    );

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    }, [])

    const handleCheckedFormInscri = useCallback(() => {
        setFormValues(prev => ({...prev, formularioInscricaoAceito: !prev.formularioInscricaoAceito}))
    }, [])

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [])

    const salvarOk = useMemo(() => {
        const { 
            categoria,
            nomeCompleto,
            endereco,
            cidade,
            documento,
            telefone,
            email,
            ingredientes,
            modoPreparo,
            descricao,
            termoAceito,
            formularioInscricaoAceito,
            media,
            nomeReceita,
        } = formValues;

        const requiredFields = checkUnfilledFields({
            categoria: { 
                value: categoria,
                minLength: 0
            },
            nomeCompleto: {
                value: nomeCompleto,
                minLength: 0
            },
            endereco: {
                value: endereco,
                minLength: 0
            },
            cidade: {
                value: cidade,
                minLength: 0
            },
            documento: {
                value: documento,
                minLength: 11
            },
            telefone: {
                value: telefone,
                minLength: 10
            },
            email: {
                value: email,
                minLength: 0
            },
            nomeReceita: {
                value: nomeReceita,
                minLength: 0,
            },
            ingredientes: {
                value: ingredientes,
                minLength: 0
            },
            modoPreparo: {
                value: modoPreparo,
                minLength: 0
            },
            descricao: {
                value: descricao,
                minLength: 0
            }
        });

        return !(
            termoAceito
            && formularioInscricaoAceito
            && requiredFields.length === 0
            && media.length !== 0
        )
    }, [formValues])

    return (
        <Base
            title="Concurso Receitas Paranaenses - Caminhos do Campo 2021"
            description={() => (
                <div className="container">
                    <Divider>Categoria</Divider>
                    <div>
                        <p>Seleciona a categoria da sua receita:</p>
                        <Radio.Group onChange={e => 
                                setFormValues(prev => ({...prev, categoria: e.target.value}))
                            } 
                            value={formValues.categoria}
                            style={{marginLeft: '24px'}}
                            className='align-left'
                        >
                            <Space direction="vertical">
                            <Radio value={'Doces'}>Doces</Radio>
                            <Radio value={'Salgados'}>Salgados</Radio>
                            </Space>
                        </Radio.Group>
                    </div>

                    <Divider>Dados Cadastrais</Divider>

                    <InputWithLabel
                        title={"Nome completo"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeCompleto}
                        onChange={onChangeInputEventHandler('nomeCompleto', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Endereço"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.endereco}
                        onChange={onChangeInputEventHandler('endereco', characterLimit)}
                    />
                    <DropdownWithLabel
                        title={'Cidade'}
                        subTitle="Campo obrigatório"
                        values={cidades}
                        onChange={onChangeDropdownEventHandler('cidade')}
                    />
                    <InputWithLabel
                        title={"CPF"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.documento}
                        onChange={onChangeInputEventHandler('documento', characterLimit)}
                        maxInputLength={14}
                        mask='cpf'
                        parser='onlyNumbers'
                    />
                    <InputWithLabel
                        title={"Telefone"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.telefone}
                        onChange={onChangeInputEventHandler('telefone', characterLimit)}
                        maxInputLength={16}
                        mask='telefone'
                        parser='onlyNumbers'
                    />
                    <InputWithLabel
                        title={"Email"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.email}
                        onChange={onChangeInputEventHandler('email', characterLimit)}
                    />

                    <Divider>Receita</Divider>

                    <InputWithLabel
                        title={"Nome da Receita"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.nomeReceita}
                        onChange={onChangeInputEventHandler('nomeReceita', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Ingredientes"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.ingredientes}
                        onChange={onChangeInputEventHandler('ingredientes', 50000)}
                        className='textInputArea'
                        maxInputLength={50000}
                    />
                    <InputWithLabel
                        title={"Modo Preparo"}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.modoPreparo}
                        onChange={onChangeInputEventHandler('modoPreparo', 50000)}
                        className='textInputArea'
                        maxInputLength={50000}
                    />

                    <MediaGallery medias={formValues.media} />
                    <ImagePicker handleSetMedias={handleSetMedia} required={true} title='Selecione a foto da sua receita'/>
                    
                    <InputWithLabel
                        title={"Agora, deixe um comentário sobre o prato criado através da sua receita."}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.descricao}
                        maxInputLength={50000}
                        onChange={onChangeInputEventHandler('descricao', 50000)}className="textInputArea"
                        inputPlaceholder={'Descrição...'}
                    />

                    <Divider />

                    <div className="opcenquete align-left">
                        <ToggleSwitch checked={formValues.formularioInscricaoAceito}
                            setChecked={handleCheckedFormInscri}
                            name='formularioInscricaoAceito'
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarFormInscriModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos do formulário de inscrição para o concurso <strong>CAMINHOS DO CAMPO 2021</strong>.
                            ​</span>                        
                        </button>
                        
                    </div>

                    <div className="opcenquete align-left">
                        <ToggleSwitch checked={formValues.termoAceito}
                            setChecked={handleCheckedTermo}
                            name='termoAceito'
                        />
                        <button type="button" className="btTransparente" 
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>
                                Declaro que li e aceito os termos de privacidade do concurso <strong>CAMINHOS DO CAMPO 2021</strong>.
                            ​</span>
                            
                        </button>
                        
                    </div>
                    <div style={{marginTop: '14px'}}>
                    {(!formValues.termoAceito || !formValues.formularioInscricaoAceito) && 
                    <span>
                        <strong>Atenção</strong>: Neste caso (<strong>NÃO ACEITE</strong>) não será possível sua participação no concurso <strong>CAMINHOS DO CAMPO 2021</strong>. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.
                    </span>}
                    </div>
                    

                    {
                        mostrarTermoModal &&    
                        <Modal
                            setTermoAceito={handleCheckedTermo}
                            setShowModal={setMostrarTermoModal}
                        >
                            <TermoCaminhosDoCampo />
                        </Modal>
                    } 
                    {
                        mostrarFormInscriModal &&    
                        <Modal 
                            setTermoAceito={handleCheckedFormInscri}
                            setShowModal={setMostrarFormInscriModal}>
                            <FormularioInscricao />
                        </Modal>
                    }                           
                </div>
            )}
            imagePath="/imgs/caminhosDoCampo.jpeg"
            programName="Concurso Caminhos do Campo"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="concursoCaminhosDoCampo2021"
            mediaID={idsMidias[formValues.categoria]}
        />
    )
}

export default ConcursoCaminhosDoCampo

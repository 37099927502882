/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext, useCallback, useState, useEffect } from 'react'

import { CurrentUserContext } from '../../../../Contexts'
import { useCallbackStatic } from '../../../../hooks/utils'

import getDeviceInfos from '../../../../utils/getDeviceInfos'
import { uploadFirestore } from './firestore'

import originConsents from '../../../../constants/originConsents'
import sendUserConsent from '../../../../utils/sendUserConsent'

const Base = ({
  title,
  description,
  imagePath,
  form,
  setForm,
  tipoTermo,
  salvarOk,
  db,
  availableTicketsAmmount,
  availableTicketsAmmountRef
}) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [userTicket, setUserTicket] = useState('')
  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(undefined)

  // console.log(userIdToken)

  useEffect(() => {
    if (!currentUser) {
      setHasError('Usuário não autenticado')
    }
  }, [currentUser])

  const back = useCallbackStatic(() => {
    setResult(false)
    window.location.reload()
  })

  const cleanDataState = useCallback(() => {
    const cleanedForm = {}
    Object.keys(form).forEach((key) => {
      cleanedForm[`${key}`] = ''
    })
    cleanedForm.termoAceito = false
    setForm(() => cleanedForm)
    setResult(() => true)
    setLoading(() => false)
    setHasError(() => undefined)
  }, [form, setForm])

  // console.log(currentUser)

  const send = useCallback(async () => {
    setLoading(true)
    getDeviceInfos()
    await uploadFirestore({
      db,
      currentUser,
      tipoTermo,
      form,
      setLoading,
      setResult,
      setHasError,
      hasError,
      availableTicketsAmmountRef,
      setUserTicket
    })
    await sendUserConsent({
      name: currentUser.nome,
      email: currentUser.email,
      origin: originConsents[tipoTermo]
    })
    setLoading(false)
  }, [
    db,
    currentUser,
    tipoTermo,
    form,
    setLoading,
    setResult,
    setHasError,
    hasError,
    availableTicketsAmmountRef
  ])

  const onBack = useCallback(() => {
    cleanDataState()
    back()
  }, [back, cleanDataState])

  const renderError = useCallback(
    () => (
      <div className="container">
        <div className="titenquete title">
          <p>Ocorreu um erro ao gerar o seu desconto.</p>
          <p>{hasError}</p>
          <p>Por favor, tente novamente.</p>
        </div>
        <div className="btsalvar">
          <button onClick={onBack}>Tente novamente</button>
        </div>
      </div>
    ),
    [hasError, onBack]
  )

  const renderContent = useCallback(() => {
    const showSendFormComponent = (
      <>
        <div className="container">
          <div className="descenquete">{description()}</div>
          <div className="btsalvar">
            <button onClick={send} className="btsalvar" disabled={salvarOk}>
              {availableTicketsAmmount > 0
                ? 'Gerar Desconto'
                : 'Descontos esgotados'}
            </button>
          </div>
        </div>
      </>
    )

    const showSuccessComponent = (
      <div className="container">
        <div className="titenquete title cupom">Código gerado com sucesso!</div>
        <div className="titenquete content cupom">Seu código é</div>
        <div className="titenquete cupom code">{userTicket}</div>
        <div className="titenquete aviso parceiro">
          <p>
            Utilize esse código para fazer a sua{' '}
            <strong>inscrição com 55%</strong> no{' '}
            <strong>show do Guns N' Roses</strong>
          </p>
        </div>
        <div
          className="btsalvar"
          style={{ textAlign: 'center', marginBottom: '10px' }}
        >
          {/* Link?  */}{' '}
          <button
            onClick={() =>
              window.open(
                'https://uhuu.com/evento/pr/curitiba/guns-n-roses-cwb-10077',
                '_blank'
              )
            }
            className="btsalvar"
          >
            GARANTA SEU INGRESSO
          </button>
        </div>
        <p>
          Eventuais dúvidas, entre em contato através do email{' '}
          <div style={{ display: 'inline-block', textDecoration: 'underline' }}>
            eventos@rpc.com.br
          </div>
        </p>
      </div>
    )

    return !result ? showSendFormComponent : showSuccessComponent
  }, [title, description, send, salvarOk, result, userTicket])

  const renderLoading = useCallback(() => {
    return (
      <>
        <div className="container">
          <div className="titenquete title">
            <p>Seu desconto está sendo gerado.</p>
          </div>
          <div className="titenquete content">
            <p>Por favor, não feche esta tela até o processo finalizar.</p>
          </div>
        </div>
        <img src="/imgs/giphy.gif" className="img-loading" />
      </>
    )
  }, [loading])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img src={imagePath} alt="banner" />
        </div>
        {hasError ? renderError() : loading ? renderLoading() : renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

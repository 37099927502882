import { integer } from '../formatters/integer';

/** Função para validar o CPF
 * @param value Valor do CPF
 * @returns Se o CPF for válido retorna true, senão retorna false
 */

export const cpfValidator = (value) => {
    const dcto = integer(value.toString()).toString();

    if (!dcto || dcto.length !== 11) return false;

    if (
        dcto === '00000000000' ||
        dcto === '11111111111' ||
        dcto === '22222222222' ||
        dcto === '33333333333' ||
        dcto === '44444444444' ||
        dcto === '55555555555' ||
        dcto === '66666666666' ||
        dcto === '77777777777' ||
        dcto === '88888888888' ||
        dcto === '99999999999'
    )
        return false;
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i += 1)
        soma += parseInt(dcto.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(dcto.substring(9, 10), 10)) return false;
    soma = 0;
    for (let i = 1; i <= 10; i += 1)
        soma += parseInt(dcto.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(dcto.substring(10, 11), 10)) return false;

    return true;
};

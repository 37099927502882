import React from 'react'

const TermoCaminhosDoCampo = () => (
  <>
    <p>
      <strong>
        FORMULÁRIO DE INSCRIÇÃO / AGENDAMENTO ENCONTRO ANIMAL FOZ – 2021
      </strong>
    </p>
    <p>Quanto aos seus DADOS PESSOAIS, esclarecemos:</p>
    <ol type='a'>
      <li>Você deverá ter o máximo de atenção no momento da sua inscrição/agendamento, ficando certo, desde já, que a REDE PARANAENSE DE COMUNICAÇÃO - RPC não se responsabiliza por erros cadastrais dos inscritos, tais como: cadastro duplo, equívocos de dados, e outros.</li>

      <li>Todos os dados dos inscritos serão armazenados num banco de dados único do ENCONTRO ANIMAL FOZ - 2021, de acordo com os Termos de Uso do aplicativo <a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank" rel="noopener noreferer">“VOCÊ NA RPC”</a> e com a política de privacidade da RPC (<a
        href="https://vamosjuntos.rpc.com.br/privacidade.html"
        target="_blank"
        rel="noopener noreferrer">https://vamosjuntos.rpc.com.br/privacidade.html</a>).</li>

      <li>Ao realizar sua inscrição/agendamento você autoriza expressamente a RPC a fazer o tratamento de seus dados pessoais, pelo prazo de 05 (cinco) anos, para que estes sejam usados pela RPC ou terceiros ligados ao desenvolvimento do ENCONTRO ANIMAL FOZ - 2021, ou seja: Dinamize (sistema de envio de e-mails) <a href="https://www.dinamize.com.br/privacidade" target="_blank" rel="noopener noreferer">https://www.dinamize.com.br/privacidade</a>, para fins de desenvolvimento e implementação do ENCONTRO ANIMAL FOZ - 2021, bem como para fins de relacionamento com você, identificação, localização, preservação dos seus direitos e obrigações e da RPC, sem o direito de obter o reconhecimento, compensação ou remuneração de qualquer espécie para tal uso</li>

      <li>Caso queira, e mediante requisição, você poderá solicitar à RPC a retificação, correção e atualização de seus dados pessoais, durante a vigência do ENCONTRO ANIMAL FOZ - 2021, para tanto deverá entrar em contato por meio do aplicativo Você na RPC.</li>

      <li>Também, mediante requisição, você poderá solicitar o cancelamento de sua inscrição/agendamento no ENCONTRO ANIMAL FOZ - 2021 (por meio do aplicativo Você na RPC), todavia, ficará resguardado o direito de arquivamento de seus dados pela RPC, pelo prazo de 05 (cinco) anos, conforme acima estabelecido.</li>

      <li>A RPC se reserva ao direito de cancelar e/ou bloquear o acesso e cadastro de qualquer inscrito no ENCONTRO ANIMAL FOZ - 2021, a qualquer momento e sem prévio aviso, caso seja constatado que este pratica ou venha a praticar algum ato ou conduta que (i) viole as leis e regulamentos federais, estaduais e/ou municipais e (ii) viole os princípios da moral e dos bons costumes.</li>
    </ol>

    <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – ENCONTRO ANIMAL FOZ - 2021</strong></p>
    <p>Ao enviar meus dados e clicar no aceite abaixo, estou de acordo com os termos/condições acima (<a href="www.rpc.com.br/encontroanimal" rel="noopener noreferer">www.rpc.com.br/encontroanimal</a>), bem como com os Termos de Uso do aplicativo <a href="https://cdn.vocenarpc.com.br/production/termos/index.html" target="_blank" rel="noopener noreferer">“VOCÊ NA RPC”</a> e das Políticas de Privacidade e Proteção de Dados da GLOBO (<a href="https://privacidade.globo.com/privacy-policy" target="_blank" rel="noopener noreferer">https://privacidade.globo.com/privacy-policy</a>) e da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank" rel="noopener noreferer">https://www.negociosrpc.com.br/politica-de-privacidade-rpc/</a>), e aceito receber informações sobre o ENCONTRO ANIMAL FOZ - 2021, bem como de outros projetos, cursos e/ou outras ações promovidas e apoiadas pela RPC a partir da presente data em meu e-mail e telefone.</p>

  </>
)

export default TermoCaminhosDoCampo

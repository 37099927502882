import React, { useContext } from 'react'

import { UploadContext } from '../Contexts'
import { useCallbackStatic } from '../hooks/utils'
import { FirebaseContext } from '../Contexts'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

const UploadProvider = (props) => {
  const { firebaseFunctions } = useContext(FirebaseContext)

  const S3StartUpload = useCallbackStatic(
    async (
      currentFiles,
      setUploading = null,
      requestHandler = null,
      setHasError = null
    ) => {
      const { data: {options} } = await firebaseFunctions.httpsCallable('getMediaKeys')()
      const data = JSON.parse(options)

      const s3Config = {
        region: data.region,
        credentials: {
          accessKeyId: data.accessKey,
          secretAccessKey: data.secretKey
        },
        requestHandler
      }
      if (!requestHandler) {
        delete s3Config.requestHandler
      }

      const s3Client = new S3Client(s3Config)

      const promises = currentFiles.map(async (oFile) => {
        const { name, file } = oFile

        console.log(oFile)

        return s3Client
          .send(
            new PutObjectCommand({
              Bucket: data.bucket,
              ACL: 'private',
              Key: `${data.keyPrefix}${name}`,
              Body: file
            })
          )
          .then((response) => {
            console.log(
              `Arquivo enviado com sucesso: ${data.bucket}/${data.keyPrefix}${name}`
            )
            if (setUploading) {
              setUploading(false)
            }
          })
          .catch((err) => {
            console.log('Ocorreu um erro ao enviar o arquivo: ', err)
            if (setUploading) {
              setUploading(false)
            }
            if (setHasError) {
              setHasError('Ocorreu um erro no upload.')
            }
          })
      })

      return promises
    }
  )

  const UploadContextValue = {
    S3StartUpload
  }

  return (
    <UploadContext.Provider value={UploadContextValue}>
      {props.children}
    </UploadContext.Provider>
  )
}

export default UploadProvider

import React, { useState } from "react";

import { ParamsContext } from '../Contexts'

const getParams = () => {
    const search = window.location.search.substring(1);
    if(!search) {
        return {}
    }
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}

const ParamsProvider = ({ children }) => {
    const [params] = useState(getParams())

    return (
        <ParamsContext.Provider value={params}>
            {children}
        </ParamsContext.Provider>
    )

}

export default ParamsProvider; 
import React, { useContext, useCallback, useState, useEffect } from 'react'

import { CurrentUserContext } from '../../../../Contexts'
import { useCallbackStatic } from '../../../../hooks/utils'
import getDeviceInfos from '../../../../utils/getDeviceInfos'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import Footer from '../../../../components/Footer'
import sendUserConsent from '../../../../utils/sendUserConsent'
import originConsents from '../../../../constants/originConsents'
import QRCode from 'qrcode.react'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Collapse from './Collapse'
import { uploadFirestore } from './firestore'
import Modal from '../../../../components/Modal'
import TermoAgasalhoCinema2024 from '../../../../termosAceite/termoAgasalhoCinema2024'

const Base = ({
  title = '',
  description,
  imagePath,
  tipoTermo,
  db,
  dataTickets,
  loadingTickets,
  setLoadingTickets
}) => {
  const { currentUser } = useContext(CurrentUserContext)

  console.log(currentUser)

  const [userTicket, setUserTicket] = useState('RPCCA445GS')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)
  const [hasError, setHasError] = useState('')
  const [itemSelected, setItemSelected] = useState({})

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [termoAceito, setTermoAceito] = useState(false)

  const onReload = useCallback(() => {
    window.location.reload()
  }, [])

  useEffect(() => {
    const userAlreadyHaveTicket = async () => {
      try {
        // if (!currentUser) return
        // let hasUserTicket = false
        // for (const item of dataTickets) {
        //   for (const data of item.data) {
        //     const ticketInDB = await data.availableTicketsAmmountRef
        //       .collection('tickets')
        //       .where('id', '==', currentUser.uid)
        //       .get()
        //     if (ticketInDB.docs.length !== 0) {
        //       setUserTicket(ticketInDB.docs[0].data().ticket)
        //       setResult(true)
        //       hasUserTicket = true
        //       break // Saia do loop interno, pois encontrou o ticket do usuário
        //     }
        //   }
        //   if (hasUserTicket) break // Saia do loop externo, pois encontrou o ticket do usuário
        // }
      } catch (error) {
        console.log('Error userAlreadyHaveTicket: ', error)
      } finally {
        setLoadingTickets(false)
      }
    }

    userAlreadyHaveTicket()
  }, [dataTickets, currentUser])

  const send = useCallback(async () => {
    setLoading(true)
    setResult(true)
    // await uploadFirestore({
    //   db,
    //   currentUser,
    //   tipoTermo,
    //   setLoading,
    //   setResult,
    //   setHasError,
    //   hasError,
    //   itemSelected,
    //   setUserTicket
    // })
    // await sendUserConsent({
    //   name: currentUser.nome,
    //   email: currentUser.email,
    //   origin: originConsents[tipoTermo]
    // })
    setLoading(false)
  }, [
    db,
    currentUser,
    tipoTermo,
    termoAceito,
    setTermoAceito,
    itemSelected,
    setLoading,
    setResult,
    setHasError,
    hasError,
    dataTickets
  ])

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(userTicket)
      toast.info('Texto copiado com sucesso!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    } catch (error) {
      console.error('Erro ao copiar o texto:', error)
    }
  }

  const onClose = () => {
    toast.error(
      'Atenção: Caso recuse o TERMO DE ACEITE, não será possível sua participação. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.',
      {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      }
    )
  }

  const renderContent = useCallback(() => {
    // if (!currentUser) {
    //   return (
    //     <div className="container-page">
    //       <Player
    //         autoplay
    //         keepLastFrame
    //         src="https://lottie.host/26f7167d-21f8-4234-91b5-ca19aecfb7fb/Dyw0I3MuB4.json"
    //         style={{ height: '160px', width: '160px' }}
    //       >
    //         <Controls
    //           visible={false}
    //           buttons={['play', 'repeat', 'frame', 'debug']}
    //         />
    //       </Player>
    //       <div className="title-enquete">
    //         <h1>Usuário não autenticado</h1>
    //       </div>
    //       <br />
    //     </div>
    //   )
    // }
    if (loadingTickets) {
      return (
        <div className="container-page">
          <Player
            autoplay
            loop
            src="https://lottie.host/0159d8f4-29eb-4314-8d9d-ed9859bb2fcd/l4J4IcGZkY.json"
            style={{ height: '200px', width: '200px' }}
          >
            <Controls
              visible={false}
              buttons={['play', 'repeat', 'frame', 'debug']}
            />
          </Player>
        </div>
      )
    }
    if (loading) {
      return (
        <div className="container-page">
          <Player
            autoplay
            loop
            src="https://lottie.host/06400ccd-5f52-4962-9b80-726706404d05/Z4GGWy8UPJ.json"
            style={{ height: '200px', width: '200px' }}
          >
            <Controls
              visible={false}
              buttons={['play', 'repeat', 'frame', 'debug']}
            />
          </Player>
          <div className="title-enquete">
            <h1>Gerando seu código</h1>
          </div>
          <br />
          <div className="sub-title">
            <h6>Por favor, não feche esta tela até o processo finalizar.</h6>
          </div>
        </div>
      )
    }
    if (hasError) {
      return (
        <div className="container-page">
          <div className="title-enquete">
            <h1>Erro na geração do cupom</h1>
          </div>
          <br />
          <div className="sub-title">
            <h6>{hasError}</h6>
          </div>
          <br />
          <div className="btn-default">
            <button onClick={onReload}>Tentar novamente</button>
          </div>
        </div>
      )
    }
    if (!result) {
      return (
        <div className="container-page agasalho-2024">
          <div className="description-enquete">{description()}</div>
          <div className="collapse-container">
            {loadingTickets ? (
              <Player
                autoplay
                loop
                src="https://lottie.host/06400ccd-5f52-4962-9b80-726706404d05/Z4GGWy8UPJ.json"
                style={{ height: '200px', width: '200px' }}
              >
                <Controls
                  visible={false}
                  buttons={['play', 'repeat', 'frame', 'debug']}
                />
              </Player>
            ) : (
              dataTickets.map((item) => (
                <Collapse key={item.city} title={`${item.city}`}>
                  {item.movies.map((movie) => (
                    <div className="content-collapse" key={movie.movie}>
                      <div className="title-collapse">
                        <p>{movie.movie}</p>
                        <p>
                          {movie.date} - {movie.location}
                        </p>
                      </div>

                      {movie.coupons.map((coupon) => (
                        <div
                          key={coupon.hour}
                          className="each-collapse"
                          style={{ marginBottom: '15px' }}
                        >
                          <h6>{coupon.hour}</h6>
                          <div className="btn-default">
                            <button
                              onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                setMostrarTermoModal((_) => true)
                                setItemSelected(coupon)
                              }}
                              disabled={coupon.availableTicketsAmmount === 0}
                            >
                              {coupon.availableTicketsAmmount > 0
                                ? 'GERAR CÓDIGO'
                                : 'ESGOTADO'}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </Collapse>
              ))
            )}
          </div>

          {mostrarTermoModal && (
            <Modal
              onClick={() => {
                setTermoAceito((_) => true)
                send()
              }}
              onClose={onClose}
              setShowModal={setMostrarTermoModal}
            >
              <TermoAgasalhoCinema2024 />
            </Modal>
          )}
        </div>
      )
    }
    return (
      <div className="container-page">
        <Player
          autoplay
          keepLastFrame
          src="https://lottie.host/9b0cc4f5-937f-4dcc-bd4c-0450d0fa6792/l1erLfIKCb.json"
          style={{ height: '200px', width: '200px' }}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>
        <div className="title-enquete">
          <h1>Código gerado com sucesso!</h1>
        </div>
        <br />

        <div className="ticket-system">
          <div className="top">
            <div className="printer" />
          </div>

          <div className="receipts-wrapper">
            <div className="receipts">
              <div className="ticket">
                <div className="holes-top"></div>
                {/* <div className="title">
                  <h2>Planetário Maringá 2024</h2>
                </div> */}
                <div className="poster">
                  <img src={imagePath} alt="banner" />
                </div>
                <div className="info">
                  <div className="header-ticket">
                    <h6>{itemSelected.movie}</h6>
                    <p>{itemSelected.location}</p>
                  </div>
                  <div className="code">
                    <h6>Seu código é</h6>
                    <p>{userTicket}</p>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Dia</th>
                        <th>Hora</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{itemSelected.date}</td>
                        <td>{itemSelected.hour}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="holes-lower"></div>
                <p className="info-footer">
                  Apresente este código e sua doação de peças de roupas,
                  calçados ou cobertas. Tenha um bom filme!
                  <br />
                  <br />
                  Eventuais dúvidas, entre em contato através do email
                  eventos@rpc.com.br
                </p>
                <div className="qrcode-ticket">
                  <QRCode size={70} bgColor="#ededed" value={userTicket} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="btn-default">
          <button onClick={copyToClipboard}>COPIAR CÓDIGO</button>
        </div>

        <Footer />
      </div>
    )
  }, [
    result,
    title,
    description,
    userTicket,
    hasError,
    send,
    dataTickets,
    mostrarTermoModal,
    loading,
    loadingTickets
  ])

  return (
    <React.Fragment>
      <div className="enqueterpc agasalho-2024">
        <div className="imagemdestaque">
          <img src={imagePath} alt="banner" />
        </div>
        {renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

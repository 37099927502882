/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext, useCallback, useState, useEffect } from 'react'

import { FirebaseContext, CurrentUserContext } from '../../../../Contexts'
import { useCallbackStatic } from '../../../../hooks/utils'

import getDeviceInfos from '../../../../utils/getDeviceInfos'
import { uploadFirestore } from './firestore'

import originConsents from '../../../../constants/originConsents'
import sendUserConsent from '../../../../utils/sendUserConsent'

const Base = ({
  title,
  description,
  imagePath,
  form,
  setForm,
  tipoTermo,
  salvarOk,
  db,
  availableTicketsAmmount,
  availableTicketsAmmountRef
}) => {
  const { firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [userIdToken, setUserIdToken] = useState('')
  const [userTicket, setUserTicket] = useState('')
  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(undefined)

  // console.log(userIdToken)

  useEffect(() => {
    const getFirebaseToken = async () => {
      if (firebaseAuth.currentUser) {
        const token = await firebaseAuth.currentUser.getIdToken()
        setUserIdToken(() => token)
      } else {
        setHasError('Usuário não autenticado')
      }
    }
    getFirebaseToken()
  }, [firebaseAuth.currentUser])

  const back = useCallbackStatic(() => {
    setResult(false)
    window.location.reload()
  })

  const cleanDataState = useCallback(() => {
    const cleanedForm = {}
    Object.keys(form).forEach((key) => {
      cleanedForm[`${key}`] = ''
    })
    cleanedForm.termoAceito = false
    setForm(() => cleanedForm)
    setResult(() => true)
    setLoading(() => false)
    setHasError(() => undefined)
  }, [form, setForm])

  // console.log(currentUser)

  const send = useCallback(async () => {
    setLoading(true)
    getDeviceInfos()
    await uploadFirestore({
      db,
      currentUser,
      tipoTermo,
      form,
      setLoading,
      setResult,
      setHasError,
      hasError,
      availableTicketsAmmountRef,
      setUserTicket
    })
    await sendUserConsent({
      name: currentUser.nome,
      email: currentUser.email,
      origin: [originConsents.curitibaCountryFestival2022]
    })
    setLoading(false)
  }, [
    db,
    currentUser,
    tipoTermo,
    form,
    setLoading,
    setResult,
    setHasError,
    hasError,
    availableTicketsAmmountRef
  ])

  const onBack = useCallback(() => {
    cleanDataState()
    back()
  }, [back, cleanDataState])

  const renderError = useCallback(
    () => (
      <div className="container">
        <div className="titenquete title">
          <p>Ocorreu um erro ao gerar o seu cupom.</p>
          <p>{hasError}</p>
          <p>Por favor, tente novamente.</p>
        </div>
        <div className="btsalvar">
          <button onClick={onBack}>Tentar novamente</button>
        </div>
      </div>
    ),
    [hasError, onBack]
  )

  const renderContent = useCallback(() => {
    const showSendFormComponent = (
      <>
        <div className="container">
          <div className="descenquete">{description()}</div>
          <div className="btsalvar">
            <button onClick={send} className="btsalvar" disabled={salvarOk}>
              {availableTicketsAmmount > 0 ? 'Gerar Desconto' : 'Cupons esgotados'}
            </button>
            {salvarOk || <p style={{ paddingTop: '10px', fontSize: '12px' }}>
               <strong>Atenção:</strong> Cupom disponível enquanto durarem os ingressos.
                Válido para os dias 28 e 29/05, <strong>somente para pista e área vip.</strong>
            </p>}
          </div>
        </div>
      </>
    )

    const showSuccessComponent = (
      <div className="container">
        <div className="titenquete title cupom">
          Desconto gerado com sucesso!
        </div>
        {/* <div className="titenquete content cupom">
          Seu cupom é
        </div>
        <div className="titenquete cupom code">
          {userTicket.toUpperCase()}
        </div> */}
        <div className="titenquete aviso parceiro">
          <p>
            Agora que você já <strong>garantiu seu desconto de 50%</strong>,
            vá até o <strong>site do parceiro</strong> e adquira seu ingresso
          </p>
        </div>
        <div className="btsalvar" style={{ textAlign: 'center' }}>
            <button onClick={() => window.open('https://curitibacountryfestival.uhuu.com/', '_blank')} className="btsalvar">
              GARANTA SEU INGRESSO
            </button>
            <a
              target="_blank"
              href="https://redeglobo.globo.com/rpc/diversao-e-arte/Curitiba/noticia/country-festival-chega-a-capital-paranaense-no-final-deste-mes-saiba-mais.ghtml">
              Saiba mais
            </a>
        </div>
      </div>
    )

    return !result ? showSendFormComponent : showSuccessComponent
  }, [title, description, send, salvarOk, result, userTicket])

  const renderLoading = useCallback(() => {
    return (
      <>
        <div className="container">
          <div className="titenquete title">
            <p>Seu cupom está sendo gerado.</p>
          </div>
          <div className="titenquete content">
            <p>Por favor, não feche esta tela até o processo finalizar.</p>
          </div>
        </div>
        <img src="/imgs/giphy.gif" className="img-loading"/>
      </>
    )
  }, [loading])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img src={imagePath} alt="banner" />
        </div>
        {hasError ? renderError() : loading ? renderLoading() : renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

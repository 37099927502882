import React, { useCallback, useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { Progress } from 'antd';

import DropdownWithLabel from '../../../components/DropdownWithLabel';
import MediaGallery from '../../../components/MediaGallery';
import ImagePicker from '../../../components/ImagePicker';
import ToggleSwitch from '../../../components/ToggleSwitch';
import InputWithLabel from '../../../components/InputWithLabel';
import Modal from '../../../components/Modal';
import TermoDiaDoAmigo from '../../../termosAceite/termoDiaDoAmigo2021'


import { CONTEUDO_API_URL } from '../../../constants/api';
import { FirebaseContext, CurrentUserContext, UploadContext } from '../../../Contexts';
import { useRequest } from '../../../hooks/request';

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getPraca from '../../../utils/getPraca'
import getDeviceInfos from '../../../utils/getDeviceInfos'

import CustomHTTPHandler from '../../../utils/CustomHTTPHandler'

const customHTTPHandler = new CustomHTTPHandler();

const Amigo = () => {
    const [cidades, setCidades] = useState([]);
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false);
    const { S3StartUpload } = useContext(UploadContext)
    const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
    const { currentUser } = useContext(CurrentUserContext)
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [userIdToken, setUserIdToken] = useState('')
    const [hasError, setHasError] = useState(undefined)
    const [uploadProgress, setUploadProgress] = useState(0);
    const [startSend, setStartSend] = useState(false);

    const [formValues, setFormValues] = useState({
        cidade: {
            key: 0,
            nome: '',
            ibge: '',
            praca: ''
        },
        media: [],
        termoAceito: false,
        tituloFoto: '',
        descricaoFoto: ''
    });

    customHTTPHandler.onProgress$.subscribe(progress => {
        const percentComplete = progress.progressEvent.loaded / progress.progressEvent.total * 100;
        setUploadProgress(percentComplete)
    });

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [setFormValues]);

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    }, [setFormValues])

    const disabledButon = useCallback(() => {
        return formValues.media.length === 0
            || formValues.tituloFoto.length === 0
            || formValues.descricaoFoto.length === 0
            || formValues.cidade.nome.length === 0
            || !formValues.termoAceito;
    }, [formValues]);

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength = 0) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;

            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: value}))
            }
        }, []
    );

    const onChangeDropdownEventHandler = useCallback((attribute, cities) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text

        const cidade = cities.find(({ nome }) => nome === value);

        setFormValues(prev => ({...prev, cidade }))
    }, []);

    const [, statusAPI, fetchSave] = useRequest(
        null,
        {
          url: `${CONTEUDO_API_URL}/midias`,
          method: 'post',
          headers: { Authorization: `Bearer ${userIdToken}` },
          data: {
            id_categoria_envio: 38,
            titulo: `${formValues.cidade.praca} - ${formValues.tituloFoto}`,
            descricao: formValues.descricaoFoto,
            midias: formValues.media.map(item => ({ path: item.path, type: item.mediaType }))
          }
        },
        null,
        {
          onComplete: data => onComplete(data)
        }
    );

    useEffect(() => {
        const getFirebaseToken = async () => {
            if(firebaseAuth.currentUser) {
                const token = await firebaseAuth.currentUser.getIdToken()
                setUserIdToken(_ => token)
            }
        }
        getFirebaseToken()
    }, [firebaseAuth.currentUser]);

    useEffect(() => {
        if(statusAPI) {
            if (statusAPI.error) {
                setHasError('Ocorreu um erro para enviar seus dados.')
            }
        }
    }, [statusAPI]);

    const FirestoreStartUpload = useCallback(async () => {
        const firebaseMensagem = {
            formularioCidadeNome: formValues.cidade.nome,
            formularioCidadePraca: formValues.cidade.praca,
            tituloFoto: formValues.tituloFoto,
            descricaoFoto: formValues.descricaoFoto,
            created_at: moment().toDate(),
            id: `${currentUser.uid}-${moment().unix()}`,
            praca: await getPraca(currentUser),
            usr_email: currentUser.email,
            usr_id: currentUser.uid,
            usr_name: currentUser.nome,
            termo: 'dia_do_amigo_2021',
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }
        // REMOVENDO OS DADOS DA IMAGEM LOCAL
        delete firebaseMensagem.media
        delete firebaseMensagem.type

        const diaAmigoDoc = firebaseFirestore.collection('flerken-interatividade').doc('TERMOS_ACEITE').collection('termos')
        try {
            await diaAmigoDoc.doc(firebaseMensagem.id).set(firebaseMensagem)
            console.log('Ddos ado termo enviados com sucesso para o firestore')
        } catch (err) {
            console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
        }
    }, [firebaseFirestore, formValues, currentUser])
    
    const onComplete = async (data) => {
        if (data.status === 200) {
            const parts = data.token.split('.')
    
            if(parts.length < 3) return

            const encodedPayload = parts[1]
            const payload = JSON.parse(atob(encodedPayload))
    
            const files = []
            payload.ids_arquivos.forEach((item, index) => {
                const filename = formValues.media[index].name
                if (filename.length < 2) {
                    console.error('Nome do arquivo de mídia invalido')
                    setHasError('Nome do arquivo de mídia é invalido');
                    return
                }
    
                const typeMatch = filename.match(/\.([^.]*)$/)
                if (!typeMatch) {
                    console.error('typeMatch inválido')
                    setHasError('Nome do arquivo de mídia é inválido');
                    return
                }

                const mediaFormat = formValues.media[index].type
                if (!mediaFormat.includes('image/')) {
                    console.error(`Extensão do arquivo inválido ${mediaFormat}`)
                    setHasError(`Tipo de arquivo não suportado (${mediaFormat}). É permitido somente o envio de arquivos de imagem`);
                    return
                }
        
                const s3Filename = btoa(`{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`)
                const name = `${s3Filename}.${typeMatch[1].toLowerCase()}`
                const file = formValues.media[index]
                files.push({ name, file })
            })
            setUploading(true)
            S3StartUpload(files, setUploading, customHTTPHandler, setHasError)
            FirestoreStartUpload()
            setLoading(false)
        } else {
            console.error('Falha no envio para API de conteudo')
            setHasError('Ocorreu uma falha inesperada');
        }
    };

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json());
            const cidadesObj = [];
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj.push({
                        key,
                        nome: cidade.nome,
                        ibge: cidade.id_ibge,
                        praca: cidade.praca
                    });
                }
            );
            setCidades(cidadesObj);
        }
        loadCidades();
    }, []);

    const onStartSend = () => {
        setLoading(true);
        setStartSend(true);
        fetchSave();
    };

    const renderContent = useCallback(() => {
        return <>
            {!hasError ? (
                <div className="container">
                    <div className="titenquete">Foto enviada com sucesso!</div>
                </div>
            ) : (
                <div className="container">
                    <div className="titenquete">{`Ocorreu um erro ao enviar a sua foto. Por favor, tente novamente.`}</div>
                    <br/>
                    <div className='titenquete'>{hasError}</div>
                </div>
            )}
        </>
    }, [hasError]);

    const renderLoading = useCallback(() => {
        return <>
            <div className="container">
                <div className='titenquete'>
                    <p>A foto está sendo enviada, isto pode demorar alguns minutos.</p>
                </div>
                <div className='titenquete'>
                    <p>Por favor, não feche esta tela até o processo finalizar.</p>
                </div>
            </div>
            <div className="centeringLoadingContainer">
                <Progress type="circle" percent={uploadProgress.toFixed(2)} />
            </div>
        </>
    }, [uploadProgress]);

    return <div className="enqueterpc">
            <div className="imagemdestaque">
                <img src="/imgs/dia_do_amigo.png" alt="banner"/>
            </div>
            {startSend ? (loading || uploading ? renderLoading() : renderContent()) : <div className="container">
                <DropdownWithLabel 
                    title="Escolha a sua cidade"
                    values={cidades.map(({ nome }) => nome)}
                    defaultValue={!!formValues.cidade.nome ? formValues.cidade.key : 'default'}
                    onChange={onChangeDropdownEventHandler('cidade', cidades)}
                />
                <InputWithLabel
                    title="Nome do seu amigo"
                    inputPlaceholder="Escreva o nome do seu amigo aqui"
                    inputValue={formValues.tituloFoto}
                    onChange={onChangeInputEventHandler('tituloFoto', 20)}
                />
                <InputWithLabel
                    className="textInputArea"
                    title="Conte sobre a foto e quem você quer homenagear"
                    inputPlaceholder="Descreva sobre a foto aqui"
                    inputValue={formValues.descricaoFoto}
                    onChange={onChangeInputEventHandler('descricaoFoto', 1000)}
                />
                <MediaGallery medias={formValues.media} />
                <ImagePicker handleSetMedias={handleSetMedia} required={true} />
                <div className="opcenquete align">
                    <ToggleSwitch checked={formValues.termoAceito}
                        setChecked={handleCheckedTermo}
                    />
                    <button type="button" className="btTransparente" 
                        onClick={() => {
                            document.body.scrollTop = 0 // For Safari
                            document.documentElement.scrollTop = 0 // FF, GC, Opera
                            document.body.style.overflowY = 'hidden';
                            setMostrarTermoModal(_ => true)
                        }}
                    >
                        <span>
                            <strong>Declaro que li e aceito os termos de uso do "Dia do Amigo"</strong>
                        ​</span>
                    </button>
                </div>

                {
                    mostrarTermoModal &&    
                    <Modal
                        setTermoAceito={handleCheckedTermo}
                        setShowModal={setMostrarTermoModal}
                    >
                        <TermoDiaDoAmigo />
                    </Modal>
                }
                <div className="btsalvar" style={{ paddingTop: '20px' }}>
                    <button onClick={onStartSend} className="btsalvar" disabled={disabledButon()}>
                        Enviar foto
                    </button>
                </div>        
            </div>}
    </div>;
};

export default Amigo;
import React, { useContext, useCallback, useState, useEffect } from 'react'

import Loading from '../../../components/Loading'

import { CONTEUDO_API_URL } from '../../../constants/api'
import { FirebaseContext, CurrentUserContext, UploadContext } from '../../../Contexts'
import { useCallbackStatic } from '../../../hooks/utils'
import { useRequest } from '../../../hooks/request'
import moment from 'moment'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getCity from '../../../utils/getCity'
import getPraca from '../../../utils/getPraca'
import getDeviceInfos from '../../../utils/getDeviceInfos'

import CustomHTTPHandler from '../../../utils/CustomHTTPHandler'
import { Progress } from 'antd'

const customHTTPHandler = new CustomHTTPHandler()

const  Base = ({ title, description, mediaID, imagePath, programName, form, setForm, tipoTermo, salvarOk }) => {
    const { S3StartUpload } = useContext(UploadContext)
    // const [customHTTPHandler, setCustomHTTPHandler] = useState(new CustomHTTPHandler())
    const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
    const { currentUser } = useContext(CurrentUserContext)
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [result, setResult] = useState(false)
    const [userIdToken, setUserIdToken] = useState('')
    const [hasError, setHasError] = useState(undefined)
    const [totalVideoSize, setTotalVideoSize] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0)
    
    customHTTPHandler.onProgress$.subscribe(progress => {
        const percentComplete = progress.progressEvent.loaded / progress.progressEvent.total * 100;
        setUploadProgress(percentComplete)
    });

    useEffect(() => {
        const getFirebaseToken = async () => {
            if(firebaseAuth.currentUser) {
                const token = await firebaseAuth.currentUser.getIdToken()
                setUserIdToken(_ => token)
            }
        }
        getFirebaseToken()
    }, [firebaseAuth.currentUser])
    
    
    const back = useCallbackStatic(() => {
        setResult(false)
    })

    let descricao = form.descricaoFoto;

    if(!!form.nomeCompletoProfessor) {
        descricao = `Nome Completo do Professor: ${form.nomeCompletoProfessor}\nCidade: ${form.cidade}\nEscola: ${form.escola}\nTurma que Leciona: ${!!form.turma ? form.turma : form.anoTurma}\nDescrição:\n${form.descricao}`;
    }

    const [, statusAPI, fetchSave] = useRequest(
        null,
        {
          url: `${CONTEUDO_API_URL}/midias`,
          method: 'post',
          headers: { Authorization: `Bearer ${userIdToken}` },
          data: {
            id_categoria_envio: mediaID,
            titulo: form.tituloFoto,
            descricao,
            midias: form.media.map(item => ({ path: item.path, type: item.mediaType }))
          }
        },
        null,
        {
          onComplete: data => onComplete(data)
        }
    )    

    const cleanDataState = useCallback(() => {
        const cleanedForm = {}
        Object.keys(form).forEach(key => {
            cleanedForm[`${key}`] = ''
        })
        cleanedForm.media = []
        cleanedForm.type = form.type
        setForm(cleanedForm)
        setResult(true)
        setLoading(false)
        setUploadProgress(0);
        setUploading(false);
        setTotalVideoSize(0);
        setHasError(undefined);
    }, [form, setForm])

    useEffect(() => {
        if(statusAPI) {
            if (statusAPI.error) {
                cleanDataState()
                setHasError('Ocorreu um erro para enviar seus dados.')
            }
        }
    }, [cleanDataState, statusAPI])

    const FirestoreStartUpload = useCallback(async () => {
        const firebaseMensagem = {
            ...form,
            created_at: moment().toDate(),
            id: `${moment().unix()}`,
            // id: `${currentUser.uid}-${moment().unix()}`,
            // praca: await getPraca(currentUser),
            // usr_email: currentUser.email,
            // usr_id: currentUser.uid,
            // usr_name: currentUser.nome,
            termo: tipoTermo,
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }
        // REMOVENDO OS DADOS DA IMAGEM LOCAL
        delete firebaseMensagem.media
        delete firebaseMensagem.type

        const televisandoDoc = firebaseFirestore.collection('flerken-interatividade').doc('TELEVISANDO')
        const typeTelevisandoCollection = form.type === 'Familia' ? televisandoDoc.collection('familia') : televisandoDoc.collection('professor')
        try {
            await typeTelevisandoCollection.doc(firebaseMensagem.id).set(firebaseMensagem)
            console.log('Ddos ado termo enviados com sucesso para o firestore')
        } catch (err) {
            console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
        }
    }, [firebaseFirestore, form, tipoTermo])

    const send = useCallback( async () => {
        setLoading(true)
        getDeviceInfos()
        fetchSave()
    }, [fetchSave])

    const onComplete = async (data) => {
        if (data.status === 200) {
            const parts = data.token.split('.')
    
            if(parts.length < 3) return

            const encodedPayload = parts[1]
            const payload = JSON.parse(atob(encodedPayload))
    
            const files = []
            payload.ids_arquivos.forEach((item, index) => {
                const filename = form.media[index].name
                if (filename.length < 2) {
                    console.error('Nome do arquivo de mídia invalido')
                    setHasError('Nome do arquivo de mídia é invalido');
                    cleanDataState()
                    return
                }
    
                const typeMatch = filename.match(/\.([^.]*)$/)
                if (!typeMatch) {
                    console.error('typeMatch inválido')
                    setHasError('Nome do arquivo de mídia é inválido');
                    cleanDataState()
                    return
                }

                const mediaFormat = form.media[index].type
                if (!mediaFormat.includes('image/')
                    && !mediaFormat.includes('video/')
                ) {
                    console.error(`Extensão do arquivo inválido ${mediaFormat}`)
                    setHasError(`Tipo de arquivo não suportado (${mediaFormat}). É permitido somente o envio de arquivos .mp4`);
                    cleanDataState()
                    return
                }
        
                const s3Filename = btoa(`{"u":"${payload.id_usuario_app}","a":"${item}","e":"${payload.id_envio}"}`)
                const name = `${s3Filename}.${typeMatch[1].toLowerCase()}`
                const file = form.media[index]
                files.push({ name, file })
                setTotalVideoSize(prev => prev + file.size);
            })
            setUploading(true)
            S3StartUpload(files, setUploading, customHTTPHandler, setHasError)
            FirestoreStartUpload()
            setLoading(false)
            setResult(true);
        } else {
            console.error('Falha no envio para API de conteudo')
            setHasError('Ocorreu uma falha inesperada');
            cleanDataState()
        }
    }

    const renderContent = useCallback(() => {
        const onBack = () => {
            cleanDataState();
            back();
        };

        return !result ? <>
            <div className="container">
                <div className="titenquete">{title}</div>
                <div className="descenquete">{description()}</div>
                <div className="btsalvar">
                    <button onClick={send} className="btsalvar"  disabled={salvarOk}>
                        Enviar
                    </button>
                </div>
            </div>
        </> : <>
            {!hasError ? (
                <div className="container">
                    <div className="titenquete">{`${form.type === 'Familia' ? `Foto enviada` : `Video enviado`} com sucesso!`}</div>
                    <br/>
                    <br/>
                    <div className="btsalvar"><button onClick={onBack}>{`Enviar ${form.type === 'Familia' ? ` nova foto` : ` novo video`}`}</button></div>
                </div>
            ) : (
                <div className="container">
                    <div className="titenquete">{`Ocorreu um erro ao enviar ${form.type === 'Familia' ? ` sua foto` : ` seu video`}. Por favor, tente novamente.`}</div>
                    <br/>
                    <div className='titenquete'>{hasError}</div>
                    <br/>
                    <div className="btsalvar"><button onClick={onBack}>{`Enviar ${form.type === 'Familia' ? ` nova foto` : ` novo video`}`}</button></div>
                </div>
            )}
        </>
    }, [result, hasError, form.type, back, title, description, salvarOk, send, cleanDataState])

    const renderLoading = useCallback(() => {
        const isPhoto = form.type === 'Familia';
        const showTotalSize = totalVideoSize > 0 ? `de ${(totalVideoSize / 1024 / 1024).toFixed(2)} MB` : '';
        return <>
            <div className="container">
                <div className='titenquete'>
                    <p>{isPhoto ? `A foto` : `O video`} {!isPhoto && showTotalSize} está sendo {isPhoto ? 'enviada' : 'enviado'}, isto pode demorar alguns minutos.
                    </p>
                </div>
                <div className='titenquete'>
                    <p>Por favor, não feche esta tela até o processo finalizar.</p>
                </div>
            </div>
            <div className="centeringLoadingContainer">
                <Progress type="circle" percent={uploadProgress.toFixed(2)} />
            </div>
                {/* <img src="/imgs/giphy.gif" className="imgLoading"/> */}
        </>
    }, [form.type, uploadProgress, totalVideoSize])

    return (
        <React.Fragment>
            <div className="enqueterpc">
                <div className="imagemdestaque">
                    <img src={imagePath} alt="banner"/>
                </div>
                {loading || uploading ? renderLoading() : renderContent()}
            </div>
        </React.Fragment>
    )
}

export default Base

import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Divider } from 'antd';

import ImagePicker from '../../../components/ImagePicker'
import InputWithLabel from '../../../components/InputWithLabel'
import MediaGallery from '../../../components/MediaGallery'
import Modal from '../../../components/Modal'
import ToggleSwitch from '../../../components/ToggleSwitch'
import DropdownWithLabel from '../../../components/DropdownWithLabel'

import TermoMarchaJesus2022 from '../../../termosAceite/termoMarchaJesus2022'

import Base from './Base'

import { checkUnfilledFields } from '../../../utils/helpers';

import parsers from '../../../utils/parsers/index'
import compareCities from '../../../utils/compareCities'

const characterLimit = 255

const MarchaJesus2022 = () => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
    const [cidades, setCidades] = useState()

    const [formValues, setFormValues] = useState({
        titulo: '',
        descricao: '',
        cidade: '',
        praca: '',
        termoAceito: false,
        media: []
    })

    useEffect(() => {
        const loadCidades = async () => {
            const cidades = await fetch('/cidades.json').then(res => res.json())
            const cidadesObj = {}
            Object.entries(cidades).forEach(
                ([key, cidade]) => {
                    cidadesObj[`${cidade.nome}`] = cidade.nome
                }
            )
            setCidades(cidadesObj)
        }
        loadCidades()
    }, [])

    useEffect(() => {
        const loadPraca = async () => {
            const importedCities = await fetch('/cidades.json').then(res => res.json())
            const cidade = Object.values(importedCities).find(cidade =>
                compareCities(formValues.cidade, cidade.nome)
            )
            if(!cidade) return;
            setFormValues(prev => ({...prev, praca: cidade.praca}))
        }
        loadPraca()
    }, [cidades, formValues.cidade])

    const onChangeDropdownEventHandler = useCallback((attribute) => (event) => {
        const {options, selectedIndex} = event.target
        const value = options[selectedIndex].text
        setFormValues(prev => ({...prev, [`${attribute}`]: value}))
    }, [])

    const onChangeInputEventHandler = useCallback(
        (attribute, maxLength) => (event) => {
            const value = event.target.value;
            const valueLength = value.length;
            const parseType = event.target.dataset.parser
            const parsedValue = parseType ? parsers[parseType](value) : value
            if (valueLength <= maxLength) {
                setFormValues(prev => ({...prev, [`${attribute}`]: parsedValue}))
            }
        }, []
    );

    const handleCheckedTermo = useCallback(() => {
        setFormValues(prev => ({...prev, termoAceito: !prev.termoAceito}))
    }, [])

    const handleSetMedia = useCallback((media) => {
        setFormValues(prev => ({...prev, media: [media]}))
    }, [])

    const salvarOk = useMemo(() => {
        const {
            titulo,
            descricao,
            cidade,
            termoAceito,
            media,
        } = formValues;

        const requiredFields = checkUnfilledFields({
          titulo: {
              value: titulo,
              minLength: 0
          },
          descricao: {
              value: descricao,
              minLength: 0
          },
          cidade: {
              value: cidade,
              minLength: 0
          },
        });

        return !(
            termoAceito
            && requiredFields.length === 0
            && media.length !== 0
        )
    }, [formValues])

    return (
        <Base
            title="Marcha para Jesus"
            description={() => (
                <div className="container">
                    <InputWithLabel
                        title={"Título"}
                        inputPlaceholder="Escreva o título da sua foto aqui..."
                        subTitle="Campo obrigatório"
                        inputValue={formValues.titulo}
                        onChange={onChangeInputEventHandler('titulo', characterLimit)}
                    />
                    <InputWithLabel
                        title={"Descrição"}
                        inputPlaceholder={"Escreva a descrição da sua foto aqui..."}
                        subTitle="Campo obrigatório"
                        inputValue={formValues.descricao}
                        onChange={onChangeInputEventHandler('descricao', characterLimit)}
                    />
                    <DropdownWithLabel
                        title={'Cidade'}
                        subTitle="Campo obrigatório"
                        values={cidades}
                        onChange={onChangeDropdownEventHandler('cidade')}
                    />

                    <MediaGallery medias={formValues.media} />
                    <ImagePicker handleSetMedias={handleSetMedia} required={true} title='Selecione a sua foto da marcha'/>

                    <Divider />

                    <div className="opcenquete align-left">
                        <ToggleSwitch checked={formValues.termoAceito}
                            setChecked={handleCheckedTermo}
                            name='termoAceito'
                        />
                        <button type="button" className="btTransparente"
                            onClick={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // FF, GC, Opera
                                document.body.style.overflowY = 'hidden';
                                setMostrarTermoModal(_ => true)
                            }}
                        >
                            <span>
                               <strong>Li e aceito os termos de uso.</strong>
                            ​</span>

                        </button>

                    </div>

                    {
                        mostrarTermoModal &&
                        <Modal
                            setTermoAceito={handleCheckedTermo}
                            setShowModal={setMostrarTermoModal}
                        >
                            <TermoMarchaJesus2022 />
                        </Modal>
                    }
                </div>
            )}
            imagePath="/imgs/marcha-jesus-2022.png"
            programName="Marcha para Jesus"
            form={formValues}
            setForm={setFormValues}
            salvarOk = {salvarOk}
            tipoTermo="marchaJesus2022"
            mediaID={51}
        />
    )
}

export default MarchaJesus2022

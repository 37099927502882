import InscricaoPessoal from '../formularios/InscricaoPessoal'
import InscricaoReceita from '../formularios/InscricaoReceita'
import DetalhesReceita from '../formularios/DetalhesReceita'
import FotoReceita from '../formularios/FotoReceita'

export const Components = {
  0: InscricaoPessoal,
  1: InscricaoReceita,
  2: DetalhesReceita,
  3: FotoReceita
}

export const termoCaminhosDoCampo2024 = 'termoCaminhosDoCampo2024'

export const categoriaReceita = {
  doce: 62,
  salgado: 63
}


export const dificuldadeReceita = {
  'facil': 'Fácil',
  'medio': 'Médio',
  'dificil': 'Difícil'
}


export const consumoTemperaturaReceita = {
  'gelado': 'Gelado' ,
  'quente': 'Quente' ,
  'gelado ou quente': 'Gelado ou Quente'
}

export const preferenciaClimaticaReceita = {
  'frios': 'Frios' ,
  'quentes': 'Quentes' ,
  'ambos': 'Ambos'
}

export const consumoReceita = {
  'assado': 'Assado' ,
  'cozido': 'Cozido' ,
  'cru': 'Cru' ,
  'frito': 'Frito'
}


import React, { useRef } from 'react'

const DropdownWithLabel = ({title, subTitle='', values, onChange, required=false, defaultValue = 'default', onBlur = () => {}}) => {
    const refDropdown = useRef(null)
    const classInvalidField = refDropdown.current?.value.length === 1 ? "" : "invalid"
    return (
    <>
        <p className="title">
            {title}
            {subTitle &&
            <span className="subTitle">
                {`(${subTitle})`}
            </span>
            }
        </p>
        {
            values ?
            <select ref={refDropdown} onChange={onChange} defaultValue={defaultValue} className={`dropdown ${required && classInvalidField}`} onBlur={onBlur}>
            <option disabled value={'default'}> Selecione uma opção </option>
            {Object.entries(values).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
                ))}
            </select>
                :
            <span>
                Carregando dados
            </span>
        }
    </>
    )
}

export default DropdownWithLabel

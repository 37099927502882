import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'



import * as Sentry from '@sentry/react'
if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    // integrations: [
    //   new Sentry.BrowserTracing({
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    //   }),
    //   new Sentry.Replay()
    // ],
    // Performance Monitoring
    environment: process.env.NODE_ENV,
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

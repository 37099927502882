import React from 'react';

const TermoFantasmaNightRun3 = () => (
    <>
      <p>Promoção válida de 28/02 a 07/03/2022 – Voucher com 50% de desconto para inscrição Fantasma Night Run III.</p>

<p>Ao me cadastrar nesta promoção estou ciente que o voucher é pessoal e exclusivo para o usuário cadastrado no APP.</p>

<p>Você da RPC, com direito único e exclusivamente à desconto para inscrição da Fantasma Night Run III via site <a target="_blank" href="https://www.cavaliproeventos.com.br/">https://www.cavaliproeventos.com.br/</a>. </p>

<p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoFantasmaNightRun3

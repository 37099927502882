import React from 'react'

const VideoPicker = ({setMedias, handleSetMedias = undefined, required = false}) => {
    return (
        <>
            <button className="btMedia"
                type="button"
                onClick={() => document.getElementById('filePicker').click()}>
                    Selecione seu vídeo
                    {required && 
                        <> 
                            <br/>
                            <span className='subTitle'>
                                (Campo obrigatório)
                            </span>
                        </>
                    }
            </button>
            <input className="margin"
                id="filePicker"
                type="file"
                accept="video/mp4,video/x-m4v"
                alt="Selecionar um vídeo"
                //O parametro multiple tem que estar configurado para multiple para evitar que o iOS faca compressao do video.
                multiple={true}
                onChange={(e) => {
                    const video = e.target.files[0]

                    var reader = new FileReader();
                    reader.onload = (event) => {
                        video.binaryString = event.target.result
                    }
                    reader.readAsBinaryString(video)

                    video.src = URL.createObjectURL(video)
                    video.path = video.src.split('/').slice(-1)[0]
                    video.mediaType="video"
                    if(handleSetMedias) {
                        handleSetMedias(video)
                    } else {
                        setMedias(_ => [video])
                    }
                }} 
                style={{display: "none" }}
            />
            <p className="subTitle">Somente arquivos .mp4 são suportados</p>
        </>
    )
}

export default VideoPicker
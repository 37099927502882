import moment from 'moment'
import getDeviceInfos from '../../../../../utils/getDeviceInfos'
import getPraca from '../../../../../utils/getPraca'
import getClientIPv4 from '../../../../../utils/getPublicIPv4'
import { termoVisitanteRPC } from '../../constants'

export const firebaseMessageUpload = async (formValues, currentUser) =>{
  const firebaseMensagemTermo = {
    created_at: moment().toDate(),
    id: `${currentUser.uid}-${moment().unix()}`,
    praca: await getPraca(currentUser),
    usr_email: currentUser.email,
    usr_id: currentUser.uid,
    usr_name: currentUser.nome,
    termo: termoVisitanteRPC,
    ipV4: await getClientIPv4(),
    device_infos: getDeviceInfos(),
  }

  const firebaseMensagem =  {
    formularioNomeCompleto: formValues.nomeCompleto,
    formularioCpf: formValues.cpf.replace(/[^\d]/g, ''),
    formularioEmail: formValues.email,
    formularioTelefone: formValues.telefone,
    formularioDataVisita: moment(formValues.dataVisita).toDate(),
    endereco: formValues.endereco,
    cidade: formValues.cidade,
    estado: formValues.estado,
    visitaConfirmada: formValues.visitaConfirmada,
    responsaveis: {
      nomeCompletoPrimeiroResponsavel: formValues.nomeCompletoPrimeiroResponsavel,
      rgPrimeiroResponsavel: formValues.rgPrimeiroResponsavel,
      cpfPrimeiroResponsavel: formValues.cpfPrimeiroResponsavel,
      emailPrimeiroResponsavel: formValues.emailPrimeiroResponsavel,
      telefonePrimeiroResponsavel: formValues.telefonePrimeiroResponsavel,
      enderecoPrimeiroResponsavel: formValues.endereco,
      cidadePrimeiroResponsavel: formValues.cidade,
      nomeCompletoSegundoResponsavel: formValues.nomeCompletoSegundoResponsavel,
      rgSegundoResponsavel: formValues.rgSegundoResponsavel,
      cpfSegundoResponsavel: formValues.cpfSegundoResponsavel,
      emailSegundoResponsavel: formValues.emailSegundoResponsavel,
      telefoneSegundoResponsavel: formValues.telefoneSegundoResponsavel,
      enderecoSegundoResponsavel: formValues.endereco,
      cidadeSegundoResponsavel: formValues.cidade,
    },
    created_at: firebaseMensagemTermo.created_at,
    id: firebaseMensagemTermo.id,
    usr_id: currentUser.uid,
    linguagem_ofensiva: currentUser.linguagem_ofensiva || null,
    //praca: await getPraca(currentUser),
    termo: termoVisitanteRPC,
  }

  return { firebaseMensagem, firebaseMensagemTermo }
}

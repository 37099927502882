import React from 'react';

const TermoShowCabare2022 = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – SHOW CABARÉ - Leonardo e Bruno & Marrone</strong></p>
        <p>Ao me cadastrar nesta promoção estou ciente que o código é pessoal e exclusivo para o usuário cadastrado no APP "Você na RPC", com direito único e exclusivamente ao código de inscrição via site <a target="_blank" href="https://www.diskingressos.com.br/event/23">https://www.diskingressos.com.br/event/23</a>.</p>
        <p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoShowCabare2022;

import React from 'react'
import { cpfValidator } from '../screens/Programas/ProgramaVisitas/utils'
import allMasks from '../utils/masks'

const InputWithLabel = ({
  title,
  subTitle,
  onChange,
  inputValue,
  minInputLength = '0',
  maxInputLength = '200',
  inputPlaceholder = `${title}...`,
  className = 'textInput',
  required = false,
  readonly = false,
  onBlur = () => {},
  mask = false,
  type = 'text',
  parser = undefined,
  ...rest
}) => {
  const isNotCpf =
    inputValue.length >= minInputLength &&
    mask === 'cpf' &&
    !cpfValidator(inputValue)
  return (
    <div>
      <p className="title">
        {title}
        {subTitle && <span className="subTitle">{`(${subTitle})`}</span>}
      </p>
      {className === 'textInputArea' ? (
        <textarea
          className={className + `${isNotCpf ? ' invalidCpf' : ''}`}
          placeholder={inputPlaceholder}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          minLength={minInputLength}
          maxLength={maxInputLength}
          required={required}
          value={inputValue}
        />
      ) : (
        <input
          className={className + `${isNotCpf ? ' invalidCpf' : ''}`}
          type={type}
          placeholder={inputPlaceholder}
          value={mask ? allMasks[mask](inputValue) : inputValue}
          onChange={(e) => onChange(e)}
          minLength={minInputLength}
          maxLength={maxInputLength}
          required={required}
          readOnly={readonly}
          onBlur={onBlur}
          data-parser={parser}
          {...rest}
        />
      )}
      {inputValue.length > 0 && inputValue.length < minInputLength && (
        <span className="invalidInput">
          Necessário no mínimo {minInputLength} caracteres
        </span>
      )}
      {inputValue.length >= minInputLength &&
        mask === 'cpf' &&
        !cpfValidator(inputValue) && (
          <span className="invalidInput">Digite um CPF válido</span>
        )}
    </div>
  )
}

export default InputWithLabel

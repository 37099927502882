import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from 'react'
import { DatePicker, Divider, Radio } from 'antd'
import moment from 'moment'

import InputWithLabel from '../../../../components/InputWithLabel'
import Modal from '../../../../components/Modal'
import ToggleSwitch from '../../../../components/ToggleSwitch'

import TermoDireStraitsLegacy from '../../../../termosAceite/termoDireStraitsLegacy'

import Base from './Base'

import { FirebaseContext } from '../../../../Contexts'

const characterLimit = 255

const DireStraitsLegacy = () => {
  const { firebaseFirestore: db } = useContext(FirebaseContext)

  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)
  const [availableTicketsAmmount, setAvailableTicketsAmmount] = useState([])
  const [availableTicketsAmmountRef, setAvailableTicketsAmmountRef] = useState()

  const [formValues, setFormValues] = useState({
    termoAceito: false
  })

  const handleCheckedTermo = useCallback(() => {
    setFormValues((prev) => ({ ...prev, termoAceito: !prev.termoAceito }))
  }, [])

  const salvarOk = useMemo(() => {
    const {
      termoAceito
    } = formValues

    return !(
      termoAceito && availableTicketsAmmount > 0
    )
  }, [formValues, availableTicketsAmmount])

  useEffect(() => {
    const getAvailableTicketsAmmount = async () => {
      const docRef = await db
        .collection('sistema-reserva-tickets')
        .doc('dire-straits-legacy')

      const data = await (await docRef.get()).data()

      setAvailableTicketsAmmountRef(docRef)
      setAvailableTicketsAmmount(data.quantidadeTicketsDisponiveis)
    }
    getAvailableTicketsAmmount()
  }, [])

  return (
    <Base
      description={() => (
        <div className="container">
          <div className="opcenquete align-left">
            <ToggleSwitch
              checked={formValues.termoAceito}
              setChecked={handleCheckedTermo}
              name="termoAceito"
            />
            <button
              type="button"
              className="btTransparente"
              onClick={() => {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0 // FF, GC, Opera
                document.body.style.overflowY = 'hidden'
                setMostrarTermoModal(() => true)
              }}
            >
              <span className="texto-termo">
                Declaro que li e aceito os <strong>termos de privacidade</strong> do evento
                <strong> Dire Straits Legacy</strong>.
              </span>
            </button>
          </div>
          <div className="texto-atencao" style={{ marginTop: '14px' }}>
            {!formValues.termoAceito && (
              <span>
                <strong>Atenção</strong>: Neste caso (
                <strong>NÃO ACEITE</strong>) não será possível sua participação
                no evento<strong> DIRE STRAITS LEGACY</strong>. Por
                favor, em caso de dúvidas, envie uma mensagem para o aplicativo
                “Você na RPC”.
              </span>
            )}
            <span>
              <br /><br />Cupom limitado ao número de ingressos disponíveis à venda.
            </span>
          </div>

          {mostrarTermoModal && (
            <Modal
              setTermoAceito={handleCheckedTermo}
              setShowModal={setMostrarTermoModal}
            >
              <TermoDireStraitsLegacy />
            </Modal>
          )}
        </div>
      )}
      title="Dire Straits Legacy"
      imagePath="/imgs/cupons/show-dsl.png"
      programName="Dire Straits Legacy"
      form={formValues}
      setForm={setFormValues}
      salvarOk={salvarOk}
      tipoTermo="direStraitsLegacy2022"
      availableTicketsAmmountRef={availableTicketsAmmountRef}
      db={db}
      availableTicketsAmmount={availableTicketsAmmount}
    />
  )
}

export default DireStraitsLegacy

import React from 'react'

import { ReactComponent as FacebookSVG } from '../assets/images/facebook.svg'
import { ReactComponent as InstagramSVG } from '../assets/images/instagram.svg'
import { ReactComponent as LinkedinSVG } from '../assets/images/linkedin.svg'

const Footer = () => {
  return (
    <div className="footer">
      <div className="subtitle">
        <h6>Fique por dentro das novidades da RPC:</h6>
      </div>
      <div className="social-media">
        <a
          href="https://www.facebook.com/rpcparana/?locale=pt_BR"
          target="_blank"
        >
          <FacebookSVG />
        </a>
        <a href="https://www.instagram.com/rpcparana/" target="_blank">
          <InstagramSVG />
        </a>
        <a
          href="https://br.linkedin.com/company/rede-paranaense-de-comunica-o"
          target="_blank"
        >
          <LinkedinSVG />
        </a>
      </div>
    </div>
  )
}

export default Footer

import React from 'react'

const TermoShowCabareSorteio2022 = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO - Show Cabaré 2022 - Sorteio</strong></p>
        <p><a href="https://rpc-multisite.s3.amazonaws.com/wp-content/uploads/sites/2/2021/06/29130459/AUT_Certificado_autorizacao_050215092022.pdf" target="_blank">Certificado de Autorização</a></p>
        <p>Ao enviar meus dados e clicar no aceite abaixo, estou de acordo com os termos/condições acima, bem como com os termos do REGULAMENTO do sorteio SHOW CABARÉ 2022 (<a href="https://rpc-multisite.s3.amazonaws.com/wp-content/uploads/sites/2/2021/06/29130459/AUT_Regulamento_0202204628.pdf" target="_blank">Regulamento</a>) das Políticas de Privacidade e Proteção de Dados da GLOBO (<a href="https://privacidade.globo.com/privacy-policy" target="_blank">privacidade.globo.com/privacy-policy</a>) e da RPC (<a href="https://www.negociosrpc.com.br/politica-de-privacidade-rpc/" target="_blank">negociosrpc.com.br/politica-de-privacidade-rpc</a>), e aceito receber informações sobre o sorteio SHOW CABARÉ 2022, bem como de outros projetos, cursos e/ou outras ações promovidas e apoiadas pela RPC a partir da presente data em meu e-mail e telefone.</p>
        <p></p>
        <p><strong>( ) Aceito</strong> os termos do Termo de Aceite acima.</p>
        <p><strong>( ) NÃO ACEITO</strong> os termos do Termo de Aceite acima.<br /><br /><strong>Atenção:</strong> Neste caso (NÃO ACEITE) não será possível sua participação no sorteio SHOW CABARÉ 2022. Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo “Você na RPC”.</p>
    </>
)

export default TermoShowCabareSorteio2022

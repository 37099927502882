import React, { useState, useEffect, useCallback, useContext } from "react";

import { CurrentUserContext, FirebaseContext, ParamsContext } from '../Contexts'
import { useCallbackStatic } from '../hooks/utils'

import { firebaseApp } from '../constants/firebase'

import Loading from '../components/Loading'

const FirebaseProvider = ({ children }) => {
    const { token } = useContext(ParamsContext)
    const [state, setState] = useState({
        load: false,
        currentUser: null,
        authenticated: false,
    })
    const firebaseAuth = useCallbackStatic(firebaseApp.auth())
    const firebaseFunctions = useCallbackStatic(firebaseApp.functions())
    const firebaseFirestore = useCallbackStatic(firebaseApp.firestore())


    useEffect(() => {
        if (token) {
            firebaseAuth.signInWithCustomToken(token).then(() => {
                console.log('signInWithCustomToken:success');
                const getUser = firebaseFunctions.httpsCallable('getUser')
                getUser().then((result) => {
                    console.log('signInWithCustomToken:getUser', result.data);
                    setState({
                        load: true,
                        currentUser: result.data,
                        authenticated: true,
                    })
                })

            }).catch((error) => {
                console.log('signInWithCustomToken:error', error);
                setState({
                    load: true,
                    currentUser: null,
                    authenticated: false,
                }) 
            });
        } else {
            setState({
                load: true,
                currentUser: null,
                authenticated: false,
            }) 
        }
    }, [firebaseAuth, firebaseFunctions, token]);


    const CurrentUserContextValue = {
        currentUser:state.currentUser,
    }

    const FirebaseContextValue = {
        firebaseAuth,
        firebaseFunctions,
        firebaseFirestore,
    }

    const renderContent = useCallback(() => {
        if (state.load) {
            // if (state.authenticated) {
            //     return children
            // }
            // return null
            return children
        }
        return (
            <Loading />
        )
    }, [children, state.load])

    return (
        <FirebaseContext.Provider value={FirebaseContextValue}>
            <CurrentUserContext.Provider value={CurrentUserContextValue}>
                {renderContent()}
            </CurrentUserContext.Provider>
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider; 
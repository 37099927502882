import React, { useContext, useCallback, useState } from 'react'
import axios from 'axios'
import moment from 'moment'

import Loading from '../../components/Loading'

import { ATENDIMENTOS_API_URL } from '../../constants/api'
import { FirebaseContext, CurrentUserContext } from '../../Contexts'
import { useCallbackStatic } from '../../hooks/utils'

const Base = ({
  title,
  description,
  imagePath,
  programName,
  characterLimit = 240,
  sendType = 'default',
  customFirebaseCollection = null,
  customFirebaseControlKey = null
}) => {
  const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [mensagem, setMensagem] = useState('')
  const [countMensagem, setCountMensagem] = useState(0)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)

  console.log(currentUser)

  const handleChange = useCallback(
    (event) => {
      const axCount = event.target.value.length
      if (axCount <= characterLimit) {
        setMensagem(event.target.value)
        setCountMensagem(axCount)
      }
    },
    [mensagem]
  )

  const sendMessage = useCallback(() => {
    if (mensagem) {
      if (sendType === 'natal') sendNatal(mensagem)
      else send(mensagem)
    } else {
      alert('Escreva a sua mensagem.')
    }
  }, [mensagem])

  const back = useCallbackStatic(() => {
    setResult(false)
    setMensagem('')
    setCountMensagem(0)
  })

  const sendNatal = useCallbackStatic(async (mensagem) => {
    setLoading(true)

    const messageParams = {}

    if (currentUser.podeEnviarMensagens) {
      const idToken = await firebaseAuth.currentUser.getIdToken()
      const url = `${ATENDIMENTOS_API_URL}/external/mensagens?notSendFirebase=true`
      const headers = { Authorization: `Bearer ${idToken}` }
      const body = {
        mensagem: `Mensagem de Natal:\n\n${mensagem}`
      }
      const result = await axios.post(url, body, { headers })
      const apiMensagem = result.data.rows[0]
      messageParams.created_at = moment(apiMensagem.data_criacao).toDate()
      messageParams.id = apiMensagem.id
    }

    const getCity = () => {
      if (currentUser.cidadeEndereco) {
        if (currentUser.cidadeEndereco.outroEstado) {
          return null
        }
        return currentUser.cidadeEndereco.nome || currentUser.nomeCidade
      }
      return currentUser.nomeCidade || null
    }

    const getPraca = () => {
      if (currentUser.cidadeEndereco) {
        if (currentUser.cidadeEndereco.outroEstado) {
          return 'PRE'
        } else {
          return currentUser.praca
        }
      }
    }

    const firebaseMensagem = {}

    firebaseMensagem.uid = currentUser.uid
    firebaseMensagem.name = currentUser.nome
    firebaseMensagem.phone = currentUser.telefone
    firebaseMensagem.email = currentUser.email
    firebaseMensagem.city = getCity()
    firebaseMensagem.praca = getPraca()
    firebaseMensagem.createdAt = moment().toDate()
    firebaseMensagem.message = mensagem

    if (
      currentUser.linguagem_ofensiva !== '1' &&
      currentUser.linguagem_ofensiva !== 1
    ) {
      const mainCollection = firebaseFirestore
        .collection('especiais')
        .doc('historias')
      const listCollection = mainCollection.collection('natal-2023')
      await listCollection.add(firebaseMensagem)

      const interatividadeJornalismo = firebaseFirestore
        .collection('flerken-control')
        .doc('interatividade-jornalismo-historias')
      await interatividadeJornalismo.update({
        ultimo_id_sincronizado: `${currentUser.uid}-${moment().unix()}`
      })
    }

    setMensagem('')
    setResult(true)
    setLoading(false)
  })

  const send = useCallbackStatic(async (mensagem) => {
    setLoading(true)

    const messageParams = {}

    if (currentUser.podeEnviarMensagens) {
      const idToken = await firebaseAuth.currentUser.getIdToken()
      const url = `${ATENDIMENTOS_API_URL}/external/mensagens?notSendFirebase=true`
      const headers = { Authorization: `Bearer ${idToken}` }
      const body = {
        mensagem: `Mensagem para o ${programName}:\n\n${mensagem}`
      }
      const result = await axios.post(url, body, { headers })
      const apiMensagem = result.data.rows[0]

      messageParams.created_at = moment(apiMensagem.data_criacao).toDate()
      messageParams.id = apiMensagem.id
    } else {
      messageParams.created_at = moment().toDate()
      messageParams.id = `${currentUser.uid}-${moment().unix()}`
    }

    const getCity = () => {
      if (currentUser.cidadeEndereco) {
        if (currentUser.cidadeEndereco.outroEstado) {
          return 'De outro Estado'
        }
        return currentUser.cidadeEndereco.nome || currentUser.nomeCidade
      }
      return currentUser.nomeCidade || null
    }
    const getPraca = async () => {
      if (currentUser.cidadeEndereco) {
        if (
          typeof currentUser.cidadeEndereco.outroEstado === 'boolean' &&
          currentUser.cidadeEndereco.outroEstado
        ) {
          return 'PRE'
        }
        if (currentUser.cidadeEndereco.idIbge) {
          const cidades = await fetch('/cidades.json').then((res) => res.json())
          const cidade = cidades.find(
            (cidade) => cidade.id_ibge === currentUser.cidadeEndereco.idIbge
          )
          if (cidade) {
            return cidade.praca
          }
        }
      }
      return currentUser.praca
    }

    const firebaseMensagem = {
      ativo: true,
      city: getCity(),
      created_at: messageParams.created_at,
      id: messageParams.id,
      message: mensagem,
      tck_id: null,
      title: null,
      praca: await getPraca(),
      usr_avatar: currentUser.avatarUrl,
      usr_email: currentUser.email,
      usr_id: currentUser.uid,
      usr_name: currentUser.nome
    }

    if (
      currentUser.linguagem_ofensiva !== '1' &&
      currentUser.linguagem_ofensiva !== 1
    ) {
      const mainCollection = firebaseFirestore
        .collection('flerken-interatividade')
        .doc('MENSAGENS_JORNALISMO')
      const listCollection = mainCollection.collection(
        customFirebaseCollection !== null
          ? customFirebaseCollection
          : 'mensagens'
      )
      await listCollection.add(firebaseMensagem)

      const interatividadeJornalismo = firebaseFirestore
        .collection('flerken-control')
        .doc(
          customFirebaseControlKey !== null
            ? customFirebaseControlKey
            : 'interatividade-jornalismo-mensagens'
        )
      await interatividadeJornalismo.update({
        ultimo_id_sincronizado: messageParams.id
      })
    }

    setMensagem('')
    setResult(true)
    setLoading(false)
  })

  const renderContent = useCallback(() => {
    if (!result) {
      return (
        <div className="container">
          <div className="titenquete">{title}</div>
          <div className="descenquete">{description()}</div>
          <div className="opcenquete">
            <div className="textareacampoaberto">
              <textarea
                className="campoaberto"
                placeholder="Escreva sua mensagem aqui..."
                value={mensagem}
                onChange={handleChange}
              />
              <div className="textareacampoabertoCount">
                {countMensagem}/{characterLimit}
              </div>
            </div>
          </div>
          <div className="btsalvar">
            <button onClick={sendMessage}>Enviar</button>
          </div>
        </div>
      )
    }
    return (
      <div className="container">
        <div className="titenquete">Mensagem enviada com sucesso!</div>
        <br />
        <br />
        <div className="btsalvar">
          <button onClick={back}>Enviar nova mensagem</button>
        </div>
      </div>
    )
  }, [result, mensagem, countMensagem, sendMessage, handleChange, back])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img src={imagePath} />
        </div>
        {renderContent()}
      </div>
      {loading ? <Loading /> : null}
    </React.Fragment>
  )
}

export default Base

const videos = [
  {
    id: '1',
    title: 'Balé Teatro Guaíra',
    url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/jornalismo360_2.mp4'
  },
  {
    id: '3',
    title: 'Orquestra Sinfônica do Paraná',
    url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/360-3.mp4'
  },
  {
    id: '4',
    title: 'Escola de Dança do CCTG',
    url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/teste360.mp4'
  },
  {
    id: '2',
    title: 'G2, Cia Master de Dança do CCTG',
    url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/video-360.mp4'
  }
  // {
  //   id: '5',
  //   title: 'Escola de Dança do CCTG 10M',
  //   url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/10M.mp4'
  // },
  // {
  //   id: '6',
  //   title: 'Escola de Dança do CCTG 15M',
  //   url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/15M.mp4'
  // },
  // {
  //   id: '7',
  //   title: 'Escola de Dança do CCTG 20M',
  //   url: 'https://s3.amazonaws.com/interatividade.vocenarpc.com.br/teste-video/video/20M.mp4'
  // }
]

export default videos

import React from 'react';
import { useHistory } from "react-router-dom";

const Televisando = () => {
    const history = useHistory()

    return (
        <div className="enqueterpc">
            <div className="imagemdestaque">
                <img src={"/imgs/televisando.png"} alt="banner"/>
            </div>
            <div className="btsalvar" style={{ 
                marginTop: '50px',
                width: '100%'
            }}>
                <button type="button" className="btsalvar" style={{ marginBottom: '50px' }}
                onClick={
                    () => history.push('/televisando/familia')
                }>
                    Sou família Televisando: categoria de alunos - Foto
                </button>
                <a href="/televisando/professor" target="_blank">
                    <button type="button" className="btsalvar">
                        Sou Professor Televisando: Categoria professores - Telejornal
                    </button>
                </a>
               
            </div>
        </div>
    )
}

export default Televisando

/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useContext, useCallback, useState, useEffect } from 'react'

import { FirebaseContext, CurrentUserContext } from '../../../Contexts'
import { useCallbackStatic } from '../../../hooks/utils'

import getDeviceInfos from '../../../utils/getDeviceInfos'
import { uploadFirestore } from './firestore'

const Base = ({
  title,
  description,
  imagePath,
  form,
  setForm,
  tipoTermo,
  salvarOk,
  db,
  availableTicketsAmmount,
  availableTicketsAmmountRef
}) => {
  const { firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)
  const [userIdToken, setUserIdToken] = useState('')
  const [userTicket, setUserTicket] = useState('')
  const [result, setResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(undefined)

  // console.log(userIdToken)

  useEffect(() => {
    const getFirebaseToken = async () => {
      if (firebaseAuth.currentUser) {
        const token = await firebaseAuth.currentUser.getIdToken()
        setUserIdToken(() => token)
      } else {
        setHasError('Usuário não autenticado')
      }
    }
    getFirebaseToken()
  }, [firebaseAuth.currentUser])

  const back = useCallbackStatic(() => {
    setResult(false)
    window.location.reload()
  })

  const cleanDataState = useCallback(() => {
    const cleanedForm = {}
    Object.keys(form).forEach((key) => {
      cleanedForm[`${key}`] = ''
    })
    cleanedForm.termoAceito = false
    setForm(() => cleanedForm)
    setResult(() => true)
    setLoading(() => false)
    setHasError(() => undefined)
  }, [form, setForm])

  // console.log(currentUser)

  const send = useCallback(async () => {
    setLoading(true)
    getDeviceInfos()
    await uploadFirestore({
      db,
      currentUser,
      tipoTermo,
      form,
      setLoading,
      setResult,
      setHasError,
      hasError,
      availableTicketsAmmountRef,
      setUserTicket
    })
    setLoading(false)
  }, [
    db,
    currentUser,
    tipoTermo,
    form,
    setLoading,
    setResult,
    setHasError,
    hasError,
    availableTicketsAmmountRef
  ])

  const onBack = useCallback(() => {
    cleanDataState()
    back()
  }, [back, cleanDataState])

  const renderError = useCallback(
    () => (
      <div className="container">
        <div className="titenquete">
          <p>Ocorreu um erro ao gerar seu ticket.</p>
          <p>{hasError}</p>
          <p>Por favor, tente novamente.</p>
        </div>
        <div className="btsalvar">
          <button onClick={onBack}>Tentar novamente</button>
        </div>
      </div>
    ),
    [hasError, onBack]
  )

  const renderContent = useCallback(() => {
    const showSendFormComponent = (
      <>
        <div className="container">
          <div className="descenquete">{description()}</div>
          <div className="btsalvar">
            <button onClick={send} className="btsalvar" disabled={salvarOk}>
              {availableTicketsAmmount > 0 ? 'Gerar Ticket' : 'Quantidade diária de tickets esgotado'}
            </button>
          </div>
        </div>
      </>
    )

    const showSuccessComponent = (
      <div className="container">
        <div className="titenquete">
          {`
          Ticket gerado com sucesso!
          Seu ticket é ${userTicket}
          `}
        </div>
      </div>
    )

    return !result ? showSendFormComponent : showSuccessComponent
  }, [title, description, send, salvarOk, result, userTicket])

  const renderLoading = useCallback(() => {
    return (
      <>
        <div className="container">
          <div className="titenquete">
            <p>Seu ticket está sendo gerado.</p>
          </div>
          <div className="titenquete">
            <p>Por favor, não feche esta tela até o processo finalizar.</p>
          </div>
        </div>
      </>
    )
  }, [loading])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        <div className="imagemdestaque">
          <img src={imagePath} alt="banner" />
        </div>
        {hasError ? renderError() : loading ? renderLoading() : renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base

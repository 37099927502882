import React, { useCallback, useMemo } from 'react'
import { Divider } from 'antd'

import parsers from '../../../../utils/parsers'
import InputWithLabel from '../../../../components/InputWithLabel'
import DropdownWithLabel from '../../../../components/DropdownWithLabel'

import { checkUnfilledFields } from '../../../../utils/helpers'

const characterLimit = 255

const DetalhesReceita = (props) => {
  const [formValues, setFormValues] = props.form
  const { setCurrentStep } = props

  const onChangeDropdownEventHandler = useCallback(
    (attribute) => (event) => {
      const {options, selectedIndex} = event.target
      const value = options[selectedIndex].text
      setFormValues(prev => ({...prev, [`${attribute}`]: value}))
  }, [])

  const onNumberChange = useCallback((value, field, min, max) => {
    const parsedValue = +value
    const newValue = parsedValue >= min && parsedValue <= max ? parsedValue : ''

    setFormValues(prev => ({ ...prev, [field]: newValue }))
  }, [])

  const isNextButtonDisabled = useMemo(() => {
    const {
        receitaPorcoes,
        receitaTempoCozimentoHoras,
        receitaTempoCozimentoMinutos,
        receitaNivelDificuldade,
        receitaDiasQuentesFrios,
        receitaPratoAssadoCozido,
    } = formValues;

    const requiredFields = checkUnfilledFields({
        receitaPorcoes: {
            value: receitaPorcoes,
            minLength: 0
        },
        receitaTempoCozimentoHoras: {
            value: receitaTempoCozimentoHoras,
            minLength: 0
        },
        receitaTempoCozimentoMinutos: {
            value: receitaTempoCozimentoMinutos,
            minLength: 0
        },
        receitaNivelDificuldade: {
            value: receitaNivelDificuldade,
            minLength: 1
        },
        receitaDiasQuentesFrios: {
            value: receitaDiasQuentesFrios,
            minLength: 1
        },
        receitaPratoAssadoCozido: {
            value: receitaPratoAssadoCozido,
            minLength: 1
        }
    });

    return !(requiredFields.length === 0)
  }, [formValues])

  return <>
     <p style={{ margin: 0 }}>Detalhes da receita</p>
     <Divider style={{ margin: '0px 0px 10px' }} />
     <p style={{ fontWeight: 700, margin: 0 }}>Qual o tempo de cozimento para a receita?</p>
     <div style={{
        display: 'flex',
        flexDirection: 'row'
      }}>
        <InputWithLabel
            inputPlaceholder="Em horas"
            type="number"
            inputValue={formValues.receitaTempoCozimentoHoras}
            onChange={event => onNumberChange(event.target.value, 'receitaTempoCozimentoHoras', 1, 9)}
            parser='onlyNumbers'
            style={{ width: '100px', marginRight: '10px' }}
            required
        />
        <InputWithLabel
            inputPlaceholder="Em minutos"
            type="number"
            inputValue={formValues.receitaTempoCozimentoMinutos}
            onChange={event => onNumberChange(event.target.value, 'receitaTempoCozimentoMinutos', 1, 999)}
            parser='onlyNumbers'
            style={{ width: '100px' }}
            required
        />
    </div>
     <InputWithLabel
        title="A receita rende quantas porções"
        subTitle="Campo obrigatório"
        inputPlaceholder="porções"
        type="number"
        inputValue={formValues.receitaPorcoes}
        onChange={event => onNumberChange(event.target.value, 'receitaPorcoes', 1, 99)}
        parser='onlyNumbers'
        style={{ width: '100px', marginRight: '10px' }}
        required
    />
     <DropdownWithLabel
        title="Qual o nível de dificuldade da receita?"
        subTitle="Campo obrigatório"
        values={['fácil', 'médio', 'difícil']}
        onChange={onChangeDropdownEventHandler('receitaNivelDificuldade')}
        required={formValues.receitaNivelDificuldade.length === 0}
    />
     <DropdownWithLabel
        title="Para dias quentes, frios?"
        subTitle="Campo obrigatório"
        values={['frios', 'quentes', 'tanto para dias quentes quanto para frios']}
        onChange={onChangeDropdownEventHandler('receitaDiasQuentesFrios')}
        required={formValues.receitaDiasQuentesFrios.length === 0}
    />
     <DropdownWithLabel
        title="É um prato assado, cozido?"
        subTitle="Campo obrigatório"
        values={['assado', 'cozido', 'cru', 'frito']}
        onChange={onChangeDropdownEventHandler('receitaPratoAssadoCozido')}
        required={formValues.receitaPratoAssadoCozido.length === 0}
    />

     <div className="btsalvar">
        <button onClick={() => setCurrentStep(prev => prev + 1)} className="btsalvar button-caminhos-do-campo" disabled={isNextButtonDisabled}>
            Próximo
        </button>
    </div>
  </>
}

export default DetalhesReceita

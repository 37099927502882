import React, { useContext, useCallback, useState, useEffect } from 'react'
import moment from 'moment'

import { FirebaseContext, CurrentUserContext } from '../../../Contexts'
import { useCallbackStatic } from '../../../hooks/utils'

import getClientIPv4 from '../../../utils/getPublicIPv4'
import getDeviceInfos from '../../../utils/getDeviceInfos'

const  Base = ({ title, description, mediaID, imagePath, programName, form, setForm, tipoTermo, salvarOk }) => {
    const { firebaseAuth, firebaseFirestore } = useContext(FirebaseContext)
    const { currentUser } = useContext(CurrentUserContext)
    const [result, setResult] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(undefined)

    useEffect(() => {
        const getFirebaseToken = async () => {
            if(firebaseAuth.currentUser) {
                await firebaseAuth.currentUser.getIdToken()
            } else {
                setHasError('Usuário não autenticado')
            }
        }
        getFirebaseToken()
    }, [firebaseAuth.currentUser])
    
    
    const back = useCallbackStatic(() => {
        setResult(false)
    })

    const cleanDataState = useCallback(() => {
        const cleanedForm = {}
        Object.keys(form).forEach(key => {
            cleanedForm[`${key}`] = ''
        })
        cleanedForm.termoAceito = false
        cleanedForm.formularioInscricaoAceito = false
        setForm(prev => cleanedForm)
        setResult(prev =>true)
        setLoading(prev =>false)
        setHasError(prev => undefined);
    }, [form, setForm])

    console.log(currentUser)

    const FirestoreStartUpload = useCallback(async () => {
        const firebaseMensagem = {
            ...form,
            created_at: moment().toDate(),
            id: `${currentUser.uid}-${moment().unix()}`,
            usr_email: currentUser.email,
            usr_id: currentUser.uid,
            usr_name: currentUser.nome,
            termo: tipoTermo,
            ipV4: await getClientIPv4(),
            device_infos: getDeviceInfos(),
        }
        // REMOVENDO OS DADOS DA IMAGEM LOCAL
        delete firebaseMensagem.media
        delete firebaseMensagem.type

        const collection = firebaseFirestore.collection('flerken-interatividade').doc('TERMOS_ACEITE').collection('termos')
        collection.doc(firebaseMensagem.id).set(firebaseMensagem).then(() => {
            console.log('Dados do formulario enviados com sucesso')
            setLoading(false)
            setResult(true)
        }).catch((err) => {
            console.log('Ocorreu um erro ao enviar os dados do formulario', err)
            setHasError('Falha ao enviar os dados do formulário.')
            setResult(false)
        })
    }, [currentUser, firebaseFirestore, form, tipoTermo])

    const send = useCallback( async () => {
        setLoading(true)
        FirestoreStartUpload()
    }, [FirestoreStartUpload])

    const onBack = useCallback(() => {
        cleanDataState();
        back();
    }, [back, cleanDataState]);

    const renderError = useCallback(() => (
        <div className="container">
            <div className="titenquete">
                <p>Ocorreu um erro ao cadastrar sua participação no Bem Verão.</p>
                <p>{hasError}</p>
                <p>Por favor, tente novamente.</p>
            </div>
            <div className="btsalvar">
                <button onClick={onBack}>
                    Tentar novamente
                </button>
            </div>
        </div>
    ), [hasError, onBack])

    const renderContent = useCallback(() => {
        const showSendFormComponent = (
        <>
            <div className="container">
                <div className="titenquete">{title}</div>
                <div className="descenquete">{description()}</div>
                <div className="btsalvar">
                    <button onClick={send} className="btsalvar"  disabled={salvarOk}>
                        Enviar
                    </button>
                </div>
            </div>
        </>)

        const showSuccessComponent = (
        <div className="container">
            <div className="titenquete">{`Sua participação no Bem Verão foi realizada com sucesso!`}</div>
        </div>)

        return !result ? showSendFormComponent : showSuccessComponent

    }, [title, description, send, salvarOk, result])

    const renderLoading = useCallback(() => (
        <>
            <div className="container">
                <div className='titenquete'>
                    <p>Seu formulário de inscrição está sendo enviado.</p>
                </div>
                <div className='titenquete'>
                    <p>Por favor, não feche esta tela até o processo finalizar.</p>
                </div>
            </div>
        </>
    ), [])

    return (
        <React.Fragment>
            <div className="enqueterpc">
                <div className="imagemdestaque">
                    <img src={imagePath} alt="banner"/>
                </div>
                {hasError? renderError() : loading ? renderLoading() : renderContent()}
            </div>
        </React.Fragment>
    )
}

export default Base

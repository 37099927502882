import React, { useEffect, useContext, useState } from 'react';

import moment from 'moment';

import { Result, Alert } from 'antd';

import { CurrentUserContext, FirebaseContext } from '../../../Contexts';

import getClientIPv4 from '../../../utils/getPublicIPv4';
import getDeviceInfos from '../../../utils/getDeviceInfos';

import Loading from '../../../components/Loading';

import sendUserConsent from '../../../utils/sendUserConsent'

import originConsents from '../../../constants/originConsents';

const Final = ({ formValues, onChangeStep }) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState({
        firebase: true,
        api: true
    });

    const { currentUser } = useContext(CurrentUserContext);
    const { firebaseFirestore } = useContext(FirebaseContext);

    useEffect(() => {
        const {
            bairro,
            cidade: {
                nome: nomeCidade,
                praca: pracaCidade,
                ibge: ibgeCidade
            },
            origemDaMidia,
            cep,
            avaliacaoDaQualidade,
            observacaoDaPesquisa
        } = formValues;

        const FirestoreStartUpload = async () => {

            const firebaseMensagem = {
                bairroDaPesquisa: bairro,
                cidadeDaPesquisa: nomeCidade,
                pracaDaPesquisa: pracaCidade,
                origemDaMidia,
                avaliacaoDaQualidade,
                observacaoDaPesquisa,
                created_at: moment().toDate(),
                id: `${currentUser.uid}-${moment().unix()}`,
                usr_id: currentUser.uid,
                usr_email: currentUser.email,
                ipV4: await getClientIPv4(),
                device_infos: getDeviceInfos(),
                termo: 'termo_pesquisa_qualidade_sinal_05_2021'
            };

            const termosCollection = firebaseFirestore.collection('flerken-interatividade').doc('TERMOS_ACEITE').collection('termos')
            try {
                await termosCollection.doc(firebaseMensagem.id).set(firebaseMensagem)
                setLoading(prev => ({
                    ...prev,
                    firebase: false
                }));
            } catch (err) {
                setError(true);
                console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
            }
        };

        const apiCall = async () => {
            try {
                await fetch('https://api.atendimento.vocenarpc.com.br/v1/external/cadastro-endereco', {
                    method: 'POST',
                    body: JSON.stringify({
                        nome: currentUser.nome,
                        email: currentUser.email,
                        uid: currentUser.uid,
                        telefone: currentUser.telefone,
                        cidade_id_ibge: ibgeCidade,
                        bairro,
                        cep,
                    })
                });

                setLoading(prev => ({
                    ...prev,
                    api: false,
                }));
            }
            catch(err) {
                setError(true);
                console.log('Ocorreu um erro ao enviar os dados para o firestore: ', err)
            }
        };

        FirestoreStartUpload();
        apiCall();
        sendUserConsent({
          name: currentUser.nome,
          email: currentUser.email,
          origin: originConsents.termo_pesquisa_qualidade_sinal_05_2021
        })
    }, []);

    return <>
        {error ? <Alert
            message="Erro"
            description="Desculpe, ocorreu um erro inesperado. Por favor, tente mais tarde."
            type="error"
            showIcon
            closable
            onClose={() => onChangeStep(0)}
            onClick={() => onChangeStep(0)}
        /> : (
            (loading.api || loading.firebase) ? <Loading />
                : <Result
                    status="success"
                    title="Pesquisa concluída"
                    subTitle="Agradecemos a sua participação"
            />)}
    </>;
};

export default Final;

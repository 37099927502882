import React from 'react';

const TermoMeiaMaratonaClubeAlice2022 = () => (
    <>
        <p><strong>TERMOS DE ACEITE/INSCRIÇÃO – MEIA MARATONA DE CURITIBA - CLUBE DA ALICE</strong></p>
        <p>Promoção válida para 15/07/2022 – Voucher de inscrição com kit para a corrida gratuitos.</p>
        <p>Ao me cadastrar nesta promoção estou ciente que o voucher é pessoal e exclusivo para o usuário cadastrado no APP.</p>
        <p>Você na RPC, com direito único e exclusivamente ao voucher de inscrição gratuito via site <a target="_blank" href="https://www.ticketsports.com.br/e/meia-maratona-de-curitiba-clube-da-alice-33722">https://www.ticketsports.com.br/e/meia-maratona-de-curitiba-clube-da-alice-33722</a>.</p>
        <p>A RPC se reserva ao direito de encerrar a promoção a qualquer momento, sem que seja devido ao usuário do APP qualquer tipo de indenização.</p>
    </>
)

export default TermoMeiaMaratonaClubeAlice2022;

import dayjs from 'dayjs';
import { userRegister } from '..';

const validatedUser = async (firebaseFirestore, setLoading, setFinish, currentUser, setUser) => {
  const registerInDB = await userRegister(firebaseFirestore, currentUser)

  const visitantes = registerInDB.docs.length > 0 && registerInDB.docs.map((doc) => doc.data())

  visitantes && visitantes.map((visitante) => {
    if(visitante.visitaConfirmada && dayjs() < dayjs(visitante.formularioDataVisita.seconds*1000).add(3, "month")){
      setUser(visitante)
      setLoading(false)
      setFinish(true)
    }
  })
}

export default validatedUser

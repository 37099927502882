import React, { useState } from 'react';

import { Radio, Space, Typography } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';

import Slider from '../../../components/Slider';
import Back from '../../../components/Back';

import Modal from '../../../components/Modal';
import ToggleSwitch from '../../../components/ToggleSwitch';

import TermoPesquisaDeSinal from '../../../termosAceite/termoPesquisaDeSinal';
import InputWithLabel from '../../../components/InputWithLabel';

const { Text } = Typography;

const Pesquisa = ({
    formValues,
    onChangeFormValues,
    onChangeInputEventHandler,
    onChangeStep
}) => {
    const [mostrarTermoModal, setMostrarTermoModal] = useState(false);

    return <>
        <Back onClick={() => onChangeStep(prev => prev - 1)} />
        <div style={{ padding: '10px 0px' }}>
            <Text strong>Como você nos assiste?</Text>
        </div>
        <Radio.Group value={formValues.origemDaMidia} onChange={(e) => onChangeFormValues({ ...formValues, origemDaMidia: e.target.value})}>
            <Space direction="vertical">
                <Radio value="Antena Interna">Antena Interna</Radio>
                <Radio value="Antena Externa">Antena Externa</Radio>
                <Radio value="Parabólica">Parabólica</Radio>
            </Space>
            <Space direction="vertical">
                <Radio value="TV a cabo">TV a cabo</Radio>
                <Radio value="Globo Play">Globo Play</Radio>
            </Space>
        </Radio.Group>
        <div style={{ padding: '10px 0px' }}>
            <Text strong>Em uma escala de 1 a 10, conte para a gente como esta a imagem da RPC na sua casa:</Text>
        </div>
        <Slider
            prefix={<FrownOutlined />}
            suffix={<SmileOutlined />}
            prefixColor='#ff0000'
            suffixColor='#00ff00'
            min={1}
            defaultValue={formValues.avaliacaoDaQualidade}
            onChange={(value) => onChangeFormValues({ ...formValues, avaliacaoDaQualidade: value })}
            marks={{
                1: '',
                2: '',
                3: '',
                4: '',
                5: '',
                6: '',
                7: '',
                8: '',
                9: '',
                10: '',
            }}
        />
        <InputWithLabel
            title="Gostaria de acrescentar algum comentário?"
            inputPlaceholder="Escreva aqui o seu comentário..."
            className="textInputArea"
            inputValue={formValues.observacaoDaPesquisa}
            maxInputLength={2000}
            onChange={onChangeInputEventHandler('observacaoDaPesquisa', 2000)}
        />
        <div className="opcenquete align">
            <ToggleSwitch checked={formValues.termoAceite} setChecked={() => onChangeFormValues({ ...formValues, termoAceite: !formValues.termoAceite })}/>
            <button type="button" className="btTransparente"
                onClick={() => {
                    document.body.scrollTop = 0 // For Safari
                    document.documentElement.scrollTop = 0 // FF, GC, Opera
                    document.body.style.overflowY = 'hidden';
                    setMostrarTermoModal(_ => true)
                }}
            >
                <span><strong>Li e aceito os termos de uso</strong></span>
            </button>
        </div>

        {
            mostrarTermoModal &&
            <Modal setTermoAceito={() => onChangeFormValues({ ...formValues, termoAceite: !formValues.termoAceite })} setShowModal={setMostrarTermoModal}>
                <TermoPesquisaDeSinal />
            </Modal>
        }
        <div className="btsalvar" style={{ paddingTop: '20px' }}>
            <button onClick={() => onChangeStep(prev => prev + 1)} className="btsalvar" disabled={!formValues.termoAceite || formValues.origemDaMidia.length === 0}>
                Enviar pesquisa
            </button>
        </div>
    </>;
};

export default Pesquisa;
